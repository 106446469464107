import { ActionConfig, ContentItem } from '@adiffengine/engine-types'
import { Colors, Img, Lightning } from '@lightningjs/sdk'
import equal from 'fast-deep-equal/es6'
import { getStoredTheme } from '../themes/theme'
// const debug = new Debugger('AdeBoxArtButton')

export interface AdeBoxArtButtonTypeConfig
  extends Lightning.Component.TypeConfig {
  imageSrc?: string
  action: ActionConfig
}

export interface AdeBoxArtButtonTemplateSpec
  extends Lightning.Component.TemplateSpecLoose {
  Shadow: object
  Content: {
    Tester: object

    Rect: {
      Image: object
      Overlay: object
    }
    Backup: {
      border: object
    }

    Vignette: object
    Clipper: {
      Text: {
        Title: object
      }
    }
  }
  content: ContentItem
  title: string
  imageSrc: string | null
}

export const BoxArtHeight = 300
export class AdeBoxArtButton
  extends Lightning.Component<
    AdeBoxArtButtonTemplateSpec,
    AdeBoxArtButtonTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<AdeBoxArtButtonTemplateSpec>
{
  private _focusAnimation: Lightning.types.Animation | undefined = undefined
  public imageSrc: string | null = null
  public action: ActionConfig | null = null
  public static width = BoxArtHeight * (44 / 66)
  public static height = BoxArtHeight
  public static margin = 20

  Content = this.getByRef('Content')!
  Backup = this.Content.getByRef('Backup')!
  Clipper = this.Content.getByRef('Clipper')!
  Text = this.Clipper.getByRef('Text')!
  Title = this.Text.getByRef('Title')!
  Rect = this.Content.getByRef('Rect')!
  Image = this.Rect.getByRef('Image')!
  Vignette = this.Content.getByRef('Vignette')!
  private _pressAnimation: Lightning.types.Animation | undefined

  static override _template(): Lightning.Component.Template<AdeBoxArtButtonTemplateSpec> {
    const theme = getStoredTheme()
    const {
      components: { VideoCardConfig },
    } = theme
    const radiusShader = {
      type: Lightning.shaders.RoundedRectangle,
      radius: VideoCardConfig.radius,
    }
    return {
      Shadow: {
        alpha: 0.2,
        mountX: 0.5,
        mountY: 0.5,
        x: (w: number) => w / 2,
        y: (h: number) => h / 2,
        w: (w: number) => w + 32,
        h: (h: number) => h + 32,
        color: Colors(VideoCardConfig.vignetteColor).get(),
        rect: true,
        shader: { type: Lightning.shaders.FadeOut, fade: 32 },
      },
      Content: {
        h: (h: number) => h,
        w: (w: number) => w,
        Rect: {
          x: 0,
          y: 0,
          shader: radiusShader,
          Image: {
            x: 0,
            y: 0,
            rect: true,
            rtt: true,
          },
        },
        Backup: {
          visible: false,
          x: 0,
          y: 0,
          w: (w: number) => w,
          h: (h: number) => h,
          shader: {
            ...radiusShader,
            stroke: 4,
            strokeColor: Colors('white').get(),
          },
          colorTop: Colors('black').get(),
          colorBottom: Colors('white').get(),
          rect: true,
          rtt: true,
        },
        Vignette: {
          alpha: 0.0001,
          x: 0,
          y: 0,
          w: (w: number) => w,
          h: (h: number) => h,
          colorBottom: Colors(VideoCardConfig.vignetteColor).get(),
          colorTop: Colors(VideoCardConfig.vignetteColor).alpha(0.01).get(),
          rect: true,
          rtt: true,
          shader: radiusShader,
        },
        Clipper: {
          x: 0,
          y: 0,
          w: (w: number) => w,
          h: (h: number) => h,
          rect: true,
          rtt: true,
          clipping: true,
          color: 0x00000000,
          shader: radiusShader,
          Text: {
            alpha: 0,
            x: 16,
            y: 16,
            flex: {
              direction: 'column',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            },
            Title: {
              color: Colors('text').get(),
              text: {
                text: '',
                fontFace: 'Bold',
                fontSize: 24,
                textAlign: 'right',
                wordWrap: true,
                maxLines: 5,
              },
            },
          },
        },
      },
    }
  }

  private _title: string = ''
  set title(text: string) {
    this.Title.patch({
      text: { text },
    })
    this._title = text
  }
  get title(): string {
    return this._title
  }

  private _content: ContentItem | null = null
  set content(content: ContentItem | null) {
    if (!equal(this._content, content)) {
      this._content = content
      if (this.content) {
        this.title = this.content.title
        this.imageSrc =
          this.content.images.box?.getForWidth(AdeBoxArtButton.width) ?? null
      }
    }
  }
  get content() {
    return this._content
  }

  private _imageFailed: boolean = false

  private _textFocusAnimation: Lightning.types.Animation | undefined = undefined
  get textFocusAnimation() {
    if (!this._textFocusAnimation) {
      this._textFocusAnimation = this.animation({
        duration: 0.2,
        delay: 0.05,
        actions: [
          { t: 'Content.Clipper.Text', p: 'y', v: { 0: 36, 1: 0 } },
          { t: 'Content.Clipper.Text', p: 'alpha', v: { 0: 0.0001, 1: 1 } },
          { t: 'Content.Vignette', p: 'alpha', v: { 0: 0.0001, 1: 1 } },
        ],
      })
    }
    return this._textFocusAnimation
  }

  override _init() {
    const theme = this.fireAncestors('$theme')

    const dimensionsPatch = {
      h: this.h ?? AdeBoxArtButton.height,
      w: this.w ?? AdeBoxArtButton.width,
    }
    this.patch(dimensionsPatch)
    this.Content.patch({
      ...dimensionsPatch,
      Backup: {
        ...dimensionsPatch,
      },
      Vignette: {
        ...dimensionsPatch,
      },
      Clipper: {
        ...dimensionsPatch,
        Text: {
          h: dimensionsPatch.h - 32,
          w: dimensionsPatch.w - 32,
          Title: {
            text: {
              wordWrapWidth: dimensionsPatch.w - 32,
            },
          },
        },
      },
    })
    let patch: object = {
      ...dimensionsPatch,
      Overlay: {
        ...dimensionsPatch,
        alpha: 0.5,
      },
    }

    const imageBackup = () => {
      this.Backup.patch({
        visible: true,
        colorTop: Colors(theme.palette.darks[300]).get(),
        colorBottom: Colors(theme.palette.darks[700]).get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 8,
          stroke: 4,
          strokeColor: Colors(theme.palette.darks[100]).get(),
        },
      })
      this._imageFailed = true
      this.Vignette.setSmooth('alpha', 1, { duration: 0.2 })
      this.Text.setSmooth('alpha', 1, { duration: 0.2 })
    }
    this._imageFailed = false
    if (this.imageSrc) {
      patch = {
        ...patch,
        Image: {
          ...dimensionsPatch,
          rect: true,
          rtt: true,
          texture: Img(this.imageSrc).cover(this.w, this.h),
        },
      }
    } else {
      imageBackup()
    }
    this.Image.on('txError', imageBackup.bind(this))
    this.Rect.patch(patch)

    this._focusAnimation = this.animation({
      duration: 0.2,
      actions: [
        { p: 'scale', v: { 0: 1, 1: 1.1 } },
        { t: 'Shadow', p: 'alpha', v: { 0: 0.2, 1: 0.5 } },
        {
          t: 'Shadow',
          p: 'color',
          v: {
            0: Colors(theme.palette.darks[900]).get(),
            1: Colors(theme.palette.darks[200]).alpha(0.6).get(),
          },
        },
        { t: 'Rect.Overlay', p: 'alpha', v: { 0: 0.2, 1: 0 } },
      ],
    })
  }
  get pressAnimation() {
    if (!this._pressAnimation) {
      this._pressAnimation = this.animation({
        duration: 0.3,
        actions: [
          {
            t: 'Content',
            p: 'scale',
            v: { 1: 1, 0: 0.95 },
          },
          {
            t: 'Shadow',
            p: 'alpha',
            v: { 1: 0.2, 0: 0.5 },
          },
        ],
      })
    }
    return this._pressAnimation
  }
  override _captureEnter() {
    this.pressAnimation.start()
    return false
  }
  override _captureEnterRelease() {
    this.pressAnimation.stop()
    return false
  }

  override _focus() {
    this._focusAnimation?.start()
    if (!this._imageFailed) {
      this.textFocusAnimation.start()
    }
  }

  override _unfocus() {
    this._focusAnimation?.stop()
    if (!this._imageFailed) {
      this.textFocusAnimation.stop()
    }
  }
}

import { CoordinateDimensions, ThemeConfig } from '@adiffengine/engine-types'
import { Colors, Img, Lightning, Settings, Utils } from '@lightningjs/sdk'
import defer from 'lodash-es/defer'
import isString from 'lodash-es/isString'
import {
  Debugger,
  getCoordinateDimensions,
  pathJoin,
  registerHoverable,
} from '../lib'
import { getStoredTheme } from '../themes'

const debug = new Debugger('MainMenuButton')

export interface MainMenuButtonTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Background: object
  Shadow: object
  Inner: {
    Icon: { Icon: object; FocusIcon: object }
    Text: object
  }
  label: string
  icon: string | null
  text: string
  path: string | null | undefined
  hide(immediate?: boolean): void
  show(immediate?: boolean): void
  openWidth?: number
}
export interface MainMenuButtonTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: {
    hovered(coords: CoordinateDimensions, label: string): void
  }
}
export class MainMenuButton
  extends Lightning.Component<
    MainMenuButtonTemplateSpec,
    MainMenuButtonTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<MainMenuButtonTemplateSpec>
{
  public path: string | null | undefined = null
  Inner = this.getByRef('Inner')!
  Icons = this.Inner.getByRef('Icon')!
  static override _template(): Lightning.Component.Template<MainMenuButtonTemplateSpec> {
    const theme = getStoredTheme()
    return {
      collision: true,
      h: 60,

      Shadow: {
        alpha: 0,
        mountX: 0.5,
        mountY: 0.5,
        h: 60,
        color: Colors('black').get(),
        rect: true,
        shader: { type: Lightning.shaders.FadeOut, fade: 24 },
      },
      Background: {
        x: 0,
        y: 0,
        h: 60,
        alpha: 0,
        rect: true,
        color: Colors(
          theme.components.MainMenuButtonConfig.backgroundColor
        ).get(),
        shader: {
          radius: theme.components.MainMenuButtonConfig.radius,
          type: Lightning.shaders.RoundedRectangle,
        },
      },
      Inner: {
        x: 12,
        w: (w: number) => w - 24,
        y: (w: number) => (w - 40) / 2,
        h: 40,
        flex: {
          direction: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        },
        rect: true,
        color: 0x00000000,
        Icon: {
          h: 24,
          w: 24,
          flexItem: {
            marginRight: 12,
          },
        },
        Text: {
          alpha: 0,
          color: Colors('white').get(),
          flexItem: {
            marginTop: 4,
          },
          scale: 1 / 1.2,
          text: {
            advancedRenderer: true,
            text: '',
            fontFace: 'Regular',
            fontSize: 20,
            wordWrap: false,
          },
        },
      },
    }
  }

  themeable(oldTheme?: ThemeConfig) {
    const theme = this.fireAncestors('$theme')
    if (oldTheme) {
      this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'Background',

            p: 'color',
            v: {
              0: Colors(
                oldTheme.components.MainMenuButtonConfig.backgroundColor
              ).get(),
              1: Colors(
                theme.components.MainMenuButtonConfig.backgroundColor
              ).get(),
            },
          },
          {
            t: 'Background',
            // @ts-ignore - bad types in lightning animation
            p: 'shader.radius',
            v: {
              0: oldTheme.components.MainMenuButtonConfig.radius,
              1: theme.components.MainMenuButtonConfig.radius,
            },
          },
        ],
      }).start()

      this.tag('Inner.Text')?.patch({
        text: {
          fontFace: `${theme.fonts.name}Regular`,
        },
      })
    } else {
      this.tag('Background')?.patch({
        w: this.w,
      })
    }

    this.tag('Shadow')?.patch({
      x: this.w / 2,
      y: this.h / 2,
      h: this.h + 24,
      w: this.w + 24,
    })

    this._focusAnimation = this.animation({
      duration: 0.2,
      actions: [
        { t: 'Shadow', p: 'scale', v: { 0: 1, 1: 1.05 } },
        { t: 'Shadow', p: 'alpha', v: { 0: 0, 1: 0.3 } },
        {
          t: 'Background',
          p: 'color',
          v: {
            0: Colors(
              theme.components.MainMenuButtonConfig.backgroundColor
            ).get(),
            1: Colors('primaryHighlight').get(),
          },
        },
        {
          t: 'Inner.Text',
          p: 'color',
          v: {
            0: Colors('text').get(),
            1: Colors('buttonHighlightText').get(),
          },
        },
        {
          t: 'Inner.Text',
          p: 'scale',
          v: {
            0: 1 / 1.2,
            1: 1,
          },
        },
        {
          t: 'Icon.Icon',
          p: 'alpha',
          v: {
            0: 1,
            1: 0,
          },
        },
        {
          t: 'Icon.Icon',
          p: 'scale',
          v: {
            0: 1,
            1: 1.05,
          },
        },
        {
          t: 'Icon.FocusIcon',
          p: 'alpha',
          v: {
            0: 0,
            1: 1,
          },
        },
        {
          t: 'Icon.FocusIcon',
          p: 'scale',
          v: {
            0: 1,
            1: 1.05,
          },
        },
      ],
    })
  }

  override _captureEnter() {
    if (isString(this.path)) {
      defer(() => {
        this.fireAncestors('$navigate', { path: this.path, fromMain: true })
      })
    }
  }

  set label(text: string) {
    this.tag('Inner.Text')!.patch({
      text: {
        text: text.toUpperCase(),
      },
    })
  }

  get label() {
    return this.tag('Inner.Text')?.text?.text ?? ''
  }
  private _icon: string | null = null
  private _focusAnimation: Lightning.types.Animation | undefined = undefined

  themeChanged(_theme: ThemeConfig, oldTheme?: ThemeConfig) {
    if (oldTheme) {
      this.themeable(oldTheme)
    }
  }

  override _init() {
    registerHoverable('MainMenuButton', this)
    this.patchIcon()
    this.themeable()
  }

  patchIcon() {
    if (this._icon !== null) {
      const focusVisible = this.hasFocus()
      const icon = `images/icons/${this._icon}.png`
      // const icon = Utils.asset(pathJoin([iconPath, `${this._icon}.svg`]))
      this.Icons.patch({
        Icon: {
          color: Colors('buttonText').get(),
          w: 24,
          h: 24,
          texture: Img(icon).contain(24, 24),
          alpha: focusVisible ? 0 : 1,
        },
        FocusIcon: {
          w: 24,
          texture: Img(icon).contain(24, 24),
          h: 24,
          color: Colors('buttonHighlightText').get(),
          alpha: focusVisible ? 1 : 0,
        },
      })
    } else {
      this.tag('Inner.Icon')?.patch({
        Icon: {
          visible: false,
        },
        FocusIcon: {
          visible: false,
        },
      })
    }
  }
  set icon(icon: string | null) {
    this._icon = icon
  }
  override _focus() {
    this.fireAncestors('$currentFocus', getCoordinateDimensions(this))
    this._focusAnimation?.start()
  }
  override _unfocus() {
    this.fireAncestors('$currentFocus', null)
    this._focusAnimation?.stop()
  }

  show() {
    this._setState('Open')
  }
  hide() {
    this._setState('Closed')
  }
  private _openAnimation: Lightning.types.Animation | undefined = undefined
  get openAnimation() {
    if (this._openAnimation) return this._openAnimation
    else {
      this._openAnimation = this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'Inner.Text',
            p: 'alpha',
            v: {
              0: 0,
              1: 1,
            },
          },
          {
            t: 'Background',
            p: 'alpha',
            v: {
              0: 0,
              1: 1,
            },
          },
        ],
      })
      return this._openAnimation
    }
  }

  /* Mouse Handlers */
  override _handleHover() {
    debug.info('Main Menu Button Hovered')
    this.signal('hovered', getCoordinateDimensions(this), this.label)
    this.stage.application.emit(
      'hovered',
      `MainMenu:${this.label}`,
      getCoordinateDimensions(this)
    )
    return false
  }
  _handleClick() {
    debug.info('main menu button clicked')
    this._captureEnter()
  }
  static override _states(): any {
    return [
      class Closed extends this {},
      class Open extends this {
        override $enter() {
          if (this.attached) {
            this.openAnimation.start()
          } else {
            this.tag('Inner.Text')?.patch({ alpha: 1 })
          }
        }
        override $exit() {
          this.openAnimation.stop()
        }
      },
    ]
  }
}

import { ContentSeason } from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { SimpleCard } from './SimpleCard'
export interface SeasonCardTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Card: typeof SimpleCard
  season: ContentSeason
}

export type SeasonCardTypeConfig = Lightning.Component.TypeConfig

export class SeasonCard
  extends Lightning.Component<SeasonCardTemplateSpec, SeasonCardTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<SeasonCardTemplateSpec>
{
  static override _template(): Lightning.Component.Template<SeasonCardTemplateSpec> {
    return {
      x: 0,
      y: 0,
      h: 200,
      w: 500,
      Card: {
        type: SimpleCard,
      },
    }
  }

  set season(_season: ContentSeason) {
    this.getByRef('Card')!.patch({})
  }
}

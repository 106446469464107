import { Colors, Lightning } from '@lightningjs/sdk'
import { Debugger, cp } from '../../../lib'
const debug = new Debugger('AdvancedGridListProgress')
export type RangeType = [number, number]

export interface AdvancedGridListProgressTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Container: object
  Progress: object
  progress: number
  width: number
  progressWidth: number
}

export interface AdvancedGridListProgressTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: {
    next(): void
    previous(): void
  }
}
export class AdvancedGridListProgress
  extends Lightning.Component<
    AdvancedGridListProgressTemplateSpec,
    AdvancedGridListProgressTypeConfig
  >
  implements
    Lightning.Component
      .ImplementTemplateSpec<AdvancedGridListProgressTemplateSpec>
{
  Progress = this.getByRef('Progress')!
  Container = this.getByRef('Container')!
  static override _template(): Lightning.Component.Template<AdvancedGridListProgressTemplateSpec> {
    return {
      h: 8,
      Container: {
        w: cp,
        h: 8,
        rect: true,
        color: Colors('backgroundGradient').get(),
        rtt: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 4,
        },
      },
      Progress: {
        w: cp,
        h: 8,
        x: 0,
        y: 0,
        rect: true,
        rtt: true,
        color: Colors('primaryHighlight').get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 4,
        },
      },
    }
  }
  private _progress = 0
  private _width = 0
  private _progressWidth = 0
  set progressWidth(width: number) {
    if (width !== this._width) {
      this._width = width
      this._progressWidth = this.w * width
      this.Progress.patch({
        w: this._progressWidth,
        x: 0,
      })

      const old = this._progress
      this._progress = 0
      this.progress = old
    }
  }
  set width(width: number) {
    if (this.w !== width) {
      debug.info('Setting progress width to %s', width, this.w)
      this.patch({ w: width })
      this.Progress.patch({
        w: width * this._width,
        x: 0,
      })
    }
  }

  set progress(percent: number) {
    percent = percent < 0 ? 0 : percent > 1 ? 1 : percent
    if (this._progress !== percent) {
      this._progress = percent
      const destination = (this.w - this._progressWidth) * percent
      debug.info(
        'SEtting x to %s with percent of %s width of %s and indicator width of %s ',
        destination,
        percent,
        this.w,
        this._width,
      )

      this.Progress.setSmooth('x', destination)
    }
  }

  override _active() {
    debug.info('Progress Active', this)
    this.Container.patch({
      w: this.w,
    })
  }
}

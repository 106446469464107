/// <reference path="../../../libs/lightning/elements/src/augmentation.d.ts" />
import {
  AdeCloseReason,
  ContentItem,
  ContentSource,
} from '@adiffengine/engine-types'
import { Colors, Lightning, Router, Settings } from '@lightningjs/sdk'

import {
  Debugger,
  MainMenuButtons,
  ThorApp,
  ThorError,
  UserVideoMonitor,
  isGoodArray,
  setGlobalTheme,
} from '@adiffengine/elements'

import { MituSdk, getMituSdk } from './mitu-sdk'
import { getRouteConfig } from './mitu_route_config'
import { mituTheme } from './theme'

setGlobalTheme(mituTheme)
const debug = new Debugger('MituApp')

export class App extends ThorApp {
  static _theme = mituTheme
  Widgets = this.getByRef('Widgets')!
  VideoPlayer = this.Widgets.getByRef('VideoPlayer')!
  Background = this.getByRef('Background')!
  MainMenu = this.Widgets.getByRef('MainMenu')!
  ErrorModal = this.Widgets.getByRef('ErrorModal')!
  override get id() {
    return 'mitu_app'
  }
  static override _template() {
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      Background: {
        w: 1920,
        h: 1080,
        rect: true,
        color: Colors(App._theme.palette.background).get(),
        Bubble: {
          w: (w: number) => w,
          h: (h: number) => h,
          x: 0,
          y: 0,
          rect: true,
          shader: {
            type: Lightning.shaders.RadialGradient,
            pivot: [0.3, 0.9],
            outerColor: Colors(App._theme.palette.background).get(),
            innerColor: Colors(App._theme.palette.backgroundGradient).get(),
          },
        },
      },
      ...super._template(),
    }
  }

  async _setup() {
    await super._setup()
    this.MainMenu.patch({
      menuButtons: [
        MainMenuButtons.home,
        MainMenuButtons.search,
        MainMenuButtons.settings,
      ],
    })
    this.Widgets.setSmooth('alpha', 1)
  }

  // Theme Config
  get _theme() {
    return App._theme
  }
  static getFonts() {
    return super.getFonts(App._theme.fonts)
  }

  static colors() {
    return super.colors(App._theme.palette)
  }

  // Lifecycle
  getRouteConfig(initialHash: string | null) {
    const routeConfig = getRouteConfig(this, initialHash)
    return routeConfig
  }

  $theme() {
    return App._theme
  }
  get sdk(): MituSdk {
    const mituSdk = getMituSdk()
    return mituSdk
  }

  prestart = this.sdk.preload

  async $search(term = '', min?: number): Promise<ContentItem[] | null> {
    if (min == null || (min && term.length >= min)) {
      this.stage.application.emit('searching', term)
      this.setInitialQuery(term)
      console.info('Doing Search')
      const results = await this.sdk.search(term)
      this.stage.application.emit('searched', results.length)
      return isGoodArray<ContentItem>(results) ? results : null
    }
    return null
  }

  async $adTagForContent(content: ContentItem): Promise<string | null> {
    if (Settings.get('app', 'ADS_ENABLED', true) === true) {
      const { lmt, us_privacy } = await this.lifecycleHandler.paramsInit()
      const uid = await this.lifecycleHandler.getUserId()
      return this.sdk.adTag(content, { lmt, us_privacy, uid }).then(url => {
        debug.info('Ad Url: ' + url)
        return url
      })
    } else {
      return null
    }
  }

  $displayErrorModal(error: ThorError) {
    this.ErrorModal.patch({ error })
    debug.info('Patched Error Modal and focusing', this.ErrorModal)
    Router.focusWidget('ErrorModal')
  }

  private _videoMonitor = new UserVideoMonitor()
  $monitorVideos(on = true) {
    if (on) {
      this._videoMonitor.reset()
    } else {
      this._videoMonitor.destroy()
    }
  }

  async $nextVideo(item: ContentItem) {
    debug.info(
      'Getting next video, user state is %s, videos viewed is %s (max: %s)',
      this._videoMonitor.state,
      this._videoMonitor.videosWatched,
      this._videoMonitor.maxed
    )
    const next = await this.sdk.nextVideo(item)
    if (this._videoMonitor.state !== 'inactive' && !this._videoMonitor.maxed) {
      this._videoMonitor.incrementVideoCount()
      return next
    } else {
      const result = await this.ManualAreYouStillThere.check()
      if (result) {
        return next
      } else {
        this.fireAncestors('$exitApp', AdeCloseReason.TIMED_OUT, true)
        return null
      }
    }
  }

  $getAppropriateSource(sources: ContentSource[] = []): ContentSource | null {
    return sources[0]
  }

  _handleExit() {
    this.$exitApp(AdeCloseReason.REMOTE_BUTTON)
  }
}
export type AppType = typeof App

import {
  CoordinateDimensions,
  DirectionalSignalMap,
} from '@adiffengine/engine-types'
import { Colors, Lightning, Registry } from '@lightningjs/sdk'
import { Debugger, getClosestIndexByX } from '../lib'
const debug = new Debugger('TabBar')
export interface TabBarItemTemplateSpec
  extends Lightning.Component.TemplateSpec {
  text: string
}

export interface TabBarTemplateSpec extends Lightning.Component.TemplateSpec {
  items: string[]
  List: object
  FocusList: object
  Focused: object
  index: number
}
interface TabBarSignalMap extends DirectionalSignalMap {
  selected(selected: string, index: number): void
}
export interface TabBarTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: TabBarSignalMap
}

export function isTabBar(x: any): x is TabBar {
  return x && x.constructor === TabBar
}
export class TabBar
  extends Lightning.Component<TabBarTemplateSpec, TabBarTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<TabBarTemplateSpec>
{
  List = this.getByRef('List')!
  FocusList = this.getByRef('FocusList')!
  Focused = this.getByRef('Focused')!
  private _items: string[] = []
  private _index: number = -1
  _focusAnimation: Lightning.types.Animation | undefined

  static override _template(): Lightning.Component.Template<TabBarTemplateSpec> {
    return {
      x: 80,
      y: 0,
      h: 80,
      w: 1920 - 160,
      List: {
        x: 0,
        flex: {
          direction: 'row',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        },
      },
      FocusList: {
        x: 0,
        flex: {
          direction: 'row',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        },
      },
      Focused: {
        mountY: 1,
        x: 80,
        y: 60,
        w: 0,
        h: 12,
        rect: true,
        rtt: true,
        color: Colors('text').get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 6,
        },
      },
    }
  }

  set index(index: number) {
    if (index >= 0 && index < this._items.length) {
      this._index = index
      this.updateFocus()
      this.focusCurrent()
    }
  }
  get index() {
    return this._index
  }

  override _focus() {
    this.focusCurrent()
    this.focusAnimation.start()
  }

  override _unfocus() {
    this.focusCurrent()
  }

  focusItem(idx: number) {
    const focus = this.FocusList.childList.getAt(idx)
    const current = this.FocusList.childList.getAt(idx)
    const isFocused = this.hasFocus() && idx === this.index
    const focusDisplay = isFocused ? 1 : 0.0001
    const currentDisplay = isFocused ? 0.0001 : 1
    if (current) current.setSmooth('alpha', currentDisplay, { duration: 0.2 })
    if (focus) focus.setSmooth('alpha', focusDisplay, { duration: 0.2 })
  }

  focusCurrent() {
    if (this.active) {
      this.List.childList.forEach((_current, index) => {
        this.focusItem(index)
      })
      if (this.hasFocus()) this.focusAnimation.start()
      else this.focusAnimation.stop()
    }
  }

  updateFocus(duration = 0.2) {
    const current = this.List.childList.getAt(this.index)
    debug.info(
      'Update Focus %s %s %s %ss',
      current?.finalW,
      this.attached,
      this.active,
      this.enabled,
    )
    if (current && this.attached) {
      const { finalW, finalX } = current
      if (finalW > 0) {
        const x = finalX - 24
        const w = finalW + 48
        this.Focused.patch({
          smooth: {
            x: [x, { duration, timingFunction: 'ease-out' }],
            w: [w, { duration, timingFunction: 'ease-out' }],
          },
        })
      }
    }
    this.signal('selected', this._items[this.index], this.index)
  }

  set items(items: string[]) {
    this.List.childList.clear()
    items.forEach((i, idx) => {
      const patch: Lightning.Element.PatchTemplate = {
        flexItem: {
          marginRight: idx !== items.length - 1 ? 48 : 0,
          marginLeft: idx !== 0 ? 48 : 0,
        },
        text: {
          text: i,
          fontFace: 'Bold',
          fontSize: 30,
        },
      }
      this.List.childList.add(this.stage.c(patch))
      patch.color = Colors('primaryHighlight').get()
      patch.alpha = 0.0001
      this.FocusList.childList.add(this.stage.c(patch))
    })
    this._items = items
    this.index = 0
    window.requestAnimationFrame(() => {
      Registry.setTimeout(() => this.updateFocus(0), 30)
    })
  }

  get focusAnimation() {
    if (!this._focusAnimation) {
      const theme = this.fireAncestors('$theme')
      this._focusAnimation = this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'Focused',
            p: 'color',
            v: {
              0: Colors('text').get(),
              1: Colors(theme.palette.highlights[500]).get(),
            },
          },
        ],
      })
    }
    return this._focusAnimation
  }

  // override _getFocused(): Lightning.Component {
  //   debug.info('Get focused on Tab Bar', this.List.attached, this.List)
  //   return this.List as Lightning.Component
  // }
  override _handleUp(): false | void {
    this.signal('up')
  }
  override _handleDown(): false | void {
    this.signal('down')
  }
  override _handleLeft(): false | void {
    if (this.index === 0) this.signal('left')
    else this.index--
  }
  override _handleRight(): false | void {
    if (this.index === this._items.length - 1) this.signal('right')
    else this.index++
  }
  override _active() {
    this.updateFocus()
  }

  setClosestByX(coords?: CoordinateDimensions) {
    if (coords) {
      const closest = getClosestIndexByX(coords, this.List.children, true)
      if (closest != null) {
        this.index = closest
      }
    }
  }
  override _init() {
    debug.info('Tab Bar Init', this)
  }
}

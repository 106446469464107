import {
  Debugger,
  PromiseIsFinished,
  PromiseIsRejected,
  ThorError,
} from '@adiffengine/elements'
import {
  ContentItem,
  ContentSource,
  EngineSdk,
  GridContext,
  GridResponse,
  ID,
  MediaDetails,
  TheGridRowData,
} from '@adiffengine/engine-types'
import { format } from 'fecha'
import Fuse from 'fuse.js'
import humanize from 'humanize-duration'
import camelCase from 'lodash-es/camelCase'
import { MediaType, MrssFeedItem, parseDurationNumber } from './mrss-feed'
import { MrssContentItem, fetchMrssUrl, mediaTypeLookup } from './mrss-parser'
import { SizedImageSet } from './sizedImageSet'

const debug = new Debugger('mrss-sdk')
interface RelatedScoreItem {
  item: ContentItem | MrssContentItem
  score: number
}
export interface MrssGridResponse extends GridResponse {
  hero_row: null | ID
}
// Comment
const defaultParams: GridContext = { params: { type: 'all' } }

export interface MrssClientOptions {
  filterContentWithNoMedia: boolean
  parseSources(item: MrssFeedItem): ContentSource[]
}
export const defaultMrssApiClientOptions: MrssClientOptions = {
  filterContentWithNoMedia: false,
  parseSources,
}
export interface FeedResponse<T = MrssContentItem> {
  items: T[]
  downloaded: Date
}

export class MrssApiClient implements EngineSdk {
  public readonly url: string
  private library: Record<ID, MrssContentItem> = {}
  private _fuseIndex: ReturnType<typeof Fuse.createIndex> = Fuse.createIndex(
    ['title', 'description'],
    []
  )
  private _options: MrssClientOptions = defaultMrssApiClientOptions
  private _fuse: Fuse<MrssContentItem> = new Fuse<MrssContentItem>(
    [],
    { findAllMatches: true },
    this._fuseIndex
  )
  constructor(url: string, options: Partial<MrssClientOptions> = {}) {
    this._options = { ...this._options, ...options }
    this.url = url
    this.parseMrssItem = this.parseMrssItem.bind(this)
    this.preload = this.preload.bind(this)
  }

  private _inflightLoader: Promise<FeedResponse> | null = null

  async getInflight(): Promise<typeof this._inflightLoader> {
    if (this._inflightLoader !== null) {
      const [resolved, rejected] = await Promise.all([
        PromiseIsFinished(this._inflightLoader),
        PromiseIsRejected(this._inflightLoader),
      ])
      if (resolved && !rejected) {
        return this._inflightLoader
      } else if (resolved && rejected) {
        this._inflightLoader = null
      }
    }
    return this._inflightLoader
  }
  async preload(): Promise<FeedResponse> {
    const inflight = await this.getInflight()
    if (inflight !== null) {
      debug.info('Returning inflight loader', this._inflightLoader)
      return inflight
    } else {
      return this._getFeed()
    }
  }

  private _feed: FeedResponse | null = null

  private async _fetchFeed() {
    debug.info(
      'Fetching Feed I %s?',
      !!this._feed ? 'have a cached feed' : 'do not have a cached feed.'
    )
    if (
      this._feed !== null &&
      new Date().getTime() - this._feed.downloaded.getTime() < 60 * 120 * 1000
    ) {
      debug.info('Feed Requested, returning cached feed.')
      return this._feed
    }
    const raw = await fetchMrssUrl(this.url)
    const items = raw.map<MrssContentItem>(this.parseMrssItem.bind(this))
    this._fuseIndex = Fuse.createIndex(['title', 'description'], items)
    this._fuse = new Fuse<MrssContentItem>(
      items,
      { findAllMatches: true },
      this._fuseIndex
    )
    this.library = items.reduce(
      (acc, item) => {
        acc[item.id] = item
        return acc
      },
      {} as Record<ID, MrssContentItem>
    )

    this._feed = {
      items,
      downloaded: new Date(),
    }
    return this._feed
  }

  async _getFeed(): Promise<FeedResponse<MrssContentItem>> {
    return this._fetchFeed()

    // const inflight = await this.getInflight()
    // if (inflight === null) {
    //   this._inflightLoader = this._fetchFeed()
    //   return this._inflightLoader
    // } else {
    //   return inflight
    // }
  }

  private _watched: ID[] = []
  addWatched(id: ID) {
    this._watched = [...this._watched.filter(x => x !== id), id]
  }

  async nextVideo(item?: ContentItem | null): Promise<ContentItem | null> {
    const { playlists } = await this.getCategorizedGridItems()
    const rows = Object.values(playlists)
    let flat: ContentItem[] = rows.reduce(
      (acc, { items }) => acc.concat(items),
      [] as ContentItem[]
    )
    if (item) {
      this.addWatched(item.id)
      const itemIndex = flat.findIndex(flatItem => flatItem.id === item.id)
      if (itemIndex > -1) {
        const cycle = [...flat.slice(0, itemIndex), ...flat.slice(itemIndex)]
        const filtered = cycle.filter(({ id }) => {
          return !this._watched.includes(id) && id !== item.id
        })

        return filtered[0] || null
      }
      return null
    } else {
      const { playlists } = await this.getCategorizedGridItems()
      const rows = Object.values(playlists)
      const first = rows[0]
      if (!first) return null
      else return first.items[0] ?? null
    }
  }

  async getCategorizedGridItems() {
    const feed = await this._getFeed()
    const playlists: Record<string, TheGridRowData> = {
      uncategorized: {
        id: 'uncategorized',
        title: 'Videos',
        items: [],
      },
    }
    const newest = feed.items.reduce(
      (acc, item) => {
        if (!acc || !acc.release) return item
        if (!item.release) return acc
        return new Date(item.release).getTime() >
          new Date(acc.release).getTime()
          ? item
          : acc
      },
      null as MrssContentItem | null
    )
    const newestRow = newest?.gridId ?? 'uncategorized'
    feed.items.forEach(item => {
      this.library[item.id] = item
      if (!item['gridId']) {
        playlists['uncategorized'].items.push(item)
      } else {
        if (!playlists[item['gridId']])
          playlists[item['gridId']] = {
            id: item['gridId'],
            title: item.showname ?? 'Video',
            items: [],
          }
        if (
          playlists[item['gridId']].title === 'Video' &&
          item.showname !== 'Video' &&
          item.showname !== undefined
        )
          item.title = item.showname
        playlists[item['gridId']].items.push(item)
      }
    })
    const cleanLists = Object.entries(playlists).reduce(
      (acc, [key, value]) => {
        if (value.items.length > 0) {
          acc[key] = value
        }
        return acc
      },
      {} as typeof playlists
    )
    return { playlists: cleanLists, newest, newestRow }
  }
  async grid(context = defaultParams): Promise<GridResponse> {
    try {
      const { playlists, newest, newestRow } =
        await this.getCategorizedGridItems()
      if (newest && newestRow && playlists[newestRow]) {
        playlists[newestRow]
        playlists[newestRow].items = playlists[newestRow].items.filter(
          ({ id }) => id !== newest.id
        )
      }

      const out = {
        requestId: new Date().getTime(),
        heros: newest
          ? {
              id: 'hero',
              items: [newest],
              title: 'Newest Content',
            }
          : undefined,
        grid: Object.values(playlists),
        context,
      }
      return out
    } catch (error) {
      throw new ThorError(error.message, ThorError.Type.GridError, { error })
    }
  }
  async details(lookup_id: ID) {
    try {
      let item: MrssContentItem | undefined = this.library[lookup_id]
      if (!item) {
        const feed = await this._getFeed()
        item = feed.items.find(({ id }) => id === lookup_id)
        if (!item)
          throw new ThorError(
            'No item found for id ' + lookup_id,
            ThorError.Type.DetailsError
          )
      }
      if (item.similar === undefined) {
        item.similar = await this.getRelatedForItem(item)
      }
      return { ...item } // Don't let people modify the original..
    } catch (error) {
      throw new ThorError(error.message, ThorError.Type.DetailsError, {
        error,
        lookup_id,
      })
    }
  }

  async search(term: string) {
    try {
      if (Object.keys(this.library).length === 0) {
        await this._getFeed()
      }
      return this._fuse.search(term).map(({ item }) => item)
    } catch (error) {
      throw new ThorError('Unable to search.', ThorError.Type.SearchError, {
        term,
        error,
      })
    }
  }
  // This doesn't need to be async right now
  // but it's easier to start this way then to convert it later.
  async getRelatedForItem(
    item: MrssContentItem
  ): Promise<MrssContentItem[] | ContentItem[]> {
    try {
      let items: RelatedScoreItem[] = Object.values<MrssContentItem>(
        this.library
      )
        .map(testItem => {
          let score = item.genres.reduce<number>((acc, word) => {
            return testItem.genres.includes(word) ? ++acc : acc
          }, 0)
          if (item.showname && testItem.showname === item.showname) score += 5
          return {
            item: testItem,
            score,
          }
        })
        .filter(({ score, item: { id } }) => score > 0 && id !== item.id)
        .sort((a, b) => {
          return b.score - a.score
        })
      if (items.length === 0) {
        const grid = await this.grid()
        const row = grid.grid.find(({ items }) => {
          const ids = items.map(({ id }) => id)
          return ids.includes(item.id)
        })
        items = row ? row.items.map(item => ({ item, score: 1 })) : []
      }
      return items.map(({ item }) => item).slice(0, 20)
    } catch (error) {
      console.warn('Error fetching related %s', error.message, error)
      return []
    }
  }

  async related(id: string): Promise<ContentItem[]> {
    const item = await this.details(id)
    return this.getRelatedForItem(item)
  }
  parseSources(item: MrssFeedItem): ContentSource[] {
    return this._options.parseSources(item)
  }
  parseMedia(item: MrssFeedItem): MediaDetails[] {
    const releaseDate = item.pubDate
    const sources = this._options.parseSources(item)

    const duration = parseDurationNumber(item.media)
    const details = {
      type: 'movie',
      sources,
      id: `${item.guid}`,
      title: item.title,
      description: item.description,
      button_text: 'Play',
      release:
        releaseDate !== null && !isNaN(releaseDate.getTime())
          ? releaseDate
          : undefined,
      duration: 99,
    } as MediaDetails
    if (duration !== null) details.duration = duration
    return [details]
  }
  parseMrssItem(item: MrssFeedItem): MrssContentItem {
    const images: Record<number, string> = item.thumbnails.reduce(
      (acc, t) => {
        acc[t.width] = t.url
        return acc
      },
      {} as Record<number, string>
    )

    const genres = item.keywords.map(k => ({
      name: camelCase(k),
      id: k,
    }))
    const releaseDate = item.pubDate
    const releaseDateString: string | null = releaseDate
      ? format(releaseDate as Date, 'ddd MMM Do')
      : null

    const durationNumber: number | null = parseDurationNumber(item.media)
    const duration: null | string =
      durationNumber === null
        ? (durationNumber as null)
        : humanize((durationNumber as number) * 1000, {
            round: true,
            units: ['h', 'm'],
          })

    return {
      media: this.parseMedia(item),
      genres,
      gridId: item.gridId ?? item.playlist,
      showname: item.showname,
      title: item.title,
      id: item.guid,
      details: duration ?? releaseDateString ?? '',
      type: 'movie',
      description: item.description,
      release: item.pubDate,
      paths: {
        details: `details/movie/${item.guid}`,
        player: `player/movie/${item.guid}`,
      },
      images: {
        wide: new SizedImageSet(images),
      },
    }
  }
}

function parseSources(item: MrssFeedItem): ContentSource[] {
  return item.media
    .map(item => {
      const type = mediaTypeLookup(item.type)
      if (type === null) return null
      const out: ContentSource = {
        type,
        src: item.url,
      }
      if (item.type === MediaType.VideoMp4 && item.bitrate) {
        out.bitrate = item.bitrate
      }
      return out
    })
    .filter(f => {
      return f !== null
    }) as ContentSource[]
}

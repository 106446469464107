import { HoverComponentSignalMap } from '@adiffengine/engine-types'
import { Colors, Lightning, Settings } from '@lightningjs/sdk'
import { Debugger, confirmCollisionsAreSet } from '../../lib'
const debug = new Debugger('HoverCover')
export interface HoverCoverTemplateSpec
  extends Lightning.Component.TemplateSpec {
  debug: boolean
}
export interface HoverCoverTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: HoverComponentSignalMap
}
export class HoverCover
  extends Lightning.Component<HoverCoverTemplateSpec, HoverCoverTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<HoverCoverTemplateSpec>
{
  static override _template(): Lightning.Component.Template<HoverCoverTemplateSpec> {
    return {
      collision: true,
      rect: true,
      color: 0x00000000,
      zIndex: 1,
    }
  }
  override _active() {
    const enablePointer = Settings.get('app', 'enablePointer', false)
    if (enablePointer) {
      confirmCollisionsAreSet(this)
    } else {
      this.patch({
        visible: false,
      })
    }
  }

  private _debug = false

  set debug(debug: boolean) {
    if (this._debug !== debug) {
      this._debug = debug
      this.patch({
        color: debug ? Colors('background').alpha(0.5).get() : 0x00000000,
      })
    }
  }
  get debug() {
    return this._debug
  }

  override _handleHover() {
    debug.info('hover cover hovered handled')
    return false
  }
}

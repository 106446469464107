import { ActionConfig, ContentItem } from '@adiffengine/engine-types'
import { Colors, Img, Lightning, Utils } from '@lightningjs/sdk'
import isEqual from 'fast-deep-equal/es6'
import { Debugger, cp, isGoodNumber } from '../lib'
import { getStoredTheme } from '../themes'
const debug = new Debugger('BoxArtButton')

export interface BoxArtButtonTemplateSpec
  extends Lightning.Component.TemplateSpecLoose {
  Shadow: object
  Tester: object
  Rect: {
    Image: object
    Overlay: object
  }
  Backup: {
    border: object
  }
  Vignette: object
  Clipper: {
    Text: {
      Title: object
    }
  }
  content: ContentItem
}

export const BoxArtButtonHeight = 300
export class BoxArtButton
  extends Lightning.Component<BoxArtButtonTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<BoxArtButtonTemplateSpec>
{
  private _focusAnimation: Lightning.types.Animation | undefined = undefined
  public imageSrc: string | null = null
  public action: ActionConfig | null = null
  public static width = BoxArtButtonHeight * (44 / 66)
  public static height = BoxArtButtonHeight
  public static margin = 20

  Backup = this.getByRef('Backup')!
  Clipper = this.getByRef('Clipper')!
  Text = this.Clipper.getByRef('Text')!
  Title = this.Text.getByRef('Title')!
  Vignette = this.getByRef('Vignette')!
  Rect = this.getByRef('Rect')!
  Image = this.Rect.getByRef('Image')!

  static override _template(): Lightning.Component.Template<BoxArtButtonTemplateSpec> {
    const theme = getStoredTheme()
    const {
      components: { VideoCardConfig },
    } = theme
    const radiusShader = {
      type: Lightning.shaders.RoundedRectangle,
      radius: VideoCardConfig.radius,
    }
    return {
      Shadow: {
        alpha: 0.6,
        mountX: 0.5,
        mountY: 0.5,
        x: (w: number) => w / 2,
        y: (h: number) => h / 2,
        w: (w: number) => w + 32,
        h: (h: number) => h + 32,
        color: Colors(VideoCardConfig.vignetteColor).get(),
        rect: true,
        rtt: true,
        shader: { type: Lightning.shaders.FadeOut, fade: 32 },
      },
      Rect: {
        x: 0,
        y: 0,
        w: cp,
        h: cp,
        rtt: true,
        rect: true,
        shader: radiusShader,
        Image: {
          x: 0,
          y: 0,
          rect: true,
          rtt: true,
          alpha: 0.95,
        },
      },
      Backup: {
        visible: false,
        x: 0,
        y: 0,
        w: (w: number) => w,
        h: (h: number) => h,
        shader: {
          ...radiusShader,
          stroke: 4,
          strokeColor: Colors(theme.palette.darks[100]).get(),
        },
        colorTop: Colors(theme.palette.darks[300]).get(),
        colorBottom: Colors(theme.palette.darks[700]).get(),
        rect: true,
        rtt: true,
      },
      Vignette: {
        alpha: 0.0001,
        x: 0,
        y: 0,
        w: (w: number) => w,
        h: (h: number) => h,
        colorBottom: Colors(VideoCardConfig.vignetteColor).get(),
        colorTop: Colors(VideoCardConfig.vignetteColor).alpha(0.01).get(),
        rect: true,
        rtt: true,
        shader: radiusShader,
      },
      Clipper: {
        x: 0,
        y: 0,
        w: (w: number) => w,
        h: (h: number) => h,
        rect: true,
        rtt: true,
        clipping: true,
        color: 0x00000000,

        shader: radiusShader,
        Text: {
          alpha: 1,
          x: 16,
          y: 16,
          flex: {
            direction: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          },
          Title: {
            color: Colors('text').get(),
            text: {
              text: '',
              fontFace: 'Bold',
              fontSize: 24,
              textAlign: 'right',
              wordWrap: true,
              maxLines: 5,
            },
          },
        },
      },
    }
  }
  private _content: ContentItem | undefined = undefined
  set content(content: ContentItem) {
    if (!isEqual(content, this._content)) {
      this._content = content
      const imageSet =
        this.content.images.box ??
        this.content.images.thumb ??
        this.content.images.wallpaper ??
        this.content.images.wide
      const width =
        isGoodNumber(this.w) && this.w > 0 ? this.w : BoxArtButton.width
      debug.info('Width? %s', width)
      const imageUrl = imageSet?.getForWidth(width)

      this.Title.patch({ text: { text: this.content.title } })
      const url = imageUrl ? Utils.proxyUrl(imageUrl) : null

      this.setImage(url)
    }
  }
  get content() {
    return this._content!
  }

  setImage(src?: string | null) {
    if (src) {
      this.imageFailed = false
      const w = isGoodNumber(this.w) ? this.w : BoxArtButton.width
      const h = isGoodNumber(this.h) ? this.h : BoxArtButton.height
      this.Image.patch({
        w,
        h,
        x: 0,
        y: 0,
        rect: true,
        rtt: true,
        texture: Img(src).cover(w, h),
      })
    } else {
      this.imageFailed = true
    }
  }
  set imageFailed(failed: boolean) {
    if (this._imageFailed !== failed) {
      this._imageFailed = failed
      if (failed) {
        this.Backup.patch({
          visible: true,
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: 8,
            stroke: 4,
          },
        })
        this.Vignette.setSmooth('alpha', 1, { duration: 0.2 })
        this.Text.setSmooth('alpha', 1, { duration: 0.2 })
      } else {
        this.Backup.patch({
          visible: false,
        })
      }
    }
  }

  private _imageFailed: boolean = false

  private _textFocusAnimation: Lightning.types.Animation | undefined = undefined
  get textFocusAnimation() {
    if (!this._textFocusAnimation) {
      this._textFocusAnimation = this.animation({
        duration: 0.2,
        delay: 0.05,
        actions: [
          { t: 'Clipper.Text', p: 'y', v: { 0: 36, 1: 0 } },
          { t: 'Clipper.Text', p: 'alpha', v: { 0: 0.0001, 1: 1 } },
          { t: 'Vignette', p: 'alpha', v: { 0: 0.0001, 1: 1 } },
        ],
      })
    }
    return this._textFocusAnimation
  }

  override _init() {
    const dimensionsPatch = {
      h: this.h ?? BoxArtButton.height,
      w: this.w ?? BoxArtButton.width,
    }
    this.patch({
      ...dimensionsPatch,
      Shadow: {
        x: dimensionsPatch.w / 2,
        y: dimensionsPatch.h / 2,
        w: dimensionsPatch.w + 32,
        h: dimensionsPatch.h + 32,
      },
      Backup: {
        ...dimensionsPatch,
      },
      Vignette: {
        ...dimensionsPatch,
      },
      Clipper: {
        ...dimensionsPatch,
        Text: {
          h: dimensionsPatch.h - 32,
          w: dimensionsPatch.w - 32,
          Title: {
            text: {
              wordWrapWidth: dimensionsPatch.w - 32,
            },
          },
        },
      },
      Rect: {
        ...dimensionsPatch,
        Image: {
          ...dimensionsPatch,
        },
      },
    })

    this.Image.on('txError', () => {
      this.imageFailed = true
    })

    const theme = this.fireAncestors('$theme')
    const {
      components: { VideoCardConfig },
    } = theme
    this._focusAnimation = this.animation({
      duration: 0.2,
      actions: [
        { p: 'scale', v: { 0: 1, 1: 1.1 } },
        {
          t: 'Shadow',
          p: 'color',
          v: {
            0: Colors(VideoCardConfig.vignetteColor).get(),
            1: Colors('primaryHighlight').get(),
          },
        },
        {
          t: 'Shadow',
          p: 'alpha',
          v: {
            0: 0.8,
            1: 1,
          },
        },
        { t: 'Rect.Overlay', p: 'alpha', v: { 0: 0.2, 1: 0 } },
      ],
    })
  }

  override _focus() {
    this._focusAnimation?.start()
    debug.info('Focus', this)
    if (!this._imageFailed) {
      this.textFocusAnimation.start()
    }
  }

  override _unfocus() {
    this._focusAnimation?.stop()
    if (!this._imageFailed) {
      this.textFocusAnimation.stop()
    }
  }
}

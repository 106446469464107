import {
  ContentItem,
  LightningDimensions,
  ListIndexChange,
} from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import isNumber from 'lodash-es/isNumber'
import {
  CardDetails,
  hashCode,
  isGoodArray,
  isGoodNumber,
  makeClosestFocusable,
} from '../lib'
import { AdeBoxArtButton } from './AdeBoxArtButton'

export interface AdeCardRowTemplateSpec
  extends Lightning.Component.TemplateSpecLoose {
  items: ContentItem[]
  Content: {
    Label: {
      LabelText: object
    }
    RowList: typeof List
  }
  itemType: DimensionItem
  actionPath: keyof ContentItem['paths']
  handleSelected: boolean
}

export interface AdeCardRowTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: {
    selected(item: ContentItem | null): void
  }
}

export interface DimensionCard {
  width: number
  height: number
  margin?: number
}

export interface DimensionItem extends DimensionCard {
  new (...a: any[]): Lightning.Component
}

export class BaseCardRow
  extends Lightning.Component<AdeCardRowTemplateSpec, AdeCardRowTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<AdeCardRowTemplateSpec>
{
  private _label: string = ''
  static height = AdeBoxArtButton.height + 100
  private _itemType: DimensionCard = AdeBoxArtButton
  public actionPath: keyof ContentItem['paths'] = 'details'
  public handleSelected: boolean = true
  static override _template() {
    return {
      w: 1920 - 160,
      x: 0,
      h: AdeBoxArtButton.height + 100,
      Content: {
        Label: {
          x: 20,
          y: 0,
          h: 60,
          w: 400,
          LabelText: {
            x: 0,
            y: 0,
            h: 36,
            w: 400,
            color: Colors('white').get(),
            text: {
              text: '',
              fontFace: 'Bold',
              fontSize: 36,
              lineHeight: 36,
              textAlign: 'left',
              wordWrap: false,
            },
          },
        },

        RowList: {
          x: 0,
          y: 80,
          h: AdeBoxArtButton.height,
          w: 1920 - 160,
          spacing: 30,
          type: List,
          direction: 'row',
          signals: {
            onIndexChanged: '_onIndexChanged',
          },
        },
      },
    }
  }

  public itemDimensions: LightningDimensions | null = null
  private _currentIndex: number = 0

  _onIndexChanged({ index }: ListIndexChange) {
    this._currentIndex = index
  }
  get currentItem() {
    return this.items[this._currentIndex]
  }
  override _captureEnter() {
    if (this.handleSelected) {
      if (this.currentItem) {
        this.fireAncestors('$navigate', this.currentItem.paths[this.actionPath])
      }
      return false
    } else {
      this.signal('selected', this.currentItem ?? null)
      return false
    }
  }

  set label(label: string) {
    this._label = label
    this.tag('Content.Label.LabelText')?.patch({
      text: { text: label },
    })
  }

  get label() {
    return this._label
  }

  private _items: ContentItem[] = []
  set items(content: ContentItem[]) {
    this._items = content
    this.patchForCard()
  }

  get items() {
    return this._items
  }

  set itemType(x: DimensionCard) {
    if (!isNumber(x.height) || !isNumber(x.width)) {
      console.warn('Invalid item type passed to AdeArtBoxRow', x)
      return
    }
    this._itemType = x
    this.patchForCard()
  }

  get itemType() {
    return this._itemType
  }

  private _spacing: number = 40
  set spacing(x: number) {
    this._spacing = x
    this.patchForCard()
  }
  get spacing() {
    return this._spacing
  }

  override _init() {
    this.patchForCard()
  }

  get numberOfCards(): number {
    this._numberOfCards = Math.floor(
      this.w / (this._itemType.width + this.spacing),
    )
    return this._numberOfCards
  }

  get scrollWidth(): number {
    return (
      this.numberOfCards * (this._itemType.width + this.spacing) -
      this.spacing / 2
    )
  }

  get listHeightWithMargin(): number {
    return (
      this._itemType.height +
      (isGoodNumber(this._itemType.margin) ? this._itemType.margin : 0)
    )
  }

  private _numberOfCards: number = 5

  patchForCard() {
    if (isGoodArray<ContentItem>(this._items)) {
      this.patch({
        h: this.listHeightWithMargin,
        Content: {
          w: this.scrollWidth,
          h: this.listHeightWithMargin,
          RowList: {
            h: this.listHeightWithMargin,
            w: this.scrollWidth,
            spacing: this.spacing,
            itemType: this._itemType,
            items: this._items.map(v => ({
              w: this._itemType.width,
              h: this._itemType.height,
              content: v,
              action: {
                action: 'navigate',
                payload: v.paths.details,
              },
            })),
          },
        },
      })
      const scroll = {
        forward: this.scrollWidth,
        backward: 0,
      }
      if (this._items.length > this._numberOfCards) {
        this.tag('Content.RowList').scroll = scroll
      }
    }
  }
  list() {
    return this.tag('Content.RowList')
  }
  cardDetails(): CardDetails {
    return {
      numberOfVisibleCards: this.numberOfCards,
      cardWidth: this._itemType.width,
      cardHeight: this._itemType.height,
    }
  }

  listId() {
    if (Array.isArray(this.items)) {
      const ids = this.items.map(({ id }) => id)
      return hashCode(ids.join(':'))
    } else {
      return null
    }
  }
}
// @ts-ignore - Forcing This..
export const AdeCardRow = makeClosestFocusable(BaseCardRow)

import { DirectionalSignalMap } from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'
import isString from 'lodash-es/isString'
import { Debugger } from '../lib'
import { getStoredTheme } from '../themes'

import {
  KEYBOARD_HEIGHT,
  KEYBOARD_WIDTH,
  SearchKeyboard,
} from './SearchKeyboard'
import { SearchTextInput } from './SearchTextInput'

const debug = new Debugger('SP')

export interface SearchBarTextTemplateSpec
  extends Lightning.Component.TemplateSpec {
  SearchTextBackground: object
  SearchText: object
  TextInput: typeof SearchTextInput
  PlaceholderText: object
  Keyboard: typeof SearchKeyboard
  AlertText: object
  setQuery(query: string): void
  lines: number
}
export interface SearchBarSignalMap extends DirectionalSignalMap {
  query(term: string): void
}
export interface SearchBarTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: SearchBarSignalMap
}

export class SearchBar extends Lightning.Component<
  SearchBarTextTemplateSpec,
  SearchBarTypeConfig
> {
  static width = KEYBOARD_WIDTH
  TextInput = this.getByRef('TextInput')!
  PlaceholderText = this.getByRef('PlaceholderText')!
  static override _template(): Lightning.Component.Template<Lightning.Component.TemplateSpecLoose> {
    const theme = getStoredTheme()
    return {
      w: 1920 - 160,
      h: 360,
      x: 0,
      y: 80,
      TextInput: {
        w: KEYBOARD_WIDTH,
        lines: 2,
        type: SearchTextInput,
        searchText: '',
        signals: {
          stringTooLong: '_showStringTooLongError',
          stringGood: '_hideStringError',
        },
      },
      PlaceholderText: {
        x: 20,
        y: 16,
        w: 800,
        h: 80,
        alpha: 0.5,
        text: {
          text: 'Search',
          fontFace: 'Text',
          fontSize: 20,
          lineHeight: 40,
          wordWrapWidth: 1200,
        },
      },
      Keyboard: {
        x: 0,
        y: 140,
        type: SearchKeyboard,
        passSignals: {
          left: true,
          up: true,
          down: true,
          right: true,
        },
      },
      AlertText: {
        x: 0,
        y: 150 + KEYBOARD_HEIGHT,
        w: KEYBOARD_WIDTH,
        color: Colors(theme.palette.errorText).get(),
        alpha: 0,
        text: {
          text: 'Text is too long, please try a shorter search.',
          textAlign: 'left',
          fontFace: 'Regular',
          fontSize: 20,
          lineHeight: 28,
        },
      },
    }
  }

  setQuery(query: string) {
    query = query.trim().replace(/\s+/, '').substring(0, this.TextInput.maxText)
    this.query = query
  }
  private _query: string = ''
  set query(text: string) {
    text = text.trim().replace(/\s+/, '').substring(0, this.TextInput.maxText)
    if (this._query !== text) {
      this.TextInput.patch({
        searchText: text,
      })
      this._query = text
      this.PlaceholderText.patch({
        visible: text === '',
      })
      this.signal('query', this.query)
    }
  }

  get query() {
    return this._query
  }

  addLetter(x: string) {
    if (isString(x) && x.length > 1) {
      switch (x.toLowerCase()) {
        case 'del':
          if (this.query.length > 0) this.query = this.query.slice(0, -1)
          break
        case 'space':
          if (!/\s$/.test(this.query)) this.query = `${this.query} `
          break
        default:
          console.warn('Got invalid letter')
      }
    } else if (isString(x)) {
      this.query += x
    }
  }

  $searchKey(x: string) {
    this.addLetter(x)
  }
  private _showErrorAnimation: Lightning.types.Animation | null = null
  get showErrorAnimation(): Lightning.types.Animation {
    if (this._showErrorAnimation !== null) return this._showErrorAnimation
    this._showErrorAnimation = this.animation({
      duration: 0.2,
      stopMethod: 'reverse',
      repeat: 1,
      actions: [
        {
          t: 'AlertText',
          p: 'alpha',
          v: { 0: 0, 1: 1 },
        },
      ],
    })
    return this._showErrorAnimation
  }

  _showStringTooLongError(str: string) {
    debug.info('Show stringTooLong Error', str)
    this._query = str
    this.showErrorAnimation.start()
  }
  _hideStringError() {
    debug.info('Hide stringTooLong Error')
    this.showErrorAnimation.stop()
  }
  override _getFocused() {
    return this.tag('Keyboard')
  }
}

import { ContentItem, MediaDetails } from '@adiffengine/engine-types'
export interface LoaderOpts {
  vastUrl?: string
}
export interface VideoDetails {
  media?: MediaDetails
  content?: ContentItem
}
export type VideoLoader = (url: string, video: HTMLVideoElement) => void
export type CreateVideoLoader = (
  details: VideoDetails,
  opts?: LoaderOpts
) => VideoLoader

export interface ADEVastPlayerSources {
  src: string
  adUrl?: string | null
}
export type IsBooleanOrString<T> = T extends true ? string : number
export const ADE_CONTAINER_ID = 'ade-vast'
export const ADE_CONTAINER_CLASS = 'ade-vast-container'
export const ADE_CONTENT_CLASS = 'ade-vast-content'
export const ADE_VAST_VIDEO_ID = 'ade-vast-video'
export const ADE_VAST_VIDEO_CLASS = 'ade-vast-video'

export enum PlayControlHoverButtons {
  next = 'next',
  previous = 'previous',
  skip_forward = 'skip_forward',
  toggle_playpause = 'toggle_playpause',
  skip_back = 'skip_back',
  pause = 'pause',
  play = 'play',
  unknown = 'unknown',
}
export type PlayControlHoverName = `${PlayControlHoverButtons}`

import { Colors, Lightning } from '@lightningjs/sdk'

export class AdeTopMenu extends Lightning.Component {
  static override _template(): Lightning.Component.Template<Lightning.Component.TemplateSpecLoose> {
    return {
      x: 80,
      y: 80,
      w: 1920 - 160,
      h: 80,
      rect: true,
      color: Colors('#00cc00').alpha(0.4).get(),
      Content: {
        w: (w: number) => w,
        h: (h: number) => h,
        x: 0,
        y: 0,
        flex: {
          alignItems: 'center',
          justifyContent: 'flex-start',
        },
      },
    }
  }
}

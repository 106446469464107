import { ContentItem, ID } from '@adiffengine/engine-types'
import { Lightning, Registry } from '@lightningjs/sdk'
import isEqual from 'fast-deep-equal/es6'
import { Debugger } from '../../lib'
import { HeroWidgetItem } from './HeroWidgetItem'
import { HeroContentSetup, defaultDetailCoordinates } from './StaticHeroSetup'
const debug = new Debugger('HeroWidget')
export interface HeroWidgetTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: object
  Overlay: object
  content: ContentItem | null
  // config: Partial<HeroContentSetup>
}

export interface HeroWidgetTypeConfig extends Lightning.Component.TypeConfig {
  IsWidget: true
}
export class HeroWidget
  extends Lightning.Component<HeroWidgetTemplateSpec, HeroWidgetTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<HeroWidgetTemplateSpec>
{
  Content = this.getByRef('Content')!
  static defaultDetailCoordinates: HeroContentSetup = defaultDetailCoordinates

  static override _template(): Lightning.Component.Template<HeroWidgetTemplateSpec> {
    return {
      x: 0,
      y: 0,
      w: (w: number) => w,
      h: 800,
      rect: true,
      rtt: true,
      color: 0x00000000,
      shader: {
        type: Lightning.shaders.FadeOut,
        bottom: 200,
      },
      Content: {
        x: 0,
        y: 0,
        w: 1920,
        h: 800,
      },
    }
  }

  private _detailCoordinates: HeroContentSetup =
    HeroWidget.defaultDetailCoordinates

  get detailCoords() {
    return this._detailCoordinates
  }

  removeItem(id: ID) {
    const index = this.Content.children.findIndex(item => item.id === id)
    if (index > -1) {
      this.Content.childList.removeAt(index)
    }
  }

  lastInList(): HeroWidgetItem | undefined {
    if (this.Content.childList.length > 0) {
      const old = this.Content.childList.getAt(
        this.Content.childList.length - 1,
      ) as HeroWidgetItem
      return old
    } else {
      return undefined
    }
  }

  private _content: ContentItem | null = null
  _contentTimeout: ReturnType<typeof Registry.setTimeout> | null = null
  setContent(item: ContentItem | null) {
    debug.info('Setting currrent content to ', item)
    if (item === null) {
      debug.info('Got null on hero widget')
    }
    if (this.content && item) {
      this.setSmooth('alpha', 1, { duration: 0.2 })
      if (this.content.id !== item.id) {
        if (this._contentTimeout !== null)
          Registry.clearTimeout(this._contentTimeout)
        this._contentTimeout = null
        this._contentTimeout = Registry.setTimeout(() => {
          this.content = item
          this._contentTimeout = null
        }, 500)
      }
    } else if (item) {
      this.content = item
    } else {
      this.Content.childList.clear()
      this.content = null
    }
  }

  override _onActivated() {
    debug.info('Hero Widget Activated')
  }

  set content(content: ContentItem | null) {
    if (isEqual(content, this._content)) {
      return
    }
    if (content === null) {
      this.stage.application.emit('currentStaticHero', content)
      this._content = null
      return
    }
    const nextContent = this.stage.c<typeof HeroWidgetItem>({
      type: HeroWidgetItem,
      content,
      alpha: 0.0001,
      signals: {
        removeItem: this.removeItem.bind(this),
      },
    }) as HeroWidgetItem
    if (this.Content.childList.length > 0) {
      const old = this.Content.childList.getAt(
        this.Content.childList.length - 1,
      ) as HeroWidgetItem | undefined
      if (old) old.hideHero()
    }
    this.Content.childList.add(nextContent)
    nextContent.showHero()
    this._content = content
    this.stage.application.emit('currentStaticHero', content)
  }
  get content() {
    return this._content
  }
}

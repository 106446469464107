import { Parameters } from '@firebolt-js/sdk'
import { isEntityIntent } from './firebolt-utils'
import {
  AdParameters,
  AdeLifecycleBootOptions,
  EnhancedBootParams,
  FireboltAdParamters,
  Lmt,
} from './lifecycle-types'

import { isNumber } from '../utils'
import { isGoodString } from '../lightning-tools'
function isTizen() {
  return typeof window.tizen !== 'undefined'
}
function isLmt(x: unknown): x is 0 | 1 {
  return isNumber(x) && (x === 0 || x === 1)
}
function isStub(params: Parameters.AppInitialization) {
  return (
    params.discovery?.navigateTo != null &&
    isEntityIntent(params.discovery.navigateTo) &&
    params.discovery.navigateTo.data.entityId === 'abc'
  )
}

async function getAdParams(
  params: Parameters.AppInitialization,
  uid: string
): Promise<AdParameters> {
  if (isTizen()) {
    try {
      console.info('On tizen, getting ad parameters')
      const ifa = webapis.adinfo.getTIFA()
      const latEnabled = webapis.adinfo.isLatEnabled()
      const out: EnhancedBootParams['adParameters'] = {
        lmt: (latEnabled ? 0 : 1) as Lmt,
        ifa,
        ifa_type: 'tifa',
      }
      console.info('Got tizen values', out)
      return out
    } catch (error) {
      console.warn('Unable to get samsung ad params %s', error.message, error)
      return {
        lmt: 1,
      }
    }
  } else if (isStub(params)) {
    return {
      lmt: 1,
    }
  } else {
    const lmt = isLmt(params.lmt) ? params.lmt : 1
    const usPrivacy = isGoodString(params.us_privacy)
      ? { us_privacy: params.us_privacy }
      : {}
    const out: FireboltAdParamters = {
      lmt,
      did: uid,
    }
    if (isGoodString(params.us_privacy)) {
      out.us_privacy = params.us_privacy
    }
    return {
      lmt,
      ...usPrivacy,
    } as FireboltAdParamters
  }
}
export async function initializeParameters(
  bootOptions: AdeLifecycleBootOptions,
  uid: string
) {
  const params = await Parameters.initialization()
  const isStub =
    params.discovery?.navigateTo != null &&
    isEntityIntent(params.discovery.navigateTo) &&
    params.discovery.navigateTo.data.entityId === 'abc'

  console.info(
    `[ Firebolt Calls] Parameters Initialization done, params are %s`,
    isStub ? 'from stub' : 'not a stub',
    params
  )

  const adParameters = await getAdParams(params, uid)
  const finalParams = isStub ? bootOptions.sampleParams : params
  return {
    ...finalParams,
    adParameters,
  }
}

import {
  ContentEpisode,
  ContentItem,
  ContentSeason,
} from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'

import isEqual from 'lodash-es/isEqual'
import isString from 'lodash-es/isString'
import {
  Debugger,
  defer,
  getCoordinateDimensions,
  isGoodArray,
  isGoodNumber,
} from '../../lib'
import { AdeBoxArtButton } from '../AdeBoxArtButton'
import { AdvancedBoxCard, AdvancedGridCardTypes } from '../AdvancedGrid'
import {
  AdvancedGridList,
  MoreGridListPatch,
} from '../AdvancedGrid/AdvancedGridList'
import { SimpleCardList } from '../SimpleCardList'
import { SimpleHorizontalCardHeaderList } from '../SimpleHorizontalCardHeaderList'
import {
  EpisodeCardConfig,
  SimpleContentItem,
} from '../SimpleHorizontalContentCard'
import { MoreTab } from './MoreTab'
const debug = new Debugger('PlayerMore')
debug.tag('MoreTest')
Debugger.setFocused('MoreTest')
export interface PlayerMoreTemplateSpec
  extends Lightning.Component.TemplateSpec {
  MoreTab: typeof MoreTab
  // MoreListWrapper: {
  //   MoreList: typeof AdvancedGridList
  // }
  MoreList: typeof AdvancedGridList
  EpisodeList: typeof SimpleCardList
  listTitle: string | null | undefined
  season: ContentSeason | null | undefined
  more: ContentItem[]
  cardType: AdvancedGridCardTypes
}

export interface PlayerMoreSignalMap {
  up(): void
  showMore(): void
  hideMore(navigate?: boolean): void
  hovered(): void
}

export interface PlayerMoreTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: PlayerMoreSignalMap
}
export class PlayerMore
  extends Lightning.Component<PlayerMoreTemplateSpec, PlayerMoreTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<PlayerMoreTemplateSpec>
{
  static height = AdeBoxArtButton.height + AdeBoxArtButton.margin + 20 + 74
  static tabHeight = 74
  _more: ContentItem[] = []

  MoreTab = this.getByRef('MoreTab')!
  MoreTabText = this.MoreTab.getByRef('Text')!
  // MoreListWrapper = this.getByRef('MoreListWrapper')!
  // MoreList= this.MoreListWrapper.getByRef('MoreList')!
  MoreList = this.getByRef('MoreList')!
  EpisodeList = this.getByRef('EpisodeList')!

  static override _template(): Lightning.Component.Template<PlayerMoreTemplateSpec> {
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: PlayerMore.height,
      visible: true,
      MoreTab: {
        x: 0,
        y: 0,
        type: MoreTab,
        signals: {
          up: '_upFromMoreTab',
          down: '_focusList',
          selected: '_toggleState',
          hovered: '_hovered',
        },
      },

      MoreList: {
        alpha: 0.0001,
        type: AdvancedGridList,
        w: 1920 - 240,
        h: AdvancedGridList.getRowHeight(1920 - 240, AdvancedBoxCard),
        x: 120,
        y: 94,
        focusable: false,
        cardSetup: AdvancedBoxCard.cardSetup,
        card: AdvancedBoxCard,
        signals: {
          up: '_focusMoreTab',
          down: '_cancelDirection',
          hovered: '_focusList',
        },
      },
      // },

      EpisodeList: {
        alpha: 0.0001,
        type: SimpleHorizontalCardHeaderList,
        x: 120,
        y: 94,
        cardConfig: EpisodeCardConfig,
        width: 1920 - 240,
        signals: {
          up: '_focusMoreTab',
          contentSelected: '_episodeSelected',
          hovered: '_focusList',
        },
        title: 'More Episodes',
      },
    }
  }
  private _cardType: AdvancedGridCardTypes = AdvancedBoxCard
  set cardType(card: AdvancedGridCardTypes) {
    if (card !== this._cardType) {
      this._cardType = card
      this.MoreList.patch({
        card,
        cardSetup: card.cardSetup,
        h: AdvancedGridList.getRowHeight(1920 - 240, card),
      })
    }
  }

  override _active() {
    this._setState('DownState')
  }

  get cardType() {
    return this._cardType
  }

  _cancelDirection() {
    return true
  }
  _hovered() {
    this.signal('hovered')
    this._focusMoreTab
  }
  _focusMoreTab() {
    defer(() => {
      this.focusable = this.MoreTab
    })
    return true
  }

  override _firstActive() {
    this._reset = this._reset.bind(this)
    this.stage.application.on('playerContentItemChanged', this._reset)
  }
  _reset() {
    this._season = null
    this.focusable = this.MoreTab
    this.MoreList.patch({
      content: null,
      alpha: 0.0001,
    })
    this.EpisodeList.patch({
      items: [],
      title: '',
      alpha: 0.0001,
    })
    this._more = []
    this.moreText = 'More Like This'
  }
  _currentList: typeof this.MoreList | typeof this.EpisodeList = this.MoreList
  set currentList(list: typeof this._currentList) {
    if (list !== this._currentList) {
      debug.info('Patching List visiblility', list, this._currentList)
      this._currentList.patch({ visible: false })
      this._currentList = list
      this._currentList.patch({ visible: true })
    }
  }
  get currentList() {
    return this._currentList
  }

  override _init() {
    let width = this.finalW
    width = isGoodNumber(width, true) ? width : 400
    this.MoreList.patch({
      w: width - 160,
    })
    this.EpisodeList.patch({
      w: width - 160,
    })
  }

  _episodeSelected(item: SimpleContentItem | null) {
    const episode =
      this._season?.episodes && item
        ? this._season.episodes.find(({ episodeId }) => episodeId === item.id)
        : undefined
    if (episode) {
      const path =
        episode.air_date && episode.air_date.getTime() < new Date().getTime()
          ? `player/tv/${this._season!.show_id}/episode/${
              this._season!.number
            }/${episode.episodeNumber}`
          : null
      if (path) {
        this.fireAncestors('$navigate', path, false)
      } else {
        console.warn('Could not play item - not yet playable?')
      }
    } else {
      console.warn('Did not find episode')
    }
  }

  $contentSelected(item: ContentItem | null) {
    console.info('Content Selected', item?.paths.player)
    this.fireAncestors('$clearPlayer')
    if (isString(item?.paths.player)) {
      this.navigateAway(item!.paths.player, false)
    } else if (isString(item?.paths.details)) {
      this.navigateAway(item!.paths.details)
    }
  }
  navigateAway(route: string, clear = true) {
    this.fireAncestors('$clearPlayer')
    this.fireAncestors('$navigate', route, clear)
  }

  _moreText: string = 'More Info'

  set moreText(text: string) {
    if (text !== this._moreText) {
      this._moreText = text
      this.MoreTabText.patch({ text: text })
    }
  }
  set more(content: ContentItem[]) {
    debug.info('Setting more', content, this._more)
    if (!isEqual(content, this._more)) {
      console.info('Got More!', content)
      const patch: MoreGridListPatch = {
        cardSetup: this.cardType.cardSetup,
        card: this.cardType,
        visible: true,
        alpha: 1,
        h: AdvancedGridList.getRowHeight(1920 - 240, this.cardType),
        content: {
          items: content,
          id: content.map(({ id }) => id).join(':'),
          title: 'More Like This',
        },
      }
      ;(window as any).__MORE_LIST = this.MoreList
      this.MoreList.patch(patch)
      debug.info(
        'Patching MoreList alpha: %s, visible: %s, attached: %s',
        this.MoreList.alpha,
        this.MoreList.visible,
        this.MoreList.attached,
        this.MoreList.active,
        this.MoreList.enabled,
        this.MoreList.h,
        this.MoreList.w,
        this.currentList
      )
    }
    this._more = content
  }
  get more() {
    return this._more
  }

  _listTitle: string = 'Related'
  set listTitle(text: string | null | undefined) {
    const update = text != null ? text : 'Related'
    if (this._listTitle !== update) {
      this._listTitle = update
      this.MoreList.patch({
        label: update,
      })
    }
  }

  _season: ContentSeason | null | undefined = null

  set season(season: typeof this._season) {
    if (season == null) {
      this.currentList = this.MoreList
      this._season = season
    } else {
      const { episodes } = season
      if (isGoodArray<ContentEpisode>(episodes)) {
        const title = season.name
          ? season.name
          : season.number
            ? `Season ${season.number}`
            : 'More Episodes'
        const items: SimpleContentItem[] = episodes.map(episode => ({
          id: episode.episodeId,
          title: episode.name,
          images: episode.image ? { wide: episode.image } : {},
          description: episode.overview ?? '',
          details: episode.episodeNumber
            ? `Season ${season.number}, Episode ${episode.episodeNumber}`
            : '',
          release: episode.air_date,
        }))
        this.EpisodeList.patch({
          items,
          title,
        })
        this.currentList = this.EpisodeList
      } else {
        this.currentList = this.MoreList
      }
    }
  }

  _focused: typeof this.MoreTab | typeof this.currentList = this.MoreTab
  set focusable(focus: typeof this._focused) {
    if (focus !== this._focused) {
      this._focused = focus
      this._refocus()
    }
  }

  get focusable(): typeof this._focused {
    return this._focused
  }

  _upFromMoreTab() {
    defer(() => {
      this._setState('DownState')
    })
    this.signal('up')
  }

  _toggleState() {
    debug.info('Setting UpState?')
    this._setState('UpState')
  }

  _focusList() {
    this._setState('UpState')
    this.focusable = this.currentList
  }

  override _getFocused() {
    return this.focusable
  }

  static override _states(): (typeof PlayerMore)[] {
    return [
      class UpState extends this {
        override _focusList() {
          this.focusable = this.currentList
        }
        override _toggleState() {
          this._setState('DownState')
        }
        override $enter() {
          debug.info('Entering Up State')
          this.signal('showMore')
          this.MoreList.patch({ focusable: true })
          this.moreText = 'Hide'
          this.MoreTab.patch({ arrowUp: true, moreText: 'Hide' })
          this.currentList.setSmooth('alpha', 1, { duration: 0.2 })
        }
        override $exit() {
          this.signal('hideMore')
          this.MoreList.patch({ focusable: false })
          this.MoreTab.patch({ arrowUp: false, moreText: 'More Info' })
          this.currentList.setSmooth('alpha', 0.0001, { duration: 0.2 })
        }
      },

      class DownState extends this {
        override $enter() {
          this.focusable = this.MoreTab
        }
        up() {
          debug.info('Up called')

          defer(() => {
            this._setState('UpState')
          })
          return true
        }
        override _captureDown() {
          return this.up()
        }
        override _captureEnter() {
          debug.info('Capture Enter call up')
          return this.up()
        }
      },
    ]
  }
}

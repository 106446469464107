import { Resolver } from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import equal from 'fast-deep-equal/es6'

import {
  FullPageModal,
  FullPageModalTemplateSpec,
} from '../screens/FullPageModal'
import { ButtonCollectionButtonConfig } from './AdeButtonCollection'
import { AdeModals } from './types'
import { defer } from '../lib/utils'
import { Debugger } from '../lib/debugger'

const debug = new Debugger('ConfirmModal')
export type ConfirmModalButtonPayload = 'yes' | 'cancel'
export interface ConfirmModalTemplateSpec
  extends Lightning.Component.TemplateSpec,
    Pick<
      FullPageModalTemplateSpec,
      | 'logo'
      | 'primaryColor'
      | 'secondaryColor'
      | 'primaryText'
      | 'secondaryText'
    > {
  Content: typeof FullPageModal<ConfirmModalButtonPayload>
  modalName: string
}

export interface ConfirmModalTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: {
    confirm(arg: boolean): void
  }
}

export class ConfirmModal
  extends Lightning.Component<ConfirmModalTemplateSpec, ConfirmModalTypeConfig>
  implements
    Lightning.Component.ImplementTemplateSpec<ConfirmModalTemplateSpec>
{
  public modalName = AdeModals.ConfirmModal
  Content = this.getByRef('Content')!
  static override _template(): Lightning.Component.Template<ConfirmModalTemplateSpec> {
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      Content: {
        visible: false,
        type: FullPageModal<ConfirmModalButtonPayload>,
        primaryText: 'Are you sure you want to exit?',
        secondaryText:
          'Selecting "Yes" will return you to the main menu. "Cancel" will return you to the application.',
        buttons: [],
        signals: {
          selected: '_confirm',
          visible: '_visible',
        },
      },
    }
  }

  static defaultButtonConfig: ButtonCollectionButtonConfig<ConfirmModalButtonPayload>[] =
    [
      {
        buttonText: 'Yes',
        payload: 'yes',
      },
      {
        buttonText: 'Cancel',
        payload: 'cancel',
      },
    ]
  private _currentButtonConfig: ButtonCollectionButtonConfig<ConfirmModalButtonPayload>[] =
    ConfirmModal.defaultButtonConfig

  _visible(visible: boolean) {
    this.patch({ zIndex: visible ? 100 : 0 })
  }

  private _resolver: Resolver<boolean> | null = null
  override _init() {
    this.Content.patch({
      buttons: ConfirmModal.defaultButtonConfig,
    })
  }

  public confirm(
    primaryText: string,
    secondaryText: string,
    hasCancel = true
  ): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this._resolver = (value: boolean | PromiseLike<boolean>) => {
        resolve(value)
        this._resolver = null
      }
      const buttons = [ConfirmModal.defaultButtonConfig[0]]
      if (hasCancel) buttons.push(ConfirmModal.defaultButtonConfig[1])
      const buttonUpdate = !equal(this._currentButtonConfig, buttons)
      this.Content.patch({
        visible: true,
        primaryText,
        secondaryText,
        buttons,
        signals: {
          selected: this._confirm.bind(this),
          visible: this._visible.bind(this),
        },
      })
      if (buttonUpdate) {
        this._refocus()
      }
    })
  }

  public set primaryText(primaryText: string) {
    this.Content.patch({ primaryText })
  }
  public get primaryText() {
    return this.Content.primaryText
  }
  public set secondaryText(secondaryText: string) {
    this.Content.patch({ secondaryText })
  }
  public get secondaryText() {
    return this.Content.secondaryText
  }

  public set primaryColor(primaryColor: number) {
    this.Content.patch({ primaryColor })
  }
  public get primaryColor() {
    return this.Content.primaryColor
  }

  public set secondaryColor(secondaryColor: number) {
    this.Content.patch({ secondaryColor })
  }
  public get secondaryColor() {
    return this.Content.secondaryColor
  }
  public set logo(logo: string) {
    this.Content.patch({ logo })
  }
  public get logo() {
    return this.Content.logo
  }

  override _handleBack(): boolean | void {
    return this._captureBack()
  }
  _captureBackspace() {
    return this._captureBack()
  }
  override _focus() {
    this.application.emit('modal', this.modalName, true)
  }
  override _unfocus() {
    this.application.emit('modal', this.modalName, false)
  }
  override _captureBack() {
    debug.info('Capturing back')
    if (this.hasFocus() && this._resolver) {
      defer(() => {
        if (this._resolver) this._resolver(false)
      })
    }
    return true
  }

  private _confirm(confirmation: ConfirmModalButtonPayload) {
    this.signal('confirm', confirmation === 'yes')
    if (this._resolver) this._resolver(confirmation === 'yes')
  }

  override _getFocused() {
    return this.Content
  }
}

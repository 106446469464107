import {
  AdeLifecycleEvents,
  ContentItem,
  PageTrackingEvent,
  SearchEvent,
  VideoEvent,
  VideoRateChangeEvent,
  VideoRenditionChangeEvent,
  VideoTrackingEvent,
  VideoTrackingEvents,
  VideoViewEvent,
  VideoViewTrackingEvent,
} from '@adiffengine/engine-types'
import { Metrics } from '@firebolt-js/sdk'
import { Settings } from '@lightningjs/sdk'
import * as Sentry from '@sentry/browser'
import Emittery from 'emittery'
// @ts-ignore
import { isCodedError } from '../coded-error'
import { Debugger } from '../debugger'
import { ThorError, getFireboltErrorType, isThorError } from '../thor-error'
import { parseIdFromPath, safeHash } from '../utils'
import { GaTracker } from './trackers/ga'
import { PlausibleTracker } from './trackers/plausible'

const debug = new Debugger('metrics')

debug.enabled = false

const hashBlackList = ['unknown', '$']

interface MetricsEvents extends AdeLifecycleEvents {
  screen_view_event: PageTrackingEvent
  video_playback_event: VideoTrackingEvent
  video_view_event: VideoViewTrackingEvent
  search_event: SearchEvent
}
export class ThorMetrics extends Emittery<MetricsEvents> {
  private _sentryEnabled = false

  constructor() {
    super()
    const dsn = Settings.get('app', 'SENTRY_DSN', false)
    this._sentryEnabled = dsn != null
    GaTracker.trackEvents(this)
    PlausibleTracker.trackEvents(this)
  }

  trackVideoPlayerEvent(event: VideoEvent) {
    debug.info('Tracking Video Player Event: %s', event.video_event, event)
    if (event.video_event !== 'progress') {
      this.emit('video_playback_event', {
        type: 'video_playback_event',
        payload: event,
      })
    }
    const id = `${event.video_id}`
    switch (event.video_event) {
      case 'ended':
        Metrics.mediaEnded(id)
        break
      case 'load_start':
        Metrics.mediaLoadStart(id)
        break
      case 'pause':
        Metrics.mediaPause(id)
        break
      case 'play':
        Metrics.mediaPlaying(id)
        break
      case 'waiting':
        Metrics.mediaWaiting(id)
        break
      case 'rate_change':
        Metrics.mediaRateChange(
          id,
          (event as VideoRateChangeEvent).rate_data.bitrate,
        )
        break
      case 'rendition_change':
        Metrics.mediaRenditionChange(
          id,
          (event as VideoRenditionChangeEvent).rendition_data.bitrate,
          (event as VideoRenditionChangeEvent).rendition_data.width,
          (event as VideoRenditionChangeEvent).rendition_data.height,
          (event as VideoRenditionChangeEvent).rendition_data.codecSet,
        )
        break
      case 'seeked':
        Metrics.mediaSeeked(id, event.video_position)
        break
      case 'progress':
        Metrics.mediaProgress(id, event.video_progress)
        break
    }
  }

  videoViewEvent(event: VideoViewEvent) {
    this.emit('video_view_event', {
      type: 'video_view_event',
      payload: event,
    })
  }

  private _lastPath: string | null = null
  screenView(
    firebase_screen: string,
    firebase_screen_class?: string,
    page_title?: string,
  ) {
    debug.info(
      'Tracking Screen View',
      firebase_screen,
      firebase_screen_class,
      page_title,
    )
    const hash = safeHash()

    const emittingPayload: PageTrackingEvent['payload'] = {
      path: '/',
      page_type: firebase_screen,
      page_class: firebase_screen_class,
    }
    if (hash && !hashBlackList.includes(hash)) {
      Metrics.page(hash)
      emittingPayload['path'] = `/${hash.replace(/^#/, '')}`
    } else if (hash === '$') {
      emittingPayload['path'] = `/`
    }

    if (page_title) {
      emittingPayload['page_title'] = page_title
    }

    const id = parseIdFromPath(emittingPayload['path'])
    if (id) {
      emittingPayload['content_id'] = id
    }

    if (emittingPayload['path'] !== this._lastPath) {
      this.emit('screen_view_event', {
        type: 'screen_view_event',
        payload: emittingPayload,
      })
      this._lastPath = emittingPayload['path']
    }
  }

  mediaRateChange(content: ContentItem, bitrate: number) {
    this.trackVideoPlayerEvent({
      video_event: 'rate_change',
      rate_data: {
        bitrate,
      },
      video_id: content.id,
      video_title: content.title,
    })
  }
  mediaRenditionChange(
    content: ContentItem,
    bitrate: number,
    width: number,
    height: number,
    codecSet: string,
  ) {
    this.trackVideoPlayerEvent({
      video_event: 'rendition_change',
      rendition_data: {
        bitrate,
        width,
        height,
        codecSet,
      },
      video_id: content.id,
      video_title: content.title,
    })
  }

  private _sendEvent(
    content: ContentItem,
    video_event: VideoTrackingEvents,
    extra?: Partial<VideoEvent>,
  ) {
    this.trackVideoPlayerEvent({
      video_event,
      video_id: content.id,
      video_title: content.title,
      ...extra,
    })
  }
  mediaLoadStart(content: ContentItem) {
    this._sendEvent(content, 'load_start')
  }
  mediaPause(content: ContentItem) {
    this._sendEvent(content, 'pause')
  }
  mediaPlaying(content: ContentItem) {
    this._sendEvent(content, 'play')
  }
  mediaProgress(content: ContentItem, progress: number) {
    this._sendEvent(content, 'progress', {
      video_progress: progress,
    })
  }
  mediaWaiting(content: ContentItem) {
    this._sendEvent(content, 'waiting')
  }
  mediaSeeked(content: ContentItem, progress: number) {
    this._sendEvent(content, 'seeked', {
      video_position: progress,
    })
  }

  search(event: SearchEvent) {
    this.emit('search_event', event)
  }

  stopContent() {
    Metrics.stopContent()
  }
  startContent(_c?: ContentItem) {
    Metrics.startContent()
  }

  error(message: string, error?: Error) {
    debug.info('Got Error %s', message, error)
    console.warn(
      'got error %s',
      message,
      error
        ? {
            error,
          }
        : { error: 'No Error passed' },
    )
    if (this._sentryEnabled) {
      Sentry.captureException(error ?? new Error(message))
    } else {
      debug.info('Sentry Notifications are not on.')
    }
    if (error && isCodedError(error)) {
      console.warn(
        'Got coded error still, this should be converted to a "ThorError"',
        error.stack,
      )
      Metrics.error(
        error.errorType,
        error.errorCode,
        error.message,
        error.visible,
        error.extra,
      )
    } else {
      const e: ThorError = isThorError(error)
        ? error
        : new ThorError(
            error ? error.message : message,
            ThorError.Type.UnknownError,
          )
      console.info(
        '[ Firebolt Calls ] Calling firebolt metrics for %s',
        e.message,
        {
          type: getFireboltErrorType(e),
          code: e.displayCode,
          visible: e.visible,
          details: e.details,
        },
      )
      Metrics.error(
        getFireboltErrorType(e),
        e.displayCode,
        e.message,
        e.visible,
        e.details,
      )
    }
  }

  breadcrumb(crumb: Sentry.Breadcrumb) {
    if (this._sentryEnabled) {
      Sentry.addBreadcrumb({ timestamp: new Date().getTime(), ...crumb })
    }
  }

  warn(msg: string, error: ThorError) {
    if (this._sentryEnabled) {
      Sentry.captureMessage(msg, { extra: { error: error ?? null } })
    } else {
      debug.info('Sentry Notifications are not on.')
    }
  }
}

import { AdeCloseReason } from '@adiffengine/engine-types'
import { Registry, Router, Settings } from '@lightningjs/sdk'
import { AreYouStillThere } from './AreYouStillThere'

export class ManualAreYouStillThere extends AreYouStillThere {
  private _timeout: ReturnType<typeof Registry.setTimeout> | null = null
  override _focus() {
    this._resetTimeout()
    this.patch({
      zIndex: 100,
    })
    this.Content.patch({
      signals: {
        stay: '_stay',
        exit: '_exit',
      },
    })
  }
  override _unfocus() {
    this._clearTimeout()
    this.patch({
      zIndex: 0,
    })
    this.Content.patch({
      signals: {},
    })
  }

  private _resolver: ((value: boolean | PromiseLike<boolean>) => void) | null =
    null

  check(): Promise<boolean> {
    Router.focusWidget('ManualAreYouStillThere')
    return new Promise(resolve => {
      this._resolver = resolve
    })
  }

  resolve(resolution: boolean) {
    Router.focusPage()
    if (this._resolver) {
      this._resolver(resolution)
    }
  }

  _stay() {
    this.resolve(true)
  }
  _exit() {
    this.resolve(false)
  }
  override _captureKey() {
    this._resetTimeout()
    return false
  }
  private _resetTimeout() {
    this._clearTimeout()
    const timeoutDuration = Settings.get(
      'app',
      'ARE_YOU_STILL_THERE_TIMEOUT',
      20000
    )
    this._timeout = Registry.setTimeout(
      this._exitApp.bind(this),
      timeoutDuration
    )
  }

  private _clearTimeout() {
    if (this._timeout !== null) {
      Registry.clearTimeout(this._timeout)
      this._timeout = null
    }
  }
  private _exitApp() {
    this.fireAncestors('$exitApp', AdeCloseReason.DONE, true)
  }

  // These needs to be here just to override thes state changes.
  override _active() {
    return null
  }
  static override _states() {
    return []
  }
}

import {
  ContentItem,
  ContentSelectedDirectionalSignalMap,
  Coords,
  FocusableMixin,
} from '@adiffengine/engine-types'
import { Colors, Img, Lightning, Settings } from '@lightningjs/sdk'
import { Debugger, passSignal } from '../../lib'
import { getStoredTheme } from '../../themes'
import { HoverZone } from '../helpers/HoverZone'
import { AdvancedGridCardSetup } from './AdvancedGridTypes'
import { getCoordinateDimensions } from '../../lib/lightning-tools'

export interface AdvancedWideCardTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Inner: {
    Shadow: object
    PosterFallback: {
      PosterGradient: object
    }
    Poster: object
    Focus: object
    Vignette: object
    InnerContent: {
      Label: {
        Title: object
        Detail: object
      }
    }
  }
  Hover: {
    HoverZone: typeof HoverZone
  }
  focusable: boolean | null | undefined
  showDetails: boolean
  content: ContentItem | null
}

// const debug = new Debugger('AdvancedWideCard')

const debug = new Debugger('AdvancedWideCard')
export interface AdvancedWideCardTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: FocusableMixin<ContentSelectedDirectionalSignalMap>
}

export class AdvancedWideCard
  extends Lightning.Component<
    AdvancedWideCardTemplateSpec,
    AdvancedWideCardTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<AdvancedWideCardTemplateSpec>
{
  private _contentItem: ContentItem | null = null
  private _focusAnimation: Lightning.types.Animation | undefined = undefined

  Inner = this.getByRef('Inner')!
  Shadow = this.Inner.getByRef('Shadow')!
  InnerContent = this.Inner.getByRef('InnerContent')!
  Vignette = this.Inner.getByRef('Vignette')!
  Label = this.InnerContent.getByRef('Label')!
  Title = this.Label.getByRef('Title')!
  Detail = this.Label.getByRef('Detail')!
  Poster = this.Inner.getByRef('Poster')!
  PosterFallback = this.Inner.getByRef('PosterFallback')!
  Hover = this.getByRef('Hover')

  static ratio = 9 / 16
  static cardSetup: AdvancedGridCardSetup = {
    spacing: 20,
    cardsPerRow: 5,
  }
  static spacing = 20
  static width = 318
  static height = (318 - this.spacing) * (9 / 16)
  static cardOffset: Partial<Coords> = {
    x: 16,
    y: 16,
  }

  static getDimensionsForWidth(
    w: number,
    setup: Partial<AdvancedGridCardSetup> = {}
  ) {
    const {
      cardsPerRow = this.cardSetup.cardsPerRow,
      spacing = this.cardSetup.spacing,
    } = setup
    const width = w / cardsPerRow
    const height = (width - spacing) * this.ratio
    return {
      height,
      width,
      spacing,
    }
  }

  static override _template(): Lightning.Component.Template<AdvancedWideCardTemplateSpec> {
    const theme = getStoredTheme()
    const {
      components: { VideoCardConfig },
    } = theme
    const radiusShader = {
      type: Lightning.shaders.RoundedRectangle,
      radius: VideoCardConfig.radius,
    }

    return {
      // zIndex: 1000,

      Inner: {
        w: (w: number) => w - this.spacing,
        h: (h: number) => h,
        Shadow: {
          alpha: 0,
          mountX: 0.5,
          mountY: 0.5,
          x: (w: number) => w / 2,
          y: (h: number) => h / 2,
          w: (w: number) => w + 24,
          h: (h: number) => h + 24,
          color: Colors('shadow').alpha(0.6).get(),
          rect: true,
          shader: { type: Lightning.shaders.FadeOut, fade: 24 },
        },
        Focus: {
          alpha: 0,
          mountX: 0.5,
          mountY: 0.5,
          x: (w: number) => w / 2,
          y: (h: number) => h / 2,
          w: (w: number) => w + 8,
          h: (h: number) => h + 8,
          rect: true,
          color: Colors(theme.palette.highlights[500]).get(),
          shader: radiusShader,
        },

        PosterFallback: {
          x: 0,
          y: 0,
          w: (w: number) => w,
          h: (h: number) => h,
          rect: true,
          visible: true,
          colorTop: Colors(theme.palette.darks[500]).get(),
          colorBottom: Colors(theme.palette.darks[800]).get(),
          shader: radiusShader,
        },
        Poster: {
          x: 0,
          y: 0,
          w: (w: number) => w,
          h: (h: number) => h,
          rect: true,
          rtt: true,
          alpha: 0.0001,
          shader: radiusShader,
        },
        Vignette: {
          x: 0,
          y: 0,
          w: (w: number) => w,
          h: (h: number) => h,
          alpha: 0.8,
          colorBottom: Colors(VideoCardConfig.vignetteColor).get(),
          colorTop: Colors(VideoCardConfig.vignetteColor).alpha(0.01).get(),
          rect: true,
          rtt: true,
          shader: radiusShader,
        },
        InnerContent: {
          x: 12,
          y: 0,
          w: (w: number) => w - 24,
          h: (h: number) => h - 24,
          clipping: true,
          rect: true,
          rtt: true,
          color: 0x00000000,
          Label: {
            x: 0,
            w: (w: number) => w,
            h: (h: number) => h,
            flex: {
              direction: 'column',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            },
            Title: {
              text: {
                advancedRenderer: true,
                text: '',
                fontFace: 'Regular',
                fontSize: 22,
                lineHeight: 24,
                textAlign: 'right',
                wordWrap: true,
                maxLines: 2,
              },
            },
            Detail: {
              flexItem: {
                marginTop: 4,
              },
              text: {
                advancedRenderer: true,
                text: '',
                fontFace: 'Text',
                fontSize: 18,
                lineHeight: 20,
                textAlign: 'right',
                wordWrap: false,
                maxLines: 1,
              },
            },
          },
        },
      },
      Hover: {
        HoverZone: {
          type: HoverZone,
          x: 0,
          y: 0,
          signals: {
            hovered: '_zoneHoverHandled',
            clicked: '_captureEnter',
          },
        },
      },
    }
  }
  private _pointerEnabled = false
  public focusable: boolean = true
  override _construct() {
    this._zoneHoverHandled = this._zoneHoverHandled.bind(this)
  }
  _zoneHoverHandled() {
    debug.info('Zone Hovered', this)
    if (this._pointerEnabled) {
      this.signal('hovered', this)
      this.fireAncestors('$hovered', this)
    }
  }

  private _showDetails = true

  set showDetails(show: boolean) {
    if (show !== this._showDetails) {
      this._showDetails = show
      this.Detail.patch({
        visible: show,
      })
    }
  }
  get showDetails() {
    return this._showDetails
  }
  private _spacing: number = AdvancedWideCard.cardSetup.spacing
  set spacing(spacing: number) {
    if (spacing !== this._spacing) {
      this._spacing = spacing
      this.Inner.patch({})
    }
  }
  get spacing() {
    return this._spacing
  }

  set content(v: ContentItem | null) {
    this._contentItem = v
    this.renderItems()
  }
  get content() {
    return this._contentItem
  }
  renderItems() {
    const config = this.fireAncestors('$getCardConfig', 'wide')
    if (config) {
      this.patch(config)
    }
    if (this.content && this.enabled) {
      const theme = this.fireAncestors('$theme')
      const {
        components: { VideoCardConfig },
      } = theme
      // const innerWidth = this.w - this.spacing
      // const innerHeight = this.w * AdvancedWideCard.ratio
      const innerHeight = this.h
      const innerWidth = this.w - this.spacing
      // const innerHeight = innerWidth * AdvancedWideCard.ratio
      const innerUpdate = {
        w: innerWidth,
        h: innerHeight,
      }

      this.patch({
        // w: this.w,
        // h: innerHeight,
        Hover: {
          w: innerWidth,
          h: innerHeight,
          HoverZone: {
            w: innerWidth,
            h: innerHeight,
          },
        },
        Inner: {
          ...innerUpdate,
          Shadow: {
            x: innerUpdate.w / 2,
            y: innerUpdate.h / 2,
            w: innerUpdate.w + 24,
            h: innerUpdate.h + 24,
          },
          PosterFallback: {
            PosterGradient: innerUpdate,
          },
          Poster: innerUpdate,
          Focus: {
            x: innerUpdate.w / 2,
            y: innerUpdate.h / 2,
            w: innerUpdate.w + 8,
            h: innerUpdate.h + 8,
          },
          Vignette: innerUpdate,
          InnerContent: {
            x: 12,
            w: innerUpdate.w - 24,
            h: innerUpdate.h - 12,
            Label: {
              w: innerUpdate.w - 24,
              h: innerUpdate.h - 12,
              y: 24,
              Title: {
                text: {
                  wordWrap: true,
                  wordWrapWidth: innerWidth - 24,
                  text: this.content?.title ?? '',
                },
              },
              Detail: {
                alpha: 0.01,
                text: {
                  text: this.content?.details ?? '',
                  wordWrapWidth: innerWidth - 24,
                },
              },
            },
          },
        },
      })
      const radiusShader = {
        type: Lightning.shaders.RoundedRectangle,
        radius: VideoCardConfig.radius,
      }

      this.Label.patch({
        Title: {
          text: {
            text: this.content.title,
          },
        },
        Detail: {
          text: {
            text: this.content.details ?? '',
          },
        },
      })
      let imageUrl = this.content.images.wide?.getForWidth(innerWidth)
      if (!imageUrl) imageUrl = this.content.images.box?.getForWidth(318)
      const fallbackImage = () => {
        this.Poster.patch({
          visible: false,
        })
        this.PosterFallback.patch({
          visible: true,
        })
      }

      if (imageUrl != null) {
        this.Poster.patch({
          shader: radiusShader,
          texture: Img(imageUrl).cover(innerWidth, innerHeight),
        })
      } else {
        fallbackImage()
      }
      if (this.Poster) {
        this.Poster.on('txLoaded', () => {
          this.Poster.setSmooth('alpha', 1)
        })
        this.Poster.on('txError', fallbackImage)
      }
    }
  }

  override _active() {
    this._pointerEnabled = Settings.get('app', 'enablePointer', false)
    this.renderItems()
  }
  override _inactive() {
    this._pointerEnabled = false
  }
  override _handleUp() {
    return passSignal(this.signal('up', getCoordinateDimensions(this)), false)
  }
  override _handleDown() {
    return passSignal(this.signal('down', getCoordinateDimensions(this)), false)
  }
  override _handleLeft() {
    return passSignal(this.signal('left', getCoordinateDimensions(this)), false)
  }
  override _handleRight() {
    return passSignal(
      this.signal('right', getCoordinateDimensions(this)),
      false
    )
  }
  get focusAnimation(): Lightning.types.Animation {
    if (!this._focusAnimation) {
      this._focusAnimation = this.animation({
        duration: 0.2,
        delay: 0.05,
        actions: [
          { t: 'Inner.Shadow', p: 'alpha', v: { 0: 0, 1: 1 } },
          { t: 'Inner.Focus', p: 'alpha', v: { 0: 0, 1: 1 } },
          {
            t: 'Inner.InnerContent.Label',
            p: 'y',
            v: { 0: 24, 1: 0 },
          },
          {
            t: 'Inner.InnerContent.Label.Detail',
            p: 'alpha',
            v: { 0: 0.01, 1: 1 },
          },
          { t: 'Inner', p: 'scale', v: { 0: 1, 1: 1.05 } },
        ],
      })
    }
    return this._focusAnimation
  }
  override _focus() {
    if (this.content) {
      this.fireAncestors('$currentFocusContent', this.content)
      this.focusAnimation.start()
    }
  }
  override _unfocus() {
    this.focusAnimation.stop()
    this.patch({
      zIndex: 1,
    })
  }
  override _captureEnter() {
    if (this.content) {
      this.fireAncestors('$contentSelected', this.content)
      this.signal('contentSelected', this.content)
    }
  }
}

import {
  ActionConfig,
  Coordinated,
  DirectionalSignalMap,
  SimplePalette,
} from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'

import defer from 'lodash-es/defer'
import isFunction from 'lodash-es/isFunction'

import {
  Debugger,
  confirmCollisionsAreSet,
  getCoordinateDimensions,
  isGoodNumber,
  registerHoverable,
} from '../lib'
import { getStoredTheme } from '../themes'
import { AdeColorIcon } from './AdeColorIcon'
const debug = new Debugger('AdeButton')
export interface AdeButtonSignals extends DirectionalSignalMap {
  selected(): void
  hovered(): void
  unhovered(): void
}

export interface AdeButtonTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: AdeButtonSignals
}
// const debug = new Debugger('ADB')
export interface AdeButtonTemplateSpec
  extends Lightning.Component.TemplateSpecLoose {
  Shadow: object
  Background: object
  InnerButton: {
    TextContainer: {
      LeftIcon: typeof AdeColorIcon
      Text: object
    }
    Release: object
  }
  action?: ActionConfig
  buttonText?: string
  leftIcon?: string
}

export abstract class AdeButtonType<
  S extends Lightning.Component.TemplateSpecLoose = Lightning.Component.TemplateSpecLoose,
  T extends AdeButtonTypeConfig = AdeButtonTypeConfig,
> extends Lightning.Component<S, T> {
  public abstract action?: ActionConfig
  public abstract buttonText?: string
}
export class AdeButton
  extends AdeButtonType<AdeButtonTemplateSpec, AdeButtonTypeConfig>
  implements
    Coordinated,
    Lightning.Component.ImplementTemplateSpec<AdeButtonTemplateSpec>
{
  private _focusAnimation: Lightning.types.Animation | undefined | null =
    undefined
  private _pressAnimation: Lightning.types.Animation | undefined | null =
    undefined
  public override action?: ActionConfig
  private _buttonText = ''

  Background = this.getByRef('Background')!
  TextContainer = this.getByRef('InnerButton')!.getByRef('TextContainer')!
  LeftIcon = this.TextContainer.getByRef('LeftIcon')!
  Text = this.TextContainer.getByRef('Text')!
  static override _template(): Lightning.Component.Template<AdeButtonTemplateSpec> {
    const theme = getStoredTheme()
    return {
      collision: true,
      w: 200,
      h: 60,
      Shadow: {
        alpha: 1,
        mountX: 0.5,
        mountY: 0.5,
        x: (w: number) => w / 2,
        y: (h: number) => h / 2,
        w: (w: number) => w + 16,
        h: (h: number) => h + 16,
        color: Colors('black').alpha(0.4).get(),
        rect: true,
        shader: { type: Lightning.shaders.FadeOut, fade: 32 },
      },
      Background: {
        w: (w: number) => w,
        h: (h: number) => h,
        x: 0,
        y: 0,
        rect: true,
        rtt: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: theme.components.ButtonConfig.radius,
        },
        color: Colors('buttonBackground').get(),
      },
      InnerButton: {
        scale: 1 / 1.075,
        w: (w: number) => w,
        h: (h: number) => h,
        x: 0,
        y: 0,
        rtt: true,
        TextContainer: {
          w: (w: number) => w - 8,
          h: (h: number) => h - 4,
          x: 4,
          y: 6,
          flex: {
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          },
          LeftIcon: {
            h: 30,
            w: 30,
            color: Colors('buttonText').get(),
            type: AdeColorIcon,
            focusColor: Colors('buttonHighlightText').get(),
            visible: false,
            flexItem: {
              alignSelf: 'flex-start',
              marginTop: 11,
              marginLeft: 12,
            },
          },
          Text: {
            color: Colors('buttonText').get(),
            text: {
              text: '',
              fontFace: 'Regular',
              textAlign: 'center',
              fontSize: 24,
              lineHeight: 30,
            },
          },
        },
      },
    }
  }


  _handleClick() {
    debug.info('[Hoverable] Button Click', this.action)
    if (this.action) {
      this.fireAncestors(this.action.action, this.action.payload)
    } else {
      this.signal('selected')
    }
  }
  override _handleHover(target: any) {
    debug.info('[Hoverable] Button Hover %s', this.buttonText, this, target)
    this.signal('hovered')
    this.fireAncestors('$hovered', this)
  }

  set buttonText(text: string) {
    if (text.toUpperCase() !== this._buttonText) {
      this.tag('InnerButton.TextContainer.Text')?.patch({
        text: text.toUpperCase(),
      })
      this.checkWidth()
    }
    this._buttonText = text.toUpperCase()
  }

  get buttonText() {
    return this._buttonText
  }

  checkWidth() {
    if (this.active) {
      const width = this.tag('InnerButton.TextContainer.Text')!.finalW
      if (width > this.w) {
        this.patch({
          w: width + 8,
          InnerButton: {
            TextContainer: {
              w: width,
            },
          },
        })
        this.tag('InnerButton.TextContainer.Text')?.patch({
          text: this.buttonText.toUpperCase(),
        })

        if (isFunction((this as any).collectionWrapper?.reposition)) {
          ;(this as any).collectionWrapper.reposition()
        }
      }
    }
  }

  override _active() {

    defer(this.checkWidth.bind(this))
    this.patch({ collision: true })
    confirmCollisionsAreSet(this)
  }
  override _inactive() {

    this.patch({ collision: false })
  }

  override _construct() {
    this.palettable = this.palettable.bind(this)
    this.stage.application.on('palette', this.palettable)
  }

  palettable(simplePalette: SimplePalette | null) {
    if (simplePalette) {
      this._focusAnimation = null
      this._pressAnimation = null
      this.Background.patch({
        smooth: {
          color: [Colors(simplePalette.background).get(), { duration: 0.2 }],
        },
      })
      this.Text.patch({
        smooth: {
          color: [Colors(simplePalette.foreground).get(), { duration: 0.2 }],
        },
      })
      this.LeftIcon.patch({
        color: Colors(simplePalette.foreground).get(),
      })
      this._pressAnimation = this.animation({
        duration: 0.2,
        actions: [
          { t: 'Shadow', p: 'scale', v: { 0: 1.075, 1: 0.925 } },
          { t: 'Background', p: 'scale', v: { 0: 1.075, 1: 0.925 } },
          {
            t: 'Background',
            p: 'color',
            v: {
              0: Colors(simplePalette.background).get(),
              1: Colors(simplePalette.focus).get(),
            },
          },
        ],
      })
    } else {
      this.Background.patch({
        smooth: {
          color: [Colors('buttonBackground').get(), { duration: 0.2 }],
        },
      })
      this.Text.patch({
        smooth: {
          color: [Colors('buttonText').get(), { duration: 0.2 }],
        },
      })
      this.LeftIcon.patch({
        color: Colors('buttonText').get(),
      })
    }
  }

  override _init() {
    registerHoverable('AdeButton', this)

    const h = isGoodNumber(this.h, true) ? this.h : 60
    const fontSize = Math.max(h * 0.3, 22)
    this.patch({
      InnerButton: {
        TextContainer: {
          Text: {
            rtt: true,
            text: {
              fontSize,
              text: this.buttonText.toUpperCase(),
            },
          },
        },
      },
    })
  }

  get pressAnimation() {
    if (!this._pressAnimation) {
      const { palette } = this.fireAncestors('$theme')
      this._pressAnimation = this.animation({
        duration: 0.2,
        actions: [
          { t: 'Shadow', p: 'scale', v: { 0: 1.075, 1: 0.925 } },
          { t: 'Background', p: 'scale', v: { 0: 1.075, 1: 0.925 } },
          {
            t: 'Background',
            p: 'color',
            v: {
              0: Colors(palette.highlights[500]).get(),
              1: Colors(palette.highlights[700]).get(),
            },
          },
        ],
      })
    }
    return this._pressAnimation
  }
  get focusAnimation() {
    if (!this._focusAnimation) {
      this._focusAnimation = this.animation({
        duration: 0.2,
        actions: [
          { t: 'Shadow', p: 'scale', v: { 0: 1, 1: 1.075 } },
          { t: 'Background', p: 'scale', v: { 0: 1, 1: 1.075 } },
          {
            t: 'Background',
            p: 'color',
            v: {
              0: Colors('buttonBackground').get(),
              1: Colors('primaryHighlight').get(),
            },
          },
          {
            t: 'InnerButton.TextContainer.Text',
            p: 'color',
            v: {
              0: Colors('buttonText').get(),
              1: Colors('buttonHighlightText').get(),
            },
          },
          {
            t: 'InnerButton.TextContainer.Text',
            p: 'scale',
            v: {
              0: 1 / 1.075,
              1: 1,
            },
          },
          { t: 'Shadow', p: 'scale', v: { 0: 1, 1: 1.075 } },
        ],
      })
    }
    return this._focusAnimation
  }

  override _focus() {
    this.fireAncestors('$currentFocus', getCoordinateDimensions(this))
    this.focusAnimation.start()
    this.LeftIcon.start()
  }
  override _unfocus() {
    this.fireAncestors('$currentFocus', null)
    this.focusAnimation.stop()
    this.LeftIcon.stop()
  }
  override _captureEnter() {
    this.pressAnimation.start()
    if (this.action) {
      const { action, payload } = this.action
      defer(() => {
        this.fireAncestors(action, payload)
      })
      return false
    } else {
      defer(() => {
        return this.signal('selected')
      })
      return true
    }
  }

  override _captureEnterRelease(): boolean | void {
    this.pressAnimation.stop()
  }
  _signalDirection(dir: 'up' | 'down' | 'right' | 'left') {
    const coords = getCoordinateDimensions(this)
    return this.signal(dir, coords)
  }
  override _handleEnter() {
    return this.signal('selected')
  }
  override _handleRight() {
    this._signalDirection('right')
    return false
  }
  override _handleLeft() {
    this._signalDirection('left')
    return false
  }
  override _handleUp() {
    this._signalDirection('up')
    return false
  }
  override _handleDown() {
    this._signalDirection('down')
    return false
  }

  private _leftIcon: string | null = null

  set leftIcon(x: string | null) {
    this._leftIcon = x
    this.tag('InnerButton.TextContainer')?.patch({
      flex: {
        justifyContent: 'flex-start',
      },
      LeftIcon: {
        icon: {
          icon: x === null ? undefined : x,
        },
        visible: true,
        flexItem: { marginRight: 12 },
      },
    })
  }
  get leftIcon() {
    return this._leftIcon
  }

  static width = 200
  static height = 60
}

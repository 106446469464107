import { CoordinateDimensions } from '@adiffengine/engine-types'
import { Colors, Lightning, Settings } from '@lightningjs/sdk'
import equal from 'fast-deep-equal/es6'
import {
  Debugger,
  getClosestIndexByY,
  getCoordinateDimensions,
  isGoodNumber,
  registerHoverable,
} from '../lib'
import { isHideElement, isShowElement } from '../lib/types'
import { getStoredTheme } from '../themes'
import { MainMenuButton } from './MainMenuButton'
import { MainMenuLogo } from './MainMenuLogo'
import { AdvancedGridMainMenuScrollButtons } from './AdvancedGrid/lib/AdavancedGridMainMenuButtons'
// button-manifest: tv,home,film,search,settings
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const debug = new Debugger('MainMenu')
export const MainMenuButtons: Record<string, MainMenuButtonItem> = {
  home: { icon: 'home', label: 'Home', path: 'home' },
  tv: { icon: 'tv', label: 'Television', path: 'tv' },
  movie: { icon: 'film', label: 'Movies', path: 'movie' },
  search: { icon: 'search', label: 'Search', path: 'search' },
  settings: { icon: 'settings', label: 'Settings', path: 'settings' },
}
export const MenuButtons = Object.values(MainMenuButtons)

export interface MainMenuButtonItem {
  icon: string
  label: string
  path: string
}
export interface MainMenuTemplateSpec
  extends Lightning.Component.TemplateSpecLoose {
  Menu: object
  Logo: typeof MainMenuLogo
  Buttons: typeof MainMenuButtonContainer
  ScrollWrapper: {
    Scroller: typeof AdvancedGridMainMenuScrollButtons
  }
  menuButtons: MainMenuButtonItem[]
  open(immediate?: boolean): void
  close(immediate?: boolean): void
  needsScroll: boolean
}
export interface MainMenuSignalMap {
  right(coords?: CoordinateDimensions | null): void
  left(coords?: CoordinateDimensions | null): void
  open(open: boolean): void
  animatingOpen(duration: number): void
  animatingClosed(duration: number): void
}

const MAIN_MENU_TRANSITION_DURATION = 0.2

export interface MainMenuTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: MainMenuSignalMap
}
export class MainMenu
  extends Lightning.Component<MainMenuTemplateSpec, MainMenuTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<MainMenuTemplateSpec>
{
  public initialState: 'Open' | 'Closed' = 'Closed'
  static width = 280
  static widthClosed = 96
  private _menuButtons: MainMenuButtonItem[] = MenuButtons

  set menuButtons(x: MainMenuButtonItem[]) {
    this.Buttons.patch({
      menuButtons: x,
    })
  }

  get menuButtons() {
    return this._menuButtons
  }

  Buttons = this.tag('Buttons')!
  Logo = this.getByRef('MainMenuLogo')!
  ScrollWrapper = this.getByRef('ScrollWrapper')!
  Scroller = this.ScrollWrapper.getByRef('Scroller')!

  static override _template(): Lightning.Component.Template<MainMenuTemplateSpec> {
    const theme = getStoredTheme()
    const {
      components: { MainMenuConfig },
    } = theme

    return {
      w: MainMenu.widthClosed,
      h: 1080 - 160,
      clipping: true,
      rect: true,
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: theme.components.MainMenuConfig.radius,
      },
      color: Colors(MainMenuConfig.backgroundColor).get(),
      MainMenuLogo: {
        x: 0,
        y: 64,
        type: MainMenuLogo,
      },
      Buttons: {
        type: MainMenuButtonContainer,
        y: 100,
        h: (x: number) => x - 200,
        w: MainMenu.width - 72,
        menuButtons: MenuButtons,
      },
      ScrollWrapper: {
        y: 1080 - 240,
        x: 0,
        w: MainMenu.widthClosed,
        mountY: 1,
        flex: {
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        },
        h: AdvancedGridMainMenuScrollButtons.height,
        Scroller: {
          type: AdvancedGridMainMenuScrollButtons,
        },
      },
    }
  }

  private _needsScroll: boolean = true
  public get needsScroll(): boolean {
    return this._needsScroll
  }
  public set needsScroll(value: boolean) {
    const pointerEnabled = Settings.get('app', 'enablePointer', false)
    value = pointerEnabled && value
    if (this._needsScroll !== value) {
      this._needsScroll = value
      this.Scroller.patch({
        visible: value,
      })
      this.Buttons.patch({
        pinTop: value,
      })
    }
  }
  override _init() {
    registerHoverable('MainMenu', this)
  }
  private _currentIndex = 0
  private _hovered = false
  set hovered(hovered: boolean) {
    if (hovered === this._hovered) return
    this._hovered = hovered
    if (hovered) {
      this.stage.application.emit(
        'hovered',
        'MainMenu',
        getCoordinateDimensions(this)
      )
    }
  }

  get hovered() {
    return this._hovered
  }
  private _scrollerEnabled = true
  public get scrollerEnabled() {
    return this._scrollerEnabled
  }
  public set scrollerEnabled(value) {
    if (value !== this._scrollerEnabled) {
      this._scrollerEnabled = value
      this.Scroller.patch({ visible: value })
    }
  }

  override _handleHover(target: Lightning.Element) {
    debug.info('Hovered on Main Menu, Got target', target.ref, this.Buttons)
    this.hovered = true
  }
  _handleUnhover() {
    debug.info('Off Main Menu')
    this.hovered = false
  }

  override _handleRight() {
    const current = this.Buttons.childList.getAt(this._currentIndex)
    const coords = current ? getCoordinateDimensions(current) : null
    return this.signal('right', coords)
  }

  override get id() {
    return 'MainMenu'
  }

  override _handleLeft() {
    const current = this.Buttons.childList.getAt(this._currentIndex)
    const coords = current ? getCoordinateDimensions(current) : null
    return this.signal('left', coords)
  }

  override _getFocused() {
    return this.Buttons
  }

  setClosestByY(coords?: CoordinateDimensions | null) {
    this.Buttons.setClosestByY(coords)
  }

  private _enterAnimation: Lightning.types.Animation | undefined = undefined

  get openAnimation() {
    const duration = MAIN_MENU_TRANSITION_DURATION
    if (!this._enterAnimation) {
      this._enterAnimation = this.animation({
        duration,
        actions: [
          {
            p: 'w',
            v: { 0: MainMenu.widthClosed, 1: MainMenu.width },
          },
          {
            t: 'Buttons',
            p: 'x',
            v: { 0: 24, 1: 36 },
          },
        ],
      })
    }
    this._enterAnimation.on('start', () => {
      this.Logo.patch({ expanded: true })
      this.signal('animatingOpen', duration)
    })
    this._enterAnimation.on('stop', () => {
      this.Logo.patch({ expanded: false })
      this.signal('animatingClosed', duration)
    })
    return this._enterAnimation
  }

  private _open = false
  set open(open: boolean) {
    if (open !== this._open) {
      this._open = open
      this.signal('open', open)
      this.stage.application.emit(
        'menuOpen',
        open,
        MAIN_MENU_TRANSITION_DURATION
      )
    }
  }

  override _focus() {
    this.open = true
    this.openAnimation.start()
    this.Buttons?.children.forEach(c => {
      if (isShowElement(c)) c.show()
    })
  }

  override _unfocus() {
    this.open = false
    this.openAnimation.stop()
    this.Buttons?.children.forEach(c => {
      if (isHideElement(c)) c.hide()
    })
  }
}

export interface MainMenuButtonContainerTemplateSpec
  extends Lightning.Component.TemplateSpecLoose {
  menuButtons: MainMenuButtonItem[]
  pinTop: boolean
}
interface MainMenuButtonContainerType extends Lightning.Component.TypeConfig {
  SignalMapType: {
    hovered(idx: number, coords: CoordinateDimensions): void
  }
}
export class MainMenuButtonContainer
  extends Lightning.Component<
    MainMenuButtonContainerTemplateSpec,
    MainMenuButtonContainerType
  >
  implements
    Lightning.Component
      .ImplementTemplateSpec<MainMenuButtonContainerTemplateSpec>
{
  static override _template(): Lightning.Component.Template<MainMenuButtonContainerTemplateSpec> {
    return {
      x: 24,
      y: 0,
      w: MainMenu.width,
      h: 1080 - 160,
      color: 0x00000000,
      rect: true,
      flex: {
        direction: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
    }
  }

  private _pinTop = false
  public get pinTop() {
    return this._pinTop
  }
  public set pinTop(value) {
    if (this._pinTop !== value) {
      this._pinTop = value
      this.patch({
        flex: {
          alignItems: value ? 'flex-start' : 'center',
        },
      })
    }
  }

  private _currentIndex = 0

  override _handleUp() {
    return this.setIndex(this._currentIndex - 1)
  }
  override _handleDown() {
    return this.setIndex(this._currentIndex + 1)
  }

  setIndex(index: number) {
    if (
      index >= 0 &&
      index < this.childList.length &&
      this.childList.getAt(index)
    ) {
      debug.info('Updating current focus to ', index)
      this._currentIndex = index
      if (this.hasFocus()) {
        debug.info('refocusing')
        this._refocus()
      }
    }
  }

  _menuButtons: MainMenuButtonItem[] = []
  set menuButtons(buttons: MainMenuButtonItem[]) {
    if (!equal(this._menuButtons, buttons)) {
      this._menuButtons = buttons
      this.childList.clear()
      buttons.forEach((config, idx) => {
        this.childList.a(
          this.stage.c({
            ...config,
            type: MainMenuButton,
            w: MainMenu.width - 72,
            flexItem: {
              marginTop: 24,
              marginBottom: 24,
            },
            zIndex: 1,
            signals: {
              hovered: () => {
                this.setIndex(idx)
              },
            },
            collision: true,
          })
        )
      })
    }
  }

  override _getFocused() {
    debug.info('Getting focus', this.childList.getAt(this._currentIndex))
    const current = this.childList.getAt(this._currentIndex)
    return (current as Lightning.Component) ?? null
  }

  get menuButtons() {
    return this._menuButtons
  }
  setClosestByY(coords?: CoordinateDimensions | null) {
    if (coords && !this.hasFocus()) {
      const index = getClosestIndexByY(coords, this.children, true)
      if (isGoodNumber(index, true)) {
        if (!this.hasFocus()) {
          this._currentIndex = index
        }
      } else {
        this._currentIndex = 0
      }
    }
  }
}

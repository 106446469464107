import { Img, Lightning } from '@lightningjs/sdk'
import { Debugger } from '../lib'

export interface MainMenuLogoTemplateSpec
  extends Lightning.Component.TemplateSpec {
  expanded: boolean
  LogoWrapper: {
    Logo: object
  }
  IconWrapper: {
    Icon: object
  }
}
const debug = new Debugger('MainMenuLogo')

export class MainMenuLogo
  extends Lightning.Component<MainMenuLogoTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<MainMenuLogoTemplateSpec>
{
  IconWrapper = this.getByRef('IconWrapper')!
  Icon = this.IconWrapper.getByRef('Icon')!
  LogoWrapper = this.getByRef('LogoWrapper')!
  Logo = this.LogoWrapper.getByRef('Logo')!

  static override _template(): Lightning.Component.Template<MainMenuLogoTemplateSpec> {
    return {
      h: 40,
      w: 280,
      LogoWrapper: {
        x: 0,
        y: 0,
        h: 40,
        w: 280,
        flex: {
          alignItems: 'center',
          justifyContent: 'center',
        },
        Logo: {
          h: 40,
          w: 232,
          rtt: true,
        },
      },
      IconWrapper: {
        x: 0,
        y: 0,
        h: 40,
        w: 96,
        flex: {
          alignItems: 'flex-end',
          justifyContent: 'center',
        },
        Icon: {
          h: 40,
          w: 40,
          rtt: true,
        },
      },
    }
  }

  private _expandAnimation: Lightning.types.Animation | undefined = undefined
  get expandedAnimation(): Lightning.types.Animation {
    const duration = 0.2
    if (!this._expandAnimation) {
      const theme = this.fireAncestors('$theme')
      const { logo } = theme.components.MainMenuConfig
      this._expandAnimation = this.animation({
        duration,
        actions: logo
          ? [
              {
                t: 'IconWrapper',
                p: 'alpha',
                v: { 0: 1, 1: 0.00001 },
              },
              {
                t: 'LogoWrapper',
                p: 'alpha',
                v: { 0: 0.0001, 1: 1 },
              },
            ]
          : [
              {
                t: 'IconWrapper',
                p: 'w',
                v: { 0: 96, 1: 280 },
              },
              {
                t: 'IconWrapper.Icon',
                p: 'h',
                v: { 0: 40, 1: 60 },
              },
              {
                t: 'IconWrapper.Icon',
                p: 'w',
                v: { 0: 40, 1: 60 },
              },
            ],
      })
    }
    return this._expandAnimation
  }
  private _expanded = false
  set expanded(expanded: boolean) {
    if (this._expanded !== expanded) {
      this._expanded = expanded
      if (expanded) this.expandedAnimation.start()
      else this.expandedAnimation.stop()
    }
  }
  get expanded() {
    return this._expanded
  }

  override _init() {
    const theme = this.fireAncestors('$theme')
    if (
      theme.components.MainMenuConfig.icon ||
      theme.components.MainMenuConfig.logo
    ) {
      const { icon, logo } = theme.components.MainMenuConfig
      if (icon) {
        this.Icon.patch({
          texture: Img(icon).cover(40, 40),
        })
        this.tag('IconWrapper')?.patch({
          alpha: this.expanded && logo ? 0.0001 : 0.8,
        })
      }
      if (logo) {
        this.Logo.patch({
          texture: Img(logo).cover(232, 40),
        })
        this.tag('LogoWrapper')?.patch({
          alpha: this.expanded ? 0.8 : 0.0001,
        })
      }
      this.patch({
        visible: true,
      })
    } else {
      this.patch({
        visible: false,
      })
    }
    debug.info('MainMenuLogo Init', this, theme.components.MainMenuConfig)
  }
}

import { Lifecycle } from '@firebolt-js/sdk'
import { ID, VideoEvent, VideoViewEvent } from './contentTypes'
export interface AdeDeviceInfo {
  distributor: string
  model: string
  make: string
  platform: string
  isMock?: boolean
}
export enum AdeCloseReason {
  REMOTE_BUTTON = 'remoteButton',
  USER_EXIT = 'userExit',
  DONE = 'done',
  ERROR = 'error',
  TIMED_OUT = 'timedOut',
}

export interface AdeLifecycleStateEvent {
  from: Lifecycle.Event
  to: Lifecycle.Event
}

export interface TrackingEvent {
  type: 'screen_view_event' | 'video_playback_event' | 'video_view_event'
}

export interface PageTrackingEvent extends TrackingEvent {
  type: 'screen_view_event'
  payload: {
    path: string
    content_id?: ID
    page_title?: string
    page_type?: string
    page_class?: string
  }
}

export interface SearchEvents {
  type: 'searching' | 'results'
}
export interface SearchingEvent extends SearchEvents {
  type: 'searching'
  payload: {
    term: string
  }
}

export function isSearchingEvent(e: SearchEvent): e is SearchingEvent {
  return e.type === 'searching'
}
export function isSearchedEvent(e: SearchEvent): e is SearchingEvent {
  return e.type === 'results'
}
export type SearchEvent = SearchingEvent | SearchedEvent
export interface SearchedEvent extends SearchEvents {
  type: 'results'
  payload: {
    result_count: string
  }
}
export interface VideoTrackingEvent extends TrackingEvent {
  type: 'video_playback_event'
  payload: VideoEvent
}
export interface VideoViewTrackingEvent extends TrackingEvent {
  type: 'video_view_event'
  payload: VideoViewEvent
}

export interface AdeLifecycleEvents {
  deviceInfo: AdeDeviceInfo
  warning: {
    message: string
    error?: Error
  }
  state: AdeLifecycleStateEvent
}

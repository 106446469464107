import { Colors, Img, Lightning, Registry } from '@lightningjs/sdk'
import { ContentItem } from '@adiffengine/engine-types'
import equal from 'fast-deep-equal/es6'
import { Debugger } from '../lib/debugger'
import { BackgroundPlane } from '../components/BackgroundPlane'
import { cp } from '../lib/lightning-tools'

const debug = new Debugger('EndCard')

export interface EndCardTemplateSpec extends Lightning.Component.TemplateSpec {
  Content: {
    Background: typeof BackgroundPlane
    Details: {
      UpNextContent: {
        Header: object
        NextItem: {
          TextDetails: {
            Title: object
            Description: object
          }
          Image: object
        }
        UpNextButton: typeof UpNextButton
      }
      StillThereContent: {
        visible: false
        Title: object
        Text: object
        Buttons: {
          Yes: typeof UpNextButton
        }
      }
    }
  }
  imageType: keyof ContentItem['images']
  nextItem: ContentItem | null
}

const END_CARD_WIDTH = 1200
const END_CARD_HEIGHT = (END_CARD_WIDTH * 9) / 16
const END_CARD_PADDING = 80

export interface EndCardTypeConfig extends Lightning.Component.TypeConfig {}
export class EndCard
  extends Lightning.Component<EndCardTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<EndCardTemplateSpec>
{
  Content = this.getByRef('Content')!

  Details = this.Content.getByRef('Details')!
  UpNextContent = this.Details.getByRef('UpNextContent')!
  StillThereContent = this.Details.getByRef('StillThereContent')!
  Header = this.UpNextContent.getByRef('Header')!
  NextItem = this.UpNextContent.getByRef('NextItem')!
  Image = this.NextItem.getByRef('Image')!
  TextDetails = this.NextItem.getByRef('TextDetails')!
  Title = this.TextDetails.getByRef('Title')!
  Description = this.TextDetails.getByRef('Description')!

  static override _template(): Lightning.Component.Template<EndCardTemplateSpec> {
    return {
      alpha: 0.0001,
      x: 0,
      y: 0,
      h: 1080,
      w: 1920,
      Content: {
        x: (x: number) => (x - END_CARD_WIDTH) / 2,
        y: (x: number) => (x - END_CARD_HEIGHT) / 2,
        w: END_CARD_WIDTH,
        h: END_CARD_HEIGHT,
        Background: {
          x: 0,
          y: 0,
          w: cp,
          h: cp,
          type: BackgroundPlane,
        },

        Details: {
          x: END_CARD_PADDING,
          y: END_CARD_PADDING,
          w: (x: number) => x - END_CARD_PADDING * 2,
          h: (x: number) => x - END_CARD_PADDING * 2,
          UpNextContent: {
            Header: {
              text: {
                fontFace: 'Bold',
                text: 'Up Next',
                fontSize: 60,
              },
            },
            NextItem: {
              y: 120,
              w: cp,
              flex: {
                direction: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
              },
              Image: {
                w: 400,
                h: 225,
                flexItem: {
                  marginRight: 24,
                },
                rect: true,
                rtt: true,
                shader: {
                  type: Lightning.shaders.RoundedRectangle,
                  radius: 12,
                },
              },
              TextDetails: {
                w: END_CARD_WIDTH - 450,
                flex: {
                  direction: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                },
                Title: {
                  text: {
                    fontFace: 'Regular',
                    fontSize: 36,
                    wordWrap: true,
                    wordWrapWidth: END_CARD_WIDTH - 540,
                    maxLines: 3,
                  },
                },
                Description: {
                  text: {
                    fontFace: 'Text',
                    fontSize: 24,
                    wordWrap: true,
                    wordWrapWidth: END_CARD_WIDTH - 540,
                    maxLines: 3,
                    lineHeight: 36,
                  },
                },
              },
            },
            UpNextButton: {
              x: 0,
              y: 120 + 225 + 80,
              type: UpNextButton,
            },
          },
          StillThereContent: {
            flex: {
              direction: 'column',
            },
            Title: {
              color: Colors('text').get(),
              text: {
                fontFace: 'Bold',
                fontSize: 60,
                text: 'Are You Still Watching?',
              },
            },
            Text: {
              flexItem: {
                marginTop: 36,
              },
              text: {
                fontFace: 'Regular',
                fontSize: 36,
                wordWrap: true,
                wordWrapWidth: END_CARD_WIDTH - 120,
                maxLines: 3,
                text: `Just checking if you're still enjoying this content. Thanks!`,
              },
            },
            Buttons: {
              flexItem: {
                marginTop: 36,
              },
              flex: {
                direction: 'row',
              },
              Yes: {
                type: UpNextButton,
                w: 200,
                h: 80,
                buttonText: 'Still Here',
                duration: 20,
                signals: {
                  selected: '_stillHere',
                },
              },
            },
          },
        },
      },
    }
  }

  override _firstActive() {
    this._setState('StillWatchingState')
  }

  private _contentItem: ContentItem | null = null
  set nextItem(contentItem: ContentItem | null) {
    if (!equal(contentItem, this._contentItem)) {
      this._contentItem = contentItem
      if (this._contentItem) {
        this.TextDetails.patch({
          Title: {
            text: {
              text: this._contentItem.title,
            },
          },
          Description: {
            text: {
              text: this._contentItem.description,
            },
          },
        })
        let image = this._contentItem.images[this.imageType]
        if (!image) image = Object.values(this._contentItem.images)[0]
        let source: string | null = null
        if (image) {
          source = image.getForWidth(400)
          if (source) {
            this.Image.patch({
              visible: true,
              texture: Img(source).cover(400, 225),
            })
          }
        }
        if (source == null) {
          this.Image.patch({
            visible: false,
          })
        }
      }
    }
    this._contentItem = contentItem
  }

  get nextItem() {
    return this._contentItem
  }
  private _imageType: keyof ContentItem['images'] = 'box'
  public get imageType(): keyof ContentItem['images'] {
    return this._imageType
  }
  public set imageType(value: keyof ContentItem['images']) {
    this._imageType = value
  }
  private _showAnimation: Lightning.types.Animation | null = null
  get showAnimation(): Lightning.types.Animation {
    if (this._showAnimation) return this._showAnimation
    this._showAnimation = this.animation({
      duration: 0.3,
      actions: [{ p: 'alpha', v: { 0: 0.0001, 1: 1 } }],
    })
    return this._showAnimation
  }

  show(nextItem?: ContentItem | null) {
    if (nextItem) {
      this.nextItem = nextItem
      this.showAnimation.start()
      debug.info('Showed End Card')
    } else {
      console.warn('Did not send next item to End Card, not showing.')
      this.showAnimation.stop()
    }
  }
  hide() {
    this.showAnimation.stop()
  }
  _stillHere() {
    this._setState('UpNextState')
  }
  static override _states(): Lightning.Component.Constructor<
    Lightning.Component<
      EndCardTemplateSpec,
      Lightning.Component.TypeConfigLoose
    >
  >[] {
    return [
      class StillWatchingState extends this {
        override $enter() {
          this.Details.patch({
            UpNextContent: {
              visible: false,
            },
            StillThereContent: {
              visible: true,
            },
          })
        }
      },
      class UpNextState extends this {
        override $enter() {
          this.Details.patch({
            UpNextContent: {
              visible: true,
            },
            StillThereContent: {
              visible: false,
            },
          })
        }
      },
    ]
  }
}
export interface UpNextButtonTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: {
    PrimaryBackground: object
    BackgroundWrapper: {
      Background: object
    }
    TextWrapper: {
      Text: object
    }
  }
  buttonText: string
  duration: number
}
export interface UpNextButtonTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: {
    selected(): void
  }
}
class UpNextButton
  extends Lightning.Component<UpNextButtonTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<UpNextButtonTemplateSpec>
{
  Content = this.getByRef('Content')!
  BackgroundWrapper = this.Content.getByRef('BackgroundWrapper')!
  Background = this.BackgroundWrapper.getByRef('Background')!
  Text = this.Content.getByRef('TextWrapper')!.getByRef('Text')!
  static override _template(): Lightning.Component.Template<UpNextButtonTemplateSpec> {
    return {
      w: 200,
      h: 80,
      Content: {
        x: 0,
        y: 0,
        w: 200,
        h: 80,
        PrimaryBackground: {
          x: 0,
          y: 0,
          w: 200,
          h: 80,
          rtt: true,
          rect: true,
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: 24,
            fillColor: Colors('primaryHighlight').alpha(0.4).get(),
          },
        },
        BackgroundWrapper: {
          x: 0,
          y: 0,
          h: 80,
          w: 200,
          rtt: true,
          rect: true,
          clipping: true,
          color: 0x00000000,
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: 24,
          },
          Background: {
            x: -200,
            y: 0,
            rect: true,
            w: 200,
            h: cp,
            rtt: true,
            shader: {
              type: Lightning.shaders.RoundedRectangle,
              radius: 24,
              fillColor: Colors('primaryHighlight').get(),
            },
          },
        },
        TextWrapper: {
          h: 80,
          w: cp,
          x: 0,
          y: 0,
          flex: {
            alignItems: 'center',
            justifyContent: 'center',
          },
          Text: {
            x: 0,
            y: 0,
            color: Colors('white').get(),
            text: {
              text: '',
              fontFace: 'Regular',
              fontSize: 24,
              textAlign: 'center',
              verticalAlign: 'middle',
            },
          },
        },
      },
    }
  }
  private _buttonText = 'Play Now'
  public get buttonText() {
    return this._buttonText
  }
  public set buttonText(value) {
    if (value !== this._buttonText) {
      this._buttonText = value
      this.Text.patch({
        text: {
          text: value,
        },
      })
    }
  }
  _animationTimeout:
    | ReturnType<typeof Registry.setInterval>
    | null
    | undefined = null
  _tick() {
    const percent = this.countdownAnimation.p
    let text = 'Playing Now'
    if (percent < 1) {
      const duration = this.countdownAnimation.settings.duration
      const second = duration - Math.ceil(duration * percent)
      text = `Plays in ${second}s`
    } else {
      this._clearInterval()
    }
    this.Text.patch({
      text: { text },
    })
  }
  _clearInterval() {
    if (this._animationTimeout != null) {
      this._animationTimeout = Registry.clearInterval(this._animationTimeout)
      this._animationTimeout = null
    }
  }
  _startTickInterval() {
    this._clearInterval()
    this._animationTimeout = Registry.setInterval(this._tick.bind(this), 1000)
  }
  _animationStarted() {
    debug.info('Started', this.countdownAnimation)
    this._startTickInterval()
  }
  _animationStopped() {
    debug.info('Stopped')
    this._clearInterval()
  }

  public duration: number = 10
  private _countdownAnimation: Lightning.types.Animation | null = null
  override _active() {
    debug.info('Active!')
    if (!this.countdownAnimation.isPlaying()) {
      this.countdownAnimation.start()
    }
  }

  public get countdownAnimation(): Lightning.types.Animation {
    if (this._countdownAnimation === null) {
      this._countdownAnimation = this.animation({
        duration: this.duration,
        actions: [{ t: 'Content.Background', p: 'x', v: { 0: -200, 1: 0 } }],
      })

      this.countdownAnimation.on('start', this._animationStarted.bind(this))
      this.countdownAnimation.on('stop', this._animationStopped.bind(this))
    }
    return this._countdownAnimation
  }
}

import {
  NavigationPayload,
  Palette,
  PaletteHuePalettes,
  PaletteStringColors,
  ThemeConfig,
} from '@adiffengine/engine-types'

import { Lightning } from '@lightningjs/sdk'
import isFunction from 'lodash-es/isFunction'
import isString from 'lodash-es/isString'
import { merge as deepmerge } from 'ts-deepmerge'
import { PartialDeep, PartialDeepOptions } from 'type-fest/source/partial-deep'

export function isNavigationPayload(p: unknown): p is NavigationPayload {
  if (!p || isString(p)) return false
  return (
    isString((p as NavigationPayload).path) &&
    ['extra', 'trailer', 'episode'].includes((p as NavigationPayload).type)
  )
}

export function createPalette(
  hues: PaletteHuePalettes,
  colors: Partial<PaletteStringColors> = {}
): Palette {
  const { darks } = hues
  return {
    primaryBackground: darks[600],
    shadow: darks[500],
    errorText: '#e4420a',
    goodText: '#005500',
    background: darks[900],
    backgroundGradient: darks[700],
    text: darks[50],
    buttonBackground: darks[400],
    buttonText: darks[50],
    buttonBorder: darks[400],
    buttonHighlightBackground: darks[600],
    buttonHighlightText: darks[900],
    cardHighlightText: darks[900],
    buttonHighlightBorder: hues.highlights[500],
    primaryHighlight: hues.highlights[500],
    currentHighlight: hues.highlights[200],
    currentHighlightText: darks[50],
    ...colors,
    ...hues,
  }
}
interface ThemeConfigPartial extends Omit<ThemeConfig, 'components'> {
  components?: PartialDeep<ThemeConfig['components'], PartialDeepOptions>
}

export function createThemeConfig(config: ThemeConfigPartial) {
  const { components = {}, ...rest } = config
  const baseComponentConfig: ThemeConfig['components'] = {
    PlayControlConfig: {
      playBackground: config.palette['primaryBackground'],
      backgroundAlphaUnfocused: 0.2,
      backgroundAlphaFocused: 0.6,
      scale: 2,
    },
    MainMenuButtonConfig: {
      radius: 8,
      backgroundColor: config.palette['primaryBackground'],
    },
    ButtonConfig: {
      radius: 12,
    },
    MainMenuConfig: {
      radius: 12,
      backgroundColor: config.palette['primaryBackground'],
    },
    HeroWidgetItemConfig: {
      colorLeft: config.palette.darks[500],
      colorRight: config.palette.darks[800],
    },
    SimpleCardConfig: {
      backgroundColor: config.palette.buttonBackground,
      radius: 18,
      stroke: 4,
      shadowColor: config.palette.darks[800],
      largeRadius: 24,
      imageRadius: 12,
    },
    FullPageBackground: {
      backgroundColor: config.palette.background,
    },
    BoxCardConfig: {
      radius: 16,
    },
    VideoCardConfig: {
      radius: 16,
      vignetteColor: config.palette.darks[900],
    },
  }

  const finalComponents = deepmerge(
    baseComponentConfig,
    components
  ) as ThemeConfig['components']
  const finalConfig: ThemeConfig = { components: finalComponents, ...rest }
  return finalConfig
}

export interface ThemeLibrary {
  blue: ThemeConfig
  base: ThemeConfig
  excitement: ThemeConfig
}
export type ThemeName = keyof ThemeLibrary
export interface HideElement extends Lightning.Element {
  hide(): void
}
export interface ShowElement extends Lightning.Element {
  show(): void
}
export type ShowHideElement = HideElement & ShowElement

export function isHideElement(x: Lightning.Element): x is HideElement {
  return x && isFunction((x as HideElement).hide)
}
export function isShowElement(x: Lightning.Element): x is ShowElement {
  return x && isFunction((x as ShowElement).show)
}
export function isShowHideElement(x: Lightning.Element): x is ShowHideElement {
  return x && isHideElement(x) && isShowElement(x)
}

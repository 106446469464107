import { ContentItem, ContentSeason } from '@adiffengine/engine-types'
import { Lightning, Settings } from '@lightningjs/sdk'
import slugify from 'slugify'
import {
  AdeBoxArtButton,
  AdvancedBoxCard,
  AdvancedGrid,
  AdvancedGridCardTypes,
  AdvancedGridList,
} from '../components'
import { DimensionItem } from '../components/AdeCardRow'
import { DetailsHero } from '../components/AdeDetailsHero'
import { AdvancedGridScrollBar } from '../components/AdvancedGrid/lib/AdvancedGridScrollBar'
import {
  Debugger,
  backHandler,
  defaultBackHandler,
  getCoordinateDimensions,
  isGoodArray,
} from '../lib'
const debug = new Debugger('MovieDetails')

export interface MovieDetailsPageTypeConfig
  extends Lightning.Component.TypeConfig {
  IsPage: true
}
type NavigationPath = keyof ContentItem['paths']
const navPaths: NavigationPath[] = ['details', 'player']
export interface MovieDetailsPageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  PageList: typeof AdvancedGrid
  ScrollBar: typeof AdvancedGridScrollBar
  hasWatchlist: boolean
  navigationPath: keyof ContentItem['paths']
  cardType: AdvancedGridCardTypes
}

export class MovieDetailsPage
  extends Lightning.Component<
    MovieDetailsPageTemplateSpec,
    MovieDetailsPageTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<MovieDetailsPageTemplateSpec>
{
  private _content: ContentItem | null | undefined
  private _cardType: AdvancedGridCardTypes = AdvancedBoxCard
  set cardType(cardType: AdvancedGridCardTypes) {
    if (cardType !== this._cardType) {
      this._cardType = cardType
      this.setupList()
    }
  }
  get cardType() {
    return this._cardType
  }
  PageList = this.getByRef('PageList')!
  ScrollBar = this.getByRef('ScrollBar')!

  override get id() {
    return 'MovieDetals'
  }

  static override _template(): Lightning.Component.Template<MovieDetailsPageTemplateSpec> {
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      PageList: {
        x: 80,
        y: 0,
        w: 1920 - 160,
        h: 1080 - 160,
        type: AdvancedGrid,
        spacing: 40,
        signals: {
          focusable: '_gridFocusCheck',
        },
      },
      ScrollBar: {
        visible: false,
        zIndex: 40,
        type: AdvancedGridScrollBar,
        x: 1920 - (80 + AdvancedGridScrollBar.width),
        y: (1080 - AdvancedGridScrollBar.height) / 2,
        signals: {
          hovered: '_scrollerHovered',
          unhovered: '_scrollerUnhovered',
        },
      },
    }
  }

  get listWidth() {
    const pointerEnabled = Settings.get('app', 'enablePointer', false)
    return pointerEnabled
      ? 1920 - 160 - AdvancedGridScrollBar.width - 40
      : 1920 - 160
  }

  public navigationPath: keyof ContentItem['paths'] = 'details'
  getValidPath(paths: ContentItem['paths']): string | null {
    let destination: string | null = paths[this.navigationPath] ?? null
    if (destination) return destination
    destination = navPaths.reduce(
      (acc, path) => {
        if (acc) return acc
        return paths[path] ?? null
      },
      null as string | null
    )
    return destination
  }
  $contentSelected(item: ContentItem) {
    const destination = this.getValidPath(item.paths)
    if (destination) {
      this.fireAncestors('$navigate', destination)
    }
  }
  private _listId: string | null = null
  set listId(x: string | null) {
    this._listId = x
  }
  get listId() {
    return this._listId
  }
  private _itemType: DimensionItem = AdeBoxArtButton
  set itemType(itemType: DimensionItem) {
    if (this._itemType !== itemType) {
      this._itemType = itemType
      this.setupList()
    }
  }
  get contentSeasons(): ContentSeason[] {
    const seasons = this.content?.seasons
    return isGoodArray<ContentSeason>(seasons) ? seasons : []
  }

  public inWatchlist: boolean = false

  private _hasWatchlist = false

  set hasWatchlist(hasWatchlist: boolean) {
    if (hasWatchlist !== this._hasWatchlist) {
      this._hasWatchlist = hasWatchlist
      this.setupList()
    }
  }

  get hasWatchlist() {
    return this._hasWatchlist
  }

  listOfContent(contentItems: ContentItem[], title: string) {
    const listId = slugify(title, {
      lower: true,
      strict: true,
    })
    debug.info('Setting list', 80, this.listWidth)
    return this.PageList.gridItem({
      w: this.listWidth,
      listId,
      h: AdvancedGridList.getCardHeight(this.listWidth, this.cardType),
      cardSetup: {
        spacing: 20,
        cardsPerRow: 5,
      },
      card: this.cardType,
      content: {
        id: `${listId}:${new Date().getTime()}`,
        items: contentItems,
        title,
      },
      type: AdvancedGridList,
    })
  }

  setupList() {
    debug.info('Setup List', this.PageList)
    if (this.content) {
      // const pointerEnabled = Settings.get('app', 'enablePointer', false)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const items: Lightning.Element.NewPatchTemplate<any>[] = []
      items.push(
        this.PageList.gridItem({
          w: this.w,
          h: 700,
          x: 0,
          type: DetailsHero,
          overflowToScreen: true,
          inWatchlist: this.inWatchlist,
          content: this.content,
        })
      )

      const extra: Lightning.Element.PatchTemplate = {}

      if (this._itemType) {
        extra['itemType'] = this._itemType
      }

      if (isGoodArray<ContentItem>(this.content.similar)) {
        items.push(this.listOfContent(this.content.similar, 'Similar'))
      }

      if (isGoodArray<ContentItem>(this.content.recommendations)) {
        items.push(
          this.listOfContent(this.content.recommendations, 'Recommended')
        )
      }

      this.PageList.patch({ items })
      debug.info(
        'Patched Page List List with',
        this.PageList,
        getCoordinateDimensions(this.PageList)
      )
    }
  }

  setContent(content: ContentItem | null) {
    this.content = content
  }

  set content(content: ContentItem | undefined | null) {
    debug.info('Content', content)
    this._content = content
    this.setupList()
  }

  get content() {
    return this._content
  }

  override _getFocused() {
    return this.PageList
  }
  override _captureBack() {
    return backHandler(this.application)
  }
  override _captureKey(e: KeyboardEvent) {
    if (e.code === 'Backspace') return this._captureBack()
    return false
  }
  public page = 'Movie Details'
  override _active() {
    this.stage.application.emit('screenView', this.page, 'movie_details_page')
  }
  override _firstActive() {
    const pointerEnabled = Settings.get('app', 'enablePointer', false)
    if (pointerEnabled) {
      this.ScrollBar.patch({ visible: true })
    }
  }
}

import { DevicePlatform } from '../platforms'

// declare namespace tizen {
//   namespace application {
//     function getCurrentApplication(): {
//       exit(): void
//     }
//   }
// }

export const platform: DevicePlatform = {
  platform: 'lg',
  exit() {
    try {
      window.close()
    } catch (error) {
      console.error('Error exiting LG App')
    }
  },
}

import { Colors, Lightning, Registry } from '@lightningjs/sdk'
import { Debugger } from '../lib'
import { getStoredTheme } from '../themes'
const debug = new Debugger('GlowBalls')
// Simple sample of a GlowBallsd images
export interface GlowBallsTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Inner1: object
  Inner2: object
  Inner3: object
}
export const GLOW_BALL_TICK_LENGTH = 4
export const GlowBalls_SIZE = 50
export const GLOW_BALL_INSET_SIZE = 8
export const GLOW_BALL_IMAGE_WIDTH =
  1920 - (GlowBalls_SIZE + GLOW_BALL_INSET_SIZE) * 2
export const GLOW_BALL_IMAGE_HEIGHT =
  1080 - (GlowBalls_SIZE + GLOW_BALL_INSET_SIZE) * 2

export interface GlowBallsTypeConfig extends Lightning.Component.TypeConfig {}
export class GlowBalls
  extends Lightning.Component<GlowBallsTemplateSpec, GlowBallsTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<GlowBallsTemplateSpec>
{
  Inner1 = this.getByRef('Inner1')!
  Inner2 = this.getByRef('Inner2')!
  Inner3 = this.getByRef('Inner3')!
  static override _template(): Lightning.Component.Template<GlowBallsTemplateSpec> {
    const theme = getStoredTheme()
    return {
      x: 0,
      y: 0,
      h: 1080,
      w: 1920,
      rect: true,
      color: Colors(theme.palette.darks[400]).get(),
      rtt: true,
      Inner1: {
        x: 300,
        y: 200,
        h: 400,
        w: 400,
        rect: true,
        color: 0x00000000,
        scale: 2,
        alpha: 0.4,
        shader: {
          type: Lightning.shaders.RadialGradient,
          innerColor: Colors(theme.palette.highlights[600]).get(),
          radius: 100,
          pivot: 0.5,
        },
      },
      Inner2: {
        x: 1700,
        y: 50,
        h: 400,
        w: 400,
        rect: true,
        color: 0x00000000,
        scale: 2,
        alpha: 0.4,
        shader: {
          type: Lightning.shaders.RadialGradient,
          innerColor: Colors(theme.palette.highlights[600]).get(),
          radius: 100,
          pivot: 0.5,
        },
      },
      Inner3: {
        x: 1400,
        y: 600,
        h: 400,
        w: 400,
        rect: true,
        color: 0x00000000,
        scale: 2.4,
        alpha: 0.4,
        shader: {
          type: Lightning.shaders.RadialGradient,
          innerColor: Colors(theme.palette.highlights[600]).get(),
          radius: 100,
          pivot: 0.5,
        },
      },
    }
  }
  override _construct() {
    this.tick = this.tick.bind(this)
  }
  private _interval: ReturnType<typeof Registry.setInterval> | null = null
  private _tickTimeouts: ReturnType<typeof Registry.setTimeout>[] = []
  clearTimeout() {
    if (this._interval !== null) Registry.clearInterval(this._interval)
    this._tickTimeouts.forEach(Registry.clearTimeout)
  }

  tick() {
    if (this.active) {
      debug.info('Tick')
      const theme = this.fireAncestors('$theme')
      this._tickTimeouts.forEach(Registry.clearTimeout)
      this._tickTimeouts = []
      const colors = Object.values(theme.palette.highlights)
        .concat(Object.values(theme.palette.darks))
        .map(d => Colors(d).get())
      ;[this.Inner1, this.Inner2, this.Inner3].forEach((item, idx) => {
        const timeout = Registry.setTimeout(
          () => {
            item.setSmooth('alpha', Math.random(), {
              duration: GLOW_BALL_TICK_LENGTH,
            })

            item.setSmooth('x', Math.random() * 1920, {
              duration: GLOW_BALL_TICK_LENGTH,
            })

            item.setSmooth('y', Math.random() * 1080, {
              duration: 3 * GLOW_BALL_TICK_LENGTH,
            })
            item.setSmooth('scale', 6 * Math.random(), {
              duration: 3 * GLOW_BALL_TICK_LENGTH,
            })
            item.setSmooth(
              // @ts-ignore - bad types in lightning animation
              'shader.innerColor',
              colors[Math.floor(colors.length * Math.random())],
              { duration: 3 * GLOW_BALL_TICK_LENGTH },
            )
            this._tickTimeouts = this._tickTimeouts.filter(f => f !== timeout)
          },
          idx * 0.3 * GLOW_BALL_TICK_LENGTH,
        )
        this._tickTimeouts.push(timeout)
      })
    }
  }

  override _active() {
    this.tick()
    this._interval = Registry.setInterval(this.tick, 3000)
  }
  override _inactive() {
    debug.info('Glowballs are inactive')
    this.clearTimeout()
  }
}

import {
  DevicePlatform,
  NetworkStatus,
  NetworkStatusCallback,
  NetworkStatusSubscriber,
} from '../platforms'

const subscribeToNetworkStatus: NetworkStatusSubscriber = async (
  subscriber: NetworkStatusCallback
) => {
  let listenerId: number | null = null
  try {
    listenerId = webapis.network.addNetworkStateChangeListener(state => {
      if (state === webapis.network.NetworkState.INTERNET_DISCONNECTED) {
        subscriber(NetworkStatus.Disconnected)
      }
      if (state === webapis.network.NetworkState.INTERNET_CONNECTED) {
        subscriber(NetworkStatus.Connected)
      } else {
        console.info("Got network status we don't want: %s", state)
      }
    })
  } catch (e) {
    console.warn('Unable to access Smaung Network Change Listener')
  }
  return () => {
    if (listenerId !== null) {
      try {
        webapis.network.removeNetworkStateChangeListener(listenerId)
        listenerId = null
      } catch (e) {
        console.warn('Failed  to remove listener')
      }
    } else {
      console.warn('Already detached from listener')
    }
  }
}

export const networkStatus = async () => {
  try {
    const connected = webapis.network.isConnectedToGateway()
    return connected ? NetworkStatus.Connected : NetworkStatus.Disconnected
  } catch (e) {
    console.warn('error getting Samsung Network Status %s', e.message)
    return NetworkStatus.Connected
  }
}

export const platform: DevicePlatform = {
  platform: 'samsung',
  exit() {
    try {
      tizen.application.getCurrentApplication().exit()
    } catch (error) {
      console.error('Error exiting Tizen App')
    }
  },
  subscribeToNetworkStatus,
  networkStatus,
}

import { AdeCloseReason, DeviceType } from '@adiffengine/engine-types'
export enum NetworkStatus {
  Connected,
  Disconnected,
}
export type NetworkStatusCallback = (status: NetworkStatus) => unknown
export type UnsubscribeFunction = () => void
export type NetworkStatusSubscriber = (
  status: NetworkStatusCallback
) => Promise<UnsubscribeFunction>
export type NetworkStatusCheck = () => Promise<NetworkStatus>

export interface DevicePlatform {
  platform: DeviceType
  exit(reason: AdeCloseReason): void
  subscribeToNetworkStatus?: NetworkStatusSubscriber
  networkStatus?: NetworkStatusCheck
}

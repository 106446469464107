import {
  HoverComponentSignalMap,
  RemoteDirection,
} from '@adiffengine/engine-types'
import { Colors, Img, Lightning, Settings, Utils } from '@lightningjs/sdk'

import {
  Debugger,
  confirmCollisionsAreSet,
  getCoordinateDimensions,
  pathJoin,
} from '../../../lib'
import { HoverZone } from '../../helpers/HoverZone'

const debug = new Debugger('AdvancedGridPageClicker')
export interface AdvancedGridPageClickerTemplateSpec
  extends Lightning.Component.TemplateSpec {
  HoverZone: typeof HoverZone
  direction: RemoteDirection
  Icon: object
  disabled: boolean
  name: string
}

export interface AdvancedGridPageClickerTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: HoverComponentSignalMap
}
export class AdvancedGridPageClicker
  extends Lightning.Component<
    AdvancedGridPageClickerTemplateSpec,
    AdvancedGridPageClickerTypeConfig
  >
  implements
    Lightning.Component
      .ImplementTemplateSpec<AdvancedGridPageClickerTemplateSpec>
{
  Icon = this.getByRef('Icon')!
  static width = 50
  static height = 50
  static override _template(): Lightning.Component.Template<AdvancedGridPageClickerTemplateSpec> {
    return {
      h: 50,
      w: 50,

      Icon: {
        x: 25,
        y: 25,
        h: 50,
        w: 50,
        mount: 0.5,
        alpha: 1,
        texture: Img('images/icons/caret-forward.png').contain(50, 50),
        color: Colors('text').get(),
      },
      HoverZone: {
        zIndex: 10,
        type: HoverZone,
        h: 60,
        w: 80,
        mountX: 0.5,
        mountY: 0.5,
        x: 25,
        y: 25,
        signals: {
          hovered: '_handleHover',
          clicked: '_handleClick',
        },
      },
    }
  }
  static buttons: Record<RemoteDirection, string> = {
    left: 'caret-back.png',
    right: 'caret-forward.png',
    up: 'caret-up.png',
    down: 'caret-down.png',
  }
  private _direction: RemoteDirection = 'right'
  set direction(dir: RemoteDirection) {
    if (dir !== this._direction) {
      this._direction = dir

      this.Icon.patch({
        texture: Img(
          Utils.asset(
            pathJoin(['images/icons', AdvancedGridPageClicker.buttons[dir]])
          )
        ).contain(50, 50),
      })
    }
  }
  public name: string = 'unknown'
  get direction() {
    return this._direction
  }
  private _focusAnimation: Lightning.types.Animation | null = null

  get focusAnimation() {
    if (this._focusAnimation === null) {
      this._focusAnimation = this.animation({
        duration: 0.2,
        actions: [
          { p: 'scale', v: { 0: 1, 1: 1.1 } },
          {
            t: 'Icon',
            p: 'color',
            v: {
              0: Colors('text').get(),
              1: Colors('primaryHighlight').get(),
            },
          },
        ],
      })
    }
    return this._focusAnimation
  }

  private _disabled: boolean = false
  set disabled(disabled: boolean) {
    if (disabled !== this._disabled) {
      this._disabled = disabled
      if (disabled) this.focusAnimation?.stop()
      this.setSmooth('alpha', disabled ? 0.1 : 1)
    }
  }
  _handleClick() {
    if (!this._disabled) {
      this.signal('clicked', getCoordinateDimensions(this))
    }
    return false
  }

  override _handleHover() {
    debug.info('Handle Hovered')
    if (!this._disabled) {
      this.focusAnimation.start()
      this.signal('hovered', getCoordinateDimensions(this))
    }
  }
  _handleUnhover() {
    if (!this._disabled) {
      this.focusAnimation.stop()
      this.signal('unhovered', getCoordinateDimensions(this))
    }
  }

  override _active() {
    confirmCollisionsAreSet(this)
  }
}

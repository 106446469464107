import { XMLParser } from 'fast-xml-parser'
import { ContentItem, ContentSource } from '@adiffengine/engine-types'
import {
  MediaType,
  MrssFeedItem,
  isString,
  parseMrssXmlItems,
  parseString,
  parseStringList,
} from './mrss-feed'

export async function fetchMrssUrl(url: string): Promise<MrssFeedItem[]> {
  const response = await fetch(url)
  const text = await response.text()

  const xml = new XMLParser({
    attributeNamePrefix: '',
    textNodeName: '$text',
    ignoreAttributes: false,
    trimValues: false,
    parseAttributeValue: true,
    //@ts-ignore
    isArray: (name: string): undefined | boolean => {
      if (name === 'media:thumbnail' || name === 'media:category') return true
      else if (name === 'playlist') return false
    },
    tagValueProcessor: (tagName: string, tagValue: unknown) => {
      if (isString(tagValue)) {
        tagValue = tagValue.trim()
      }
      switch (tagName) {
        case 'item_id':
        case 'title':
        case 'keywords':
        case 'description':
          return parseString(tagValue)
        case 'playlist':
        case 'showname':
          return parseString(tagValue).replace(/_/gi, ' ')
        case 'categories':
          return parseStringList(tagValue)
        default:
          return tagValue
      }
    },
  })
  const result = xml.parse(text) as any
  const {
    rss: { channel },
  } = result

  const items = Array.isArray(channel.item)
    ? (channel.item as any[]).filter(item => {
        const status = item['media:status']
        if (!status) return true
        else return status.state !== 'blocked'
      })
    : []

  const output = parseMrssXmlItems(items)
  return output
}

export function mediaTypeLookup(type: MediaType): ContentSource['type'] | null {
  switch (type) {
    case MediaType.VideoHLS:
      return 'hls'
    case MediaType.VideoMp4:
      return 'mp4'
    default:
      console.warn('Invalid media type', type)
      return null
  }
}
export interface MrssContentItem extends ContentItem {
  showname?: string
  gridId: string
}

import {
  ContentEpisode,
  ContentSeason,
  DirectionalSignalMap,
} from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { format } from 'fecha'
import { SimpleCard, SimpleCardPatch } from './SimpleCard'
// import { Debugger } from '../lib'
// const debug = new Debugger('SeasonDetailsInnerPage')

import { SeasonList } from './SeasonList'
import { SignaledList } from './SignaledList'
export interface SeasonDetailsInnerPageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  ListWrapper: {
    List: typeof SignaledList
  }
  ListTitle: object
  SeasonList: typeof SeasonList
  Details: {
    Title: object
    Description: object
  }
  seasons: ContentSeason[]
  season?: ContentSeason
  resetListOnBlur: boolean
  height: number
  width: number
}

export interface SeasonDetailsInnerPageSignals extends DirectionalSignalMap {
  episodeSelected(episode: ContentEpisode): void
  seasonSelected(season: ContentSeason): void
}
export interface SeasonDetailsInnerPageTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: SeasonDetailsInnerPageSignals
}

export class SeasonDetailsInnerPage
  extends Lightning.Component<
    SeasonDetailsInnerPageTemplateSpec,
    SeasonDetailsInnerPageTypeConfig
  >
  implements
    Lightning.Component
      .ImplementTemplateSpec<SeasonDetailsInnerPageTemplateSpec>
{
  EpisodeList = this.getByRef('ListWrapper')!.getByRef('List')!
  SeasonList = this.getByRef('SeasonList')!
  ListTitle = this.getByRef('ListTitle')!

  static override _template(): Lightning.Component.Template<SeasonDetailsInnerPageTemplateSpec> {
    return {
      x: 0,
      y: 0,
      h: 700,
      w: 1920,
      rect: true,
      color: 0x00000000,
      rtt: true,
      shader: {
        type: Lightning.shaders.FadeOut,
        top: 20,
        bottom: 20,
      },
      SeasonList: {
        x: 80,
        y: 40,
        w: 700,
        type: SeasonList,
        passSignals: {
          up: true,
        },
        signals: {
          left: 'signalLeft',
          season: '_currentSeason',
        },
      },
      Details: {
        visible: false,
        x: 80,
        y: 80,
        w: 1920 - 320,
        flex: {
          direction: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        },
        Title: {
          text: {
            fontSize: 48,
            fontFace: 'Bold',
            wordWrapWidth: 600,
            wordWrap: true,
          },
        },
        Description: {
          text: {
            fontSize: 24,
            lineHeight: 40,
            fontFace: 'Text',
            wordWrapWidth: 600,
            wordWrap: true,
          },
        },
      },
      ListTitle: {
        x: 900,
        y: 20,
        text: {
          fontSize: 36,
          fontFace: 'Bold',
          wordWrapWidth: 600,
          wordWrap: true,
        },
      },
      ListWrapper: {
        x: 900,
        y: 100,
        w: 700,
        h: 760,
        rect: true,
        color: 0x00000000,
        rtt: true,
        shader: {
          type: Lightning.shaders.FadeOut,
          top: 20,
        },

        List: {
          x: 0,
          y: 20,
          w: 700,
          h: 740,
          type: SignaledList,
          listConfig: {
            spacing: 40,
            itemType: SimpleCard,
            direction: 'column',
          },
          passSignals: {
            up: true,
          },
          signals: {
            selected: 'episodeSelected',
            left: 'focusSeasonList',
            right: 'signalRight',
          },
        },
      },
    }
  }

  public resetListOnBlur: boolean = false

  setCurrentSeason(season: ContentSeason) {
    console.info('Settings current Season', season)
    this.season = season
  }

  private _seasons: ContentSeason[] = []

  focusEpisodeList() {
    const coords = this.SeasonList.getCurrentIndexCoordinates()
    this.EpisodeList.setClosestByY(coords)
    this.focusedList = this.EpisodeList
  }
  focusSeasonList() {
    const coords = this.EpisodeList.getCurrentIndexCoordinates()
    this.SeasonList.setClosestByY(coords)
    this.focusedList = this.SeasonList
  }

  episodeSelected(index: number) {
    const episode = Array.isArray(this.season?.episodes)
      ? this.season?.episodes[index]
      : undefined
    if (episode) {
      this.signal('episodeSelected', episode)
    }
  }
  set seasons(seasons: ContentSeason[]) {
    this._seasons = seasons
    this.SeasonList.patch({
      season: seasons[0].id,
      seasons,
      passSignals: {
        up: true,
      },
      signals: {
        season: this.setCurrentSeason.bind(this),
        right: this.focusEpisodeList.bind(this),
      },
    })

    if (seasons[0]) {
      this.season = seasons[0]
    }
  }
  get seasons() {
    return this._seasons
  }

  async loadEpisodes() {
    if (this._season && this.active) {
      const response = await this.fireAncestors('$fetchSeason', this._season.id)
      if (response) {
        console.info('Got episodes', response.episodes)
        this.patchEpisodes(response.episodes)
      } else {
        console.warn('No response to load episodes')
      }
    }
  }
  patchEpisodes(episodes?: ContentEpisode[] | null) {
    if (this.season && Array.isArray(episodes) && episodes.length) {
      const items: SimpleCardPatch[] = episodes.map((item, idx) => {
        const details = item.air_date ? format(item.air_date, 'MMMM, YYYY') : ''
        const out: SimpleCardPatch = {
          contentId: `${this.season!.id}:season/${item.id}:${idx}`,
          title: item.name!,
          details,
          imageSrc: item.image?.getForWidth(100),
          description: item.overview ?? '',
          w: 700,
          h: 200,
          imageWidthRatio: 4 / 3,
          padding: 20,
        }
        return out
      })
      this.EpisodeList.List.patch({ items })
    } else {
      this.EpisodeList.List.clear()
    }
  }
  private _season: ContentSeason | undefined = undefined

  set season(season: ContentSeason | undefined) {
    this._season = season
    if (season) {
      if (Array.isArray(season.episodes) && season.episodes.length) {
        this.patchEpisodes(season.episodes)
      } else {
        this.loadEpisodes()
        this.EpisodeList.List.clear()
      }
      this.ListTitle.patch({ text: { text: season.name ?? '' } })
      this.signal('seasonSelected', season)
    }
  }
  get season() {
    return this._season
  }
  private _height: number = 700
  set height(h: number) {
    this.patch({
      h,
      ListWrapper: {
        List: {
          h: h - 40,
        },
      },
    })
    this._height = h
  }
  get height(): number {
    return this._height
  }
  private _width: number = 1920
  set width(w: number) {
    this.patch({
      w,
    })
    this._width = w
  }
  get width(): number {
    return this._width
  }

  _onBlur() {
    this.EpisodeList.List.first()
  }

  get useDimensionName() {
    return true
  }
  override _active() {
    if (
      this.season &&
      (!Array.isArray(this.season.episodes) ||
        this.season?.episodes.length === 0)
    ) {
      this.loadEpisodes()
    }
  }
  override $enter() {
    this._refocus()
  }

  private _focusedList: typeof this.SeasonList | typeof this.EpisodeList =
    this.SeasonList

  set focusedList(list: typeof this._focusedList) {
    this._focusedList = list
    this._refocus
  }
  get focusedList() {
    return this._focusedList
  }
  override _getFocused() {
    return this.focusedList
  }
  override _handleUp(): void | false {
    if (this.SeasonList.index === 0) {
      this.signal('up')
    } else {
      return false
    }
  }
  signalRight() {
    this.signal('right')
  }
  signalLeft() {
    this.signal('left')
  }
}

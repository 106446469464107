import {
  CastMember,
  DirectionalSignalMap,
  isID,
} from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { format } from 'fecha'
import isDate from 'lodash-es/isDate'
import isString from 'lodash-es/isString'
import { Debugger, isGoodString, sleep } from '../lib'
import { Scroller } from './Scroller'
const debug = new Debugger('CastDetailsInnerPage')

import { SignaledList } from './SignaledList'
import { SimpleCard, SimpleCardPatch } from './SimpleCard'
export interface CastDetailsInnerPageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  CastList: typeof SignaledList
  Details: {
    Name: object
    Details: object
    Character: object
    Description: object
  }
  Scroller: typeof Scroller
  cast: CastMember[]
  resetListOnBlur: boolean
}

export interface CastDetailsInnerPageTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: DirectionalSignalMap
}

export class CastDetailsInnerPage
  extends Lightning.Component<
    CastDetailsInnerPageTemplateSpec,
    CastDetailsInnerPageTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<CastDetailsInnerPageTemplateSpec>
{
  Details = this.getByRef('Details')!
  DetailsDetails = this.Details.tag('Details')!
  Character = this.Details.tag('Character')!
  Name = this.Details.tag('Name')!
  CastList = this.getByRef('CastList')!
  Scroller = this.getByRef('Scroller')!
  private _scrollerEnabled: boolean = false
  static override _template(): Lightning.Component.Template<CastDetailsInnerPageTemplateSpec> {
    return {
      x: 0,
      y: 0,
      h: 700,
      w: 1920,
      rect: true,
      rtt: true,
      color: 0x00000000,
      shader: {
        type: Lightning.shaders.FadeOut,
        top: 20,
        bottom: 20,
      },
      CastList: {
        x: 80,
        y: 40,
        w: 700,
        type: SignaledList,
        listConfig: {
          direction: 'column',
          spacing: 20,
        },
        passSignals: {
          up: true,
        },
        signals: {
          left: 'signalLeft',
          right: 'focusScroller',
          selected: '_castSelected',
        },
      },
      Scroller: {
        alpha: 0.0001,
        type: Scroller,
        mountX: 1,
        passSignals: {
          up: true,
        },
        signals: {
          left: 'focusCastList',
          right: 'focusScroller',
          percent: 'scrollPercent',
        },
      },
      Details: {
        visible: true,
        x: 900,
        y: 40,
        w: 1920 - 980,
        flex: {
          direction: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        },
        Name: {
          visible: false,
          text: {
            text: '',
            fontSize: 48,
            fontFace: 'Bold',
            wordWrapWidth: 600,
            wordWrap: true,
          },
        },
        Character: {
          visible: false,
          flexItem: {
            marginTop: 12,
          },
          text: {
            text: '',
            fontSize: 24,
            fontFace: 'Regular',
            wordWrapWidth: 600,
            wordWrap: true,
          },
        },
        Details: {
          visible: false,
          flexItem: {
            marginTop: 12,
          },
          text: {
            text: '',
            fontSize: 24,
            fontFace: 'Regular',
            wordWrapWidth: 600,
            wordWrap: true,
          },
        },
        Description: {
          visible: false,
          flexItem: {
            marginTop: 24,
          },
          text: {
            fontSize: 24,
            lineHeight: 40,
            fontFace: 'Text',
            wordWrapWidth: 600,
            wordWrap: true,
          },
        },
      },
    }
  }

  scrollPercent(x: number) {
    debug.info('Scroll Percent %s', x)
    const lastItem = this.Details.children[this.Details.children.length - 1]
    if (lastItem) {
      const textHeight = lastItem.finalY + lastItem.finalH
      const containerHeight = this.h - 180
      if (textHeight > containerHeight) {
        const diff = textHeight - containerHeight
        const amount = x * diff * -1
        debug.info('offset %s diff: %s percent: %s', amount, diff, x)
        this.Details.patch({
          smooth: {
            y: [amount + 40, { duration: 0.2 }],
          },
        })
      }
    }
  }
  public resetListOnBlur: boolean = false

  get detailsVisible() {
    let visible = false
    this.Details.children.forEach(c => {
      if (c.visible === true) visible = true
    })
    return visible
  }
  focusScroller() {
    if (this.detailsVisible && this._scrollerEnabled) {
      this.focusedList = this.Scroller
    }
  }
  focusCastList() {
    this.focusedList = this.CastList
  }
  _castSelected(index: number) {
    const current = this.cast[index]
    if (current) {
      this.currentCastMember = current
    }
  }
  private _cast: CastMember[] = []
  set cast(cast: CastMember[]) {
    if (Array.isArray(cast) && cast.length) {
      this.CastList.patch({ visible: true })
      this._cast = cast
      if (this._cast[0]) {
        this.currentCastMember = this._cast[0]
      }
    } else {
      this.CastList.patch({ visible: false })
      this._cast = []
    }
  }
  get cast() {
    return this._cast
  }
  async _loadCurrentCastMember() {
    if (isID(this._currentCastMember?.id)) {
      const person = await this.fireAncestors(
        '$fetchPerson',
        this._currentCastMember!.id,
      )
      if (person) {
        if (person.name) {
          this.Name.patch({
            text: person.name,
            visible: true,
          })
        } else {
          this.Name.patch({
            visible: false,
          })
        }
        if (person.birthday || person.place_of_birth) {
          let details = ``
          if (isDate(person.birthday)) {
            details = `Born ${format(person.birthday, 'MMMM Do, YYYY')}`
            if (person.place_of_birth)
              details = `${details} in ${person.place_of_birth}`
          } else if (isString(person.place_of_birth)) {
            details = `From ${person.place_of_birth}`
          }
          this.DetailsDetails.patch({
            text: { text: details },
            visible: true,
          })
        } else {
          this.DetailsDetails.patch({ visible: false })
        }
        if (isGoodString(this._currentCastMember?.character)) {
          this.Character.patch({
            text: { text: this._currentCastMember!.character },
            visible: true,
          })
        } else {
          this.Character.patch({ visible: false })
        }
        if (isGoodString(person.biography)) {
          this.Details.tag('Description')!.patch({
            text: {
              text: person.biography,
            },
            visible: true,
          })
        } else {
          this.Details.tag('Description')!.patch({ visible: false })
        }
      }

      window.requestAnimationFrame(async () => {
        await sleep(100)
        const lastItem = this.Details.children[this.Details.children.length - 1]
        if (lastItem) {
          const currentHeight = lastItem.finalY + lastItem.finalH
          debug.info(
            'Current Height, %s, this.h: %s, this.h - 180 = %s',
            currentHeight,
            this.h,
            this.h - 180,
          )
          if (currentHeight > this.h - 180) {
            this.Scroller.patch({
              heightPercent: currentHeight / (this.h - 180),
              percent: 0,
              smooth: {
                alpha: [1, { duration: 0.2 }],
              },
            })
            this._scrollerEnabled = true
          } else {
            this.Scroller.patch({
              percent: 0,
              smooth: {
                alpha: [0.0001, { duration: 0.2 }],
              },
            })
            this._scrollerEnabled = false
          }
        }
      })
    }
  }

  private _currentCastMember: CastMember | undefined = undefined
  set currentCastMember(cast: CastMember) {
    this._currentCastMember = cast
    this._loadCurrentCastMember()
  }
  patchList() {
    if (this.__initialized) {
      this.height = this.h
      debug.info('Initialized, patching items Cast List')
      this.CastList.patch({
        height: this.h - 80,
        width: 700,
      })
      this.Scroller.patch({
        h: this.h - 180,
        x: this.w - 80,
        w: 10,
        y: 60,
      })
      const wrapPatch = {
        text: { wordWrapWidth: 700 },
      }
      this.Details.patch({
        Name: wrapPatch,
        Description: wrapPatch,
        Details: wrapPatch,
        Character: wrapPatch,
      })
      const items: SimpleCardPatch[] = this._cast.map(castMember => {
        const out: SimpleCardPatch = {
          type: SimpleCard,
          contentId: isString(castMember['id']) ? castMember['id'] : '',
          title: isString(castMember['name']) ? castMember['name'] : '',
          details: isString(castMember['character'])
            ? castMember['character']
            : '',
          imageSrc: castMember.profile?.getForWidth(100),
          w: 700,
          h: 200,
          imageWidthRatio: 3 / 4,
          padding: 20,
        }
        return out
      })
      this.CastList.patch({ items })
    }
  }

  private _height: number = 700
  set height(h: number) {
    this._height = h
  }
  get height(): number {
    return this._height
  }
  private _width: number = 1920
  set width(w: number) {
    this.patch({
      w,
    })
    this._width = w
  }
  get width(): number {
    return this._width
  }

  override $enter() {
    this._refocus()
  }

  private _focusedList: typeof this.CastList | typeof this.Scroller =
    this.CastList

  set focusedList(list: typeof this._focusedList) {
    this._focusedList = list
    this._refocus
  }
  get focusedList() {
    return this._focusedList
  }

  override _getFocused() {
    return this.focusedList
  }

  override _handleUp(): void | false {
    if (this.CastList.index === 0) {
      this.signal('up')
    } else {
      return false
    }
  }
  override _active() {
    this.patchList()
  }
  signalRight() {
    this.signal('right')
  }
  signalLeft() {
    this.signal('left')
  }
}

import { Lightning } from '@lightningjs/sdk'
import isFunction from 'lodash-es/isFunction'
import isNumber from 'lodash-es/isNumber'
import { Debugger } from '../lib'
const debug = new Debugger('ScreenSaver')
export interface ScreenSaverTemplateSpec
  extends Lightning.Component.TemplateSpec {
  duration: number
  hideDuration: number
  showDuration: number
}

export interface ShowableElement extends Lightning.Element {
  show(duration?: number): void | Promise<void>
  hide(duration?: number): void | Promise<void>
}
export function isShowableElement(x: Lightning.Element): x is ShowableElement {
  return (
    x &&
    isFunction((x as ShowableElement).show) &&
    isFunction((x as ShowableElement).hide)
  )
}
export interface ScreenSaverTypeConfig extends Lightning.Component.TypeConfig {}
export class ScreenSaver
  extends Lightning.Component<ScreenSaverTemplateSpec, ScreenSaverTypeConfig>
  implements
    Lightning.Component.ImplementTemplateSpec<ScreenSaverTemplateSpec>,
    ShowableElement
{
  static override _template(): Lightning.Component.Template<ScreenSaverTemplateSpec> {
    return {
      alpha: 0.0001,
      x: 0,
      y: 0,
      h: 1080,
      w: 1920,
    }
  }
  private _hideDuration: number = 0.2
  private _showDuration: number = 0.4
  private _duration: number = 0.2
  private _currentlyActive: boolean = false

  show(d?: number) {
    debug.info(' Show screen saver')
    const duration = isNumber(d) ? d : this.showDuration
    if (!this._currentlyActive) {
      this._currentlyActive = true
      this.patch({
        smooth: {
          alpha: [1, { duration }],
        },
      })
    }
    this.childList.forEach(c => {
      debug.info('Is showable? ', c, isShowableElement(c))
      if (isShowableElement(c)) c.show(d)
    })
  }

  hide(d?: number) {
    const duration = isNumber(d) ? d : this.hideDuration
    if (this._currentlyActive) {
      this._currentlyActive = false
      this.patch({
        smooth: {
          alpha: [0.0001, { duration }],
        },
      })
    }
    this.childList.forEach(c => {
      if (isShowableElement(c)) c.hide(d)
    })
  }

  set duration(duration: number) {
    this.hideDuration = duration
    this.showDuration = duration
    this._duration = duration
  }
  get duration() {
    return this._duration
  }
  set hideDuration(duration: number) {
    this._hideDuration = duration
  }
  get hideDuration() {
    return this._hideDuration
  }

  set showDuration(duration: number) {
    this._showDuration = duration
  }
  get showDuration() {
    return this._showDuration
  }
}

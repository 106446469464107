import {
  CoordinateDimensions,
  HorizontalNavSignalMap,
  SetsByY,
} from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import { Debugger, cp, getClosestIndexByY, isGoodNumber } from '../lib'

import { CardDetails, hashCode, makeClosestFocusable } from '../lib'

import {
  SIMPLE_CARD_BASE_HEIGHT,
  SimpleCard,
  SimpleCardTemplateConfig,
} from './SimpleCard'
const debug = new Debugger('SCL')
debug.enabled = false

type IndexedList = typeof List & {
  index: number
}
export interface SimpleCardListTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Header: object
  List: typeof List
  itemConfig?: SimpleCardListItemConfig
  items: Omit<SimpleCardListItem, keyof SimpleCardListItemConfig>[]
  label?: string
  handleSelected: boolean
}

export interface SimpleCardListItemConfig
  extends Pick<SimpleCardTemplateConfig, 'padding' | 'imageWidthRatio'> {
  w: number
  h: number
}

export interface SeasonTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: SimpleListSeasonListSignals
}

export interface SimpleListSeasonListSignals extends HorizontalNavSignalMap {
  selected(season: SimpleCardListItem): void
}
export interface SimpleCardListItem<T = any> extends SimpleCardTemplateConfig {
  payload?: T
}
export class SimpleCardListBase
  extends Lightning.Component<SimpleCardListTemplateSpec, SeasonTypeConfig>
  implements
    Lightning.Component.ImplementTemplateSpec<SimpleCardListTemplateSpec>,
    SetsByY
{
  static marginTop = 64
  private _itemConfig: SimpleCardListItemConfig = {
    w: 200,
    h: 80,
    padding: 16,
    imageWidthRatio: 1,
  }
  public handleSelected: boolean = true
  List = this.getByRef('List')!
  Header = this.getByRef('Header')!
  static override _template(): Lightning.Component.Template<SimpleCardListTemplateSpec> {
    return {
      h: SIMPLE_CARD_BASE_HEIGHT + 64,
      Header: {
        x: 20,
        y: 0,
        w: 500,
        h: 40,
        rect: true,
        color: Colors('white').get(),
        text: {
          text: '',
          fontFace: 'Bold',
          fontSize: 36,
        },
      },
      List: {
        x: 0,
        y: 64,
        w: cp,
        h: SIMPLE_CARD_BASE_HEIGHT,
        type: List,
        itemType: SimpleCard,
        spacing: 36,
      },
    }
  }

  set itemConfig(config: Partial<SimpleCardListItemConfig>) {
    this._itemConfig = { ...this._itemConfig, ...config }
  }
  get itemConfig(): SimpleCardListItemConfig {
    return this._itemConfig
  }
  private _items: SimpleCardListItem[] = []
  set items(items: SimpleCardListItem[]) {
    this._items = items
    this.List.patch({
      items: this.items.map(item => ({
        ...this._itemConfig,
        ...item,
      })),
    })
  }

  get items() {
    return this._items.map(config => ({
      ...config,
      ...this.itemConfig,
    }))
  }

  set label(text: string) {
    this.Header.patch({
      text: { text },
    })
  }
  private _spacing = 30
  set spacing(spacing: number) {
    this._spacing = spacing
    this.List.patch({
      spacing,
    })
  }

  get spacing() {
    return this._spacing
  }
  get cardList(): IndexedList {
    const list = this.List as IndexedList
    return list as IndexedList
  }

  override _captureEnter() {
    if (this.cardList.hasFocus()) {
      const card = this.items[this.cardList.index]
      if (card?.action && this.handleSelected) {
        this.fireAncestors(card.action.action, card.action.payload)
      } else {
        this.signal(
          'selected',
          this.items[this.cardList.index]?.payload ??
            this.items[this.cardList.index] ??
            this.cardList.index,
        )
        this.fireAncestors(
          '$itemSelected',
          this.items[this.cardList.index]?.payload ??
            this.items[this.cardList.index] ??
            this.cardList.index,
        )
      }
      return true
    } else {
      return false
    }
  }
  private _numberOfCards: number = 4
  get numberOfCards(): number {
    this._numberOfCards = Math.floor(
      this.w / (this.itemConfig.w + this.spacing),
    )
    return this._numberOfCards
  }

  override _init() {
    const theme = this.fireAncestors('$theme')
    if (theme) {
      this.Header?.patch({
        color: Colors(theme.palette.text).get(),
      })
    }
    const width = this.w
    if (isGoodNumber(width, true)) {
      this.List.patch({
        w: this.w,
      })
    }
  }

  override _getFocused() {
    return this.List
  }

  listId() {
    return hashCode(this.items.map(({ contentId }) => contentId).join(':'))
  }

  list(): typeof List {
    return this.cardList
  }
  cardDetails(): CardDetails {
    return {
      numberOfVisibleCards: this.numberOfCards,
      cardWidth: this.itemConfig.w,
      cardHeight: this.itemConfig.h,
    }
  }
  setClosestByY(coords: CoordinateDimensions | null) {
    if (coords && this.cardList) {
      const index = getClosestIndexByY(coords, this.cardList.children ?? [])
      if (isGoodNumber(index, true)) this.cardList.setIndex(index)
    }
  }
}
export const SimpleCardList = makeClosestFocusable(SimpleCardListBase)

import {
  ActionConfig,
  ContentSelectedDirectionalSignalMap,
  Coords,
} from '@adiffengine/engine-types'
import { Colors, Img, Lightning } from '@lightningjs/sdk'
import { Debugger, getCoordinateDimensions } from '../../lib'
import { getStoredTheme } from '../../themes'
import { AdvancedGridCardSetup } from './AdvancedGridTypes'

export interface AdvancedTextCardTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Inner: {
    Shadow: object
    Poster: object
    Focus: object
    Vignette: object
    InnerContent: {
      Label: {
        Title: object
        Detail: object
      }
    }
  }
  cardSetup: Partial<AdvancedGridCardSetup>
  title: string
  details?: string
  image?: string
  ratio?: number
  action: ActionConfig
}

// @ts-ignore
const debug = new Debugger('AdvancedGridList')

export interface AdvancedTextCardTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: ContentSelectedDirectionalSignalMap
}

export class AdvancedTextCard
  extends Lightning.Component<
    AdvancedTextCardTemplateSpec,
    AdvancedTextCardTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<AdvancedTextCardTemplateSpec>
{
  private _focusAnimation: Lightning.types.Animation | undefined = undefined

  Inner = this.getByRef('Inner')!
  Shadow = this.Inner.getByRef('Shadow')!
  InnerContent = this.Inner.getByRef('InnerContent')!
  Vignette = this.Inner.getByRef('Vignette')!
  Label = this.InnerContent.getByRef('Label')!
  Title = this.Label.getByRef('Title')!
  Detail = this.Label.getByRef('Detail')!
  Poster = this.Inner.getByRef('Poster')!

  static ratio = 9 / 16
  static cardSetup: AdvancedGridCardSetup = {
    spacing: 20,
    cardsPerRow: 5,
  }
  static spacing = 20
  static width = 318
  static height = (318 - this.spacing) * (9 / 16)
  static cardOffset: Partial<Coords> = {
    x: 16,
    y: 16,
  }

  public cardSetup = AdvancedTextCard.cardSetup
  public details: string | undefined = undefined
  public title = ''
  public image: undefined | string = undefined
  public action: ActionConfig = {
    action: '$warn',
    payload: 'No action implemented for card',
  }

  static getDimensionsForWidth(
    w: number,
    setup: Partial<AdvancedGridCardSetup> = {},
  ) {
    const {
      cardsPerRow = this.cardSetup.cardsPerRow,
      spacing = this.cardSetup.spacing,
    } = setup
    const width = w / cardsPerRow
    const height = (width - spacing) * this.ratio
    return {
      height,
      width,
      spacing,
    }
  }

  static override _template(): Lightning.Component.Template<AdvancedTextCardTemplateSpec> {
    const theme = getStoredTheme()
    const {
      components: { VideoCardConfig },
    } = theme
    const radiusShader = {
      type: Lightning.shaders.RoundedRectangle,
      radius: VideoCardConfig.radius,
    }

    return {
      w: this.width,
      h: this.height,
      Inner: {
        w: (w: number) => w - this.spacing,
        h: (h: number) => h,
        Shadow: {
          alpha: 0,
          mountX: 0.5,
          mountY: 0.5,
          x: (w: number) => w / 2,
          y: (h: number) => h / 2,
          w: (w: number) => w + 24,
          h: (h: number) => h + 24,
          color: Colors('shadow').alpha(0.6).get(),
          rect: true,
          shader: { type: Lightning.shaders.FadeOut, fade: 24 },
        },
        Focus: {
          alpha: 0,
          mountX: 0.5,
          mountY: 0.5,
          x: (w: number) => w / 2,
          y: (h: number) => h / 2,
          w: (w: number) => w + 8,
          h: (h: number) => h + 8,
          rect: true,
          color: Colors(theme.palette.highlights[500]).get(),
          shader: radiusShader,
        },

        Poster: {
          x: 0,
          y: 0,
          w: (w: number) => w,
          h: (h: number) => h,
          rect: true,
          rtt: true,
          alpha: 0.0001,
          shader: radiusShader,
        },
        Vignette: {
          x: 0,
          y: 0,
          w: (w: number) => w,
          h: (h: number) => h,
          alpha: 0.8,
          colorBottom: Colors(VideoCardConfig.vignetteColor).get(),
          colorTop: Colors(VideoCardConfig.vignetteColor).alpha(0.01).get(),
          rect: true,
          rtt: true,
          shader: radiusShader,
        },
        InnerContent: {
          x: 12,
          y: 0,
          w: (w: number) => w - 24,
          h: (h: number) => h - 24,
          clipping: true,
          rect: true,
          rtt: true,
          color: 0x00000000,
          Label: {
            x: 0,
            w: (w: number) => w,
            h: (h: number) => h,
            flex: {
              direction: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            },
            Title: {
              text: {
                advancedRenderer: true,
                text: '',
                fontFace: 'Regular',
                fontSize: 22,
                lineHeight: 24,
                textAlign: 'center',
                wordWrap: true,
                maxLines: 2,
              },
            },
            Detail: {
              flexItem: {
                marginTop: 4,
              },
              text: {
                advancedRenderer: true,
                text: '',
                fontFace: 'Text',
                fontSize: 16,
                lineHeight: 20,
                textAlign: 'center',
                wordWrap: false,
                maxLines: 2,
              },
            },
          },
        },
      },
    }
  }

  private _spacing: number = AdvancedTextCard.cardSetup.spacing
  set spacing(spacing: number) {
    if (spacing !== this._spacing) {
      this._spacing = spacing
      const innerWidth = (this.w = spacing)
      const innerHeight = innerWidth * AdvancedTextCard.ratio
      this.Inner.patch({
        w: innerWidth,
        h: innerHeight,
        InnerContent: {
          w: innerWidth,
          h: innerHeight,
          Label: {
            w: innerWidth - 12,
            h: innerHeight - 12,
            Title: {
              x: innerWidth,
              text: {
                wordWrapWidth: innerHeight - 12,
              },
            },
            Detail: {
              text: {
                wordWrapWidth: innerHeight - 12,
              },
            },
          },
        },
      })
    }
  }
  get spacing() {
    return this._spacing
  }
  private _ratio: number = AdvancedTextCard.ratio
  set ratio(ratio: number) {
    this._ratio = ratio
  }
  get ratio() {
    return this._ratio
  }

  renderItems() {
    if (this.enabled) {
      const theme = this.fireAncestors('$theme')
      const {
        components: { VideoCardConfig },
      } = theme
      const innerWidth = this.w - this.spacing
      const innerHeight = this.w * this.ratio
      const innerUpdate = {
        w: innerWidth,
        h: innerHeight,
      }

      this.patch({
        h: innerHeight,
        Inner: {
          ...innerUpdate,
          Shadow: {
            x: innerUpdate.w / 2,
            y: innerUpdate.h / 2,
            w: innerUpdate.w + 24,
            h: innerUpdate.h + 24,
          },
          Poster: innerUpdate,
          Focus: {
            x: innerUpdate.w / 2,
            y: innerUpdate.h / 2,
            w: innerUpdate.w + 8,
            h: innerUpdate.h + 8,
          },
          Vignette: innerUpdate,
          InnerContent: {
            x: 12,
            w: innerUpdate.w - 24,
            h: innerUpdate.h - 12,
            Label: {
              w: innerUpdate.w - 24,
              h: innerUpdate.h - 12,
              y: 24,
              Title: {
                text: {
                  wordWrap: true,
                  wordWrapWidth: innerWidth - 24,
                  text: this.title,
                },
              },
              Detail: {
                alpha: 0.01,
                visible: Boolean(this.details && this.details.trim() !== ''),
                text: {
                  text: this.details,
                  wordWrapWidth: innerWidth - 24,
                },
              },
            },
          },
        },
      })
      const radiusShader = {
        type: Lightning.shaders.RoundedRectangle,
        radius: VideoCardConfig.radius,
      }

      this.Label.patch({
        Title: {
          text: {
            text: this.title ?? '',
          },
        },
      })
      if (this.image && this.image.trim() !== '')
        if (this.image && this.image.trim() !== '') {
          this.Poster.patch({
            visible: true,
            shader: radiusShader,
            texture: Img(this.image).cover(innerWidth, innerHeight),
          })
        } else {
          this.Poster.patch({
            visible: false,
          })
        }

      if (this.Poster) {
        this.Poster.on('txLoaded', () => {
          this.Poster.setSmooth('alpha', 1)
        })
        this.Poster.on('txError', () => {
          this.Poster.patch({
            visible: false,
          })
        })
      }
    }
  }
  override _active() {
    this.renderItems()
  }
  override _handleUp() {
    this.signal('up', getCoordinateDimensions(this))
    return false
  }
  override _handleDown() {
    this.signal('down', getCoordinateDimensions(this))
    return false
  }
  override _handleLeft() {
    this.signal('left', getCoordinateDimensions(this))
    return false
  }
  override _handleRight() {
    this.signal('right', getCoordinateDimensions(this))
    return false
  }
  get focusAnimation(): Lightning.types.Animation {
    if (!this._focusAnimation) {
      this._focusAnimation = this.animation({
        duration: 0.2,
        delay: 0.05,
        actions: [
          { t: 'Inner.Shadow', p: 'alpha', v: { 0: 0, 1: 1 } },
          { t: 'Inner.Focus', p: 'alpha', v: { 0: 0, 1: 1 } },
          {
            t: 'Inner.InnerContent.Label',
            p: 'y',
            v: { 0: 24, 1: 0 },
          },
          {
            t: 'Inner.InnerContent.Label.Detail',
            p: 'alpha',
            v: { 0: 0.01, 1: 1 },
          },
          { t: 'Inner', p: 'scale', v: { 0: 1, 1: 1.05 } },
        ],
      })
    }
    return this._focusAnimation
  }
  override _focus() {
    this.focusAnimation.start()
  }
  override _unfocus() {
    this.focusAnimation.stop()
    this.patch({
      zIndex: 1,
    })
  }
  override _captureEnter() {
    this.fireAncestors(this.action.action, this.action.payload)
  }
}

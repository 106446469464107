import {
  ContentItem,
  CoordinateDimensions,
  GridPosition,
  LeftRightSignalMap,
  RemoteDirection,
} from '@adiffengine/engine-types'
import { Lightning, Registry } from '@lightningjs/sdk'
import { Grid } from '@lightningjs/ui'
import {
  Debugger,
  componentWithinContainerVertically,
  getClosestIndexByY,
  getCoordinateDimensions,
  isGoodArray,
  isGoodNumber,
  isGridPosition,
} from '../lib'

import isNumber from 'lodash-es/isNumber'

import { AdvancedWideCard } from './AdvancedGrid/AdvancedWideCard'

export interface VideoCardGridTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Grid: typeof Grid
  items: { content: ContentItem }[]
  columns: 3 | 4
  action: 'details' | 'player'
}
export interface VideoCardGridTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: LeftRightSignalMap
}
const debug = new Debugger('SG')
export class VideoCardGrid extends Lightning.Component<
  VideoCardGridTemplateSpec,
  VideoCardGridTypeConfig
> {
  Grid = this.getByRef('Grid')!
  static override _template(): Lightning.Component.Template<VideoCardGridTemplateSpec> {
    return {
      x: 0,
      y: 0,
      Grid: {
        w: 1080 - 160,
        h: 1920 - 160,
        spacing: 20,
        type: Grid,
        columns: 4,
        direction: 'column',
        itemType: AdvancedWideCard,
        signals: {
          onIndexChanged: '_indexChanged',
        },
      },
    }
  }
  override _construct() {
    this._handleMainMenuScrollButtons =
      this._handleMainMenuScrollButtons.bind(this)
  }
  public action: 'details' | 'player' = 'details'
  private _items: { content: ContentItem }[] = []
  set items(items: { content: ContentItem }[]) {
    this.tag('Grid').patch({
      items: items.map((item, idx) => ({
        ...item,
        signals: {
          hovered: () => {
            this._hoverIndex(idx)
          },
          contentSelected: (item: ContentItem) => {
            const destination = item.paths[this.action]
            if (destination) {
              this.fireAncestors('$navigate', item.paths[this.action])
            }
          },
        },
      })),
    })
    this._items = items
  }
  override _active() {
    this.stage.application.on(
      'mainMenuScrollButton',
      this._handleMainMenuScrollButtons
    )
  }
  override _inactive() {
    this.stage.application.off(
      'mainMenuScrollButton',
      this._handleMainMenuScrollButtons
    )
  }
  _handleMainMenuScrollButtons(direction: RemoteDirection) {
    if (direction === 'up') {
      this.Grid.up()
    } else if (direction === 'down') {
      this.Grid.down()
    }
  }

  _waiting: boolean = false

  _hoverIndex(idx: number) {
    debug.info('hover over idnex', idx)
    const hover = this.Grid.items[idx]
    debug.info('hover', hover)
    if (hover) {
      const within = componentWithinContainerVertically(this, hover)
      if (within) {
        this.Grid.setIndex(idx)
        this.signal('hovered', getCoordinateDimensions(hover))
      } else if (!this._waiting) {
        this.Grid.setIndex(idx)
        this.signal('hovered', getCoordinateDimensions(hover))
        this._waiting = true
        Registry.setTimeout(() => {
          debug.info('done waiting')
          this._waiting = false
        }, 300)
      }
    }
  }

  setClosestByY(side: 'right' | 'left', coords?: CoordinateDimensions) {
    debug.info('Setting closest by %s', side, coords)
    const columns = this.tag('Grid').columns
    const items = this.tag('Grid').items
    if (isGoodArray(items) && isGoodNumber(columns, true) && coords) {
      let idx = side === 'left' ? 0 : columns - 1
      const tests = []
      while (idx < items.length) {
        const test = items[idx]
        if (!test) {
          idx = items.length
        } else {
          tests.push({
            index: idx,
            item: test,
          })
        }
        idx += columns
      }
      const testIndex = getClosestIndexByY(
        coords,
        tests.map(t => t.item)
      )
      if (isGoodNumber(testIndex)) {
        const focus = tests[testIndex]
        if (focus) {
          debug.info('Setitng focus to %s', focus.index, focus.item)
          this.tag('Grid').setIndex(focus.index)
        }
      }
    }
  }

  override _init() {
    this.tag('Grid').patch({
      w: isNumber(this.w) ? this.w : 1080 - 160,
      h: isNumber(this.h) ? this.h : 1920 - 160,
    })
  }

  set columns(c: number) {
    this.tag('Grid').patch({
      columns: c,
    })
  }
  get columns(): number {
    return this.tag('Grid').columns as number
  }
  private _spacing: number | null = null
  set spacing(spacing: number) {
    this.tag('Grid').patch({
      spacing,
    })
    this._spacing = spacing
  }

  get spacing(): number {
    if (isGoodNumber(this._spacing)) return this._spacing
    const tag = this.tag('Grid')
    if (tag) {
      const { spacing, _mainSpacing = 20 } = this.tag('Grid')!
      return isGoodNumber(spacing)
        ? spacing
        : isGoodNumber(_mainSpacing)
          ? _mainSpacing
          : 20
    }
    return 20
  }
  private _edges: {
    left: boolean
    right: boolean
    top: boolean
    bottom: boolean
  } = { left: false, right: false, top: false, bottom: false }

  private _currentItem: ContentItem | null = null
  _indexChanged(args: GridPosition) {
    this._currentItemCheck()
    this._checkEdges(args)
  }

  override _handleEnter() {
    debug.info('Handle enter on video card grid')
    if (this._currentItem && this._currentItem.paths[this.action]) {
      this.fireAncestors('$navigate', this._currentItem.paths[this.action])
    }
  }

  _checkEdges(x?: GridPosition) {
    const position = x ?? this._getGridPositionFromIndex()
    if (isGridPosition(position)) {
      const columns = this.tag('Grid')!.columns
      const lastRowMaxColumn =
        position.mainIndex === position.lines - 1
          ? (position.dataLength % columns) - 1
          : columns - 1
      const edge: Partial<typeof this._edges> = {}
      edge.bottom = position.mainIndex === position.lines - 1
      edge.top = position.mainIndex === 0
      edge.left = position.crossIndex === 0
      edge.right = position.crossIndex === lastRowMaxColumn
      const newEdges = { ...this._edges, ...edge }
      this._edges = newEdges
    } else {
      debug.info('Invalid Grid Position', position)
    }
  }

  _getGridPositionFromIndex(
    index?: number
  ): Pick<GridPosition, 'mainIndex' | 'lines' | 'dataLength'> | null {
    index = isNumber(index) ? index : this.tag('Grid')!.index
    if (this.tag('Grid').items.length > 0) {
      const currentLocation = this.tag('Grid')!._findLocationOfIndex(
        index
      ) as Pick<GridPosition, 'crossIndex' | 'mainIndex'>
      const dataLength = this.tag('Grid').items.length
      const lines = Math.ceil(
        this.tag('Grid').items.length / this.tag('Grid')!.columns
      )
      return {
        ...currentLocation,
        lines,
        dataLength,
      }
    } else {
      return null
    }
  }
  private _currentItemCheck() {
    const index = this.tag('Grid')?.index
    debug.info('index Changed? %s', index)
    if (isGoodNumber(index, true)) {
      this._currentItem = this._items[index]?.content ?? null
    }
  }
  override _focus() {
    this._currentItemCheck()
    this._checkEdges()
  }

  override _handleLeft() {
    if (this._edges.left) {
      const current = this.tag('Grid').items[this.tag('Grid').index]
      const coords = current ? getCoordinateDimensions(current) : undefined
      return this.signal('left', coords)
    }
  }
  override _handleRight() {
    if (this._edges.right) {
      const current = this.tag('Grid').items[this.tag('Grid').index]
      const coords = current ? getCoordinateDimensions(current) : undefined
      return this.signal('right', coords)
    }
  }
  override _getFocused() {
    return this.tag('Grid')
  }
}

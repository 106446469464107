import { Colors, Lightning } from '@lightningjs/sdk'
import { AdvancedGridPageClicker } from './AdvancedGridPageClicker'
import { PointerState, RemoteDirection } from '@adiffengine/engine-types'
import { Debugger } from '../../../lib'
const debug = new Debugger('AdvancedGridColumnScrollButtons')

export interface AdvancedGridColumnScrollButtonsTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: {
    Background: object
    Buttons: {
      UpButton: typeof AdvancedGridPageClicker
      DownButton: typeof AdvancedGridPageClicker
    }
  }
}
export interface AdvancedGridColumnScrollButtonsTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: {
    up(): void
    down(): void
  }
}
export class AdvancedGridColumnScrollButtons
  extends Lightning.Component<
    AdvancedGridColumnScrollButtonsTemplateSpec,
    AdvancedGridColumnScrollButtonsTypeConfig
  >
  implements
    Lightning.Component
      .ImplementTemplateSpec<AdvancedGridColumnScrollButtonsTemplateSpec>
{
  static width = 360
  static height = 80
  Content = this.getByRef('Content')!
  Buttons = this.Content.getByRef('Buttons')!
  UpButton = this.Buttons.getByRef('UpButton')!
  DownButton = this.Buttons.getByRef('DownButton')!
  static override _template(): Lightning.Component.Template<AdvancedGridColumnScrollButtonsTemplateSpec> {
    return {
      Content: {
        alpha: 0.0001,
        x: 0,
        y: 0,
        w: 360,
        h: 80,
        Background: {
          x: 0,
          y: 0,
          w: 360,
          h: 80,
          rect: true,
          rtt: true,
          color: Colors('background').alpha(0.4).get(),
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: 20,
          },
        },
        Buttons: {
          x: 0,
          y: 15,
          flex: {
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          },
          w: 360,
          h: 50,
          UpButton: {
            type: AdvancedGridPageClicker,
            direction: 'up',
            flexItem: {
              marginRight: 24,
            },
            signals: {
              clicked: '_signalUp',
            },
          },
          DownButton: {
            flexItem: {
              marginLeft: 24,
            },
            type: AdvancedGridPageClicker,
            direction: 'down',
            signals: {
              clicked: '_signalDown',
            },
          },
        },
      },
    }
  }

  _signalUp() {
    this.signal('up')
  }
  _signalDown() {
    this.signal('down')
  }

  private _showAnimationInstance: Lightning.types.Animation | null = null
  get _showAnimation() {
    if (this._showAnimationInstance === null) {
      this._showAnimationInstance = this.animation({
        duration: 0.4,
        actions: [
          {
            t: 'Content',
            p: 'alpha',
            v: { 0: 0.0001, 1: 1 },
          },
        ],
      })
      this._showAnimation.on('start', () => {
        debug.info('Show Animation Start')
        this.UpButton.patch({
          disabled: false,
        })
        this.DownButton.patch({
          disabled: false,
        })
      })
      this._showAnimation.on('stop', () => {
        debug.info('Show Animation Stop')
        this.UpButton.patch({
          disabled: true,
        })
        this.DownButton.patch({
          disabled: true,
        })
      })
    }
    return this._showAnimationInstance
  }

  _pointerActive() {
    this._showAnimation.start()
  }
  _pointerInactive() {
    this._showAnimation.stop()
  }
  _handlePointerState(state: PointerState) {
    if (state === 'active') this._pointerActive()
    else this._pointerInactive()
  }
  override _construct() {
    this._handlePointerState = this._handlePointerState.bind(this)
  }
  override _active() {
    const pointerActive = this.fireAncestors('$pointerState')
    if (pointerActive) {
      this._pointerActive()
    }
    this.stage.application.on('pointerState', this._handlePointerState)
  }
  override _inactive() {
    this.stage.application.off('pointerState', this._handlePointerState)
  }
  setDisabled(
    direction: Omit<RemoteDirection, 'left' | 'right'>,
    disabled = true,
  ) {
    if (direction === 'up') {
      this.UpButton.patch({ disabled })
    }
    if (direction === 'down') {
      this.DownButton.patch({ disabled })
    }
  }
}

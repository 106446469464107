import {
  ContentItem,
  CoordinateDimensions,
  DirectionalSignalMap,
  ID,
  SetsByX,
  TheGridRowData,
  XListPosition,
} from '@adiffengine/engine-types'
import { Colors, Lightning, Registry, Settings } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import equal from 'fast-deep-equal/es6'
import isNumber from 'lodash-es/isNumber'
import {
  Debugger,
  componentWithinContainerHorizontally,
  expandDimensions,
  expandVertical,
  getClosestIndexByX,
  getCoordinateDimensions,
  getPatchFromCoordinates,
  hashCode,
  isFullyWithinContainer,
  isGoodArray,
  isGoodNumber,
  isScrollTransition,
  passSignal,
  withinViewport,
} from '../../lib'
import { MainMenu } from '../MainMenu'
import {
  AdvancedGridCardSetup,
  CardRenderConfig,
  GridComponent,
} from './AdvancedGridTypes'
import { AdvancedWideCard } from './AdvancedWideCard'
import { AdvancedGridMousePager } from './lib/AdvancedGridMousePager'

const debug = new Debugger('AdvancedGridList')
debug.tag('MoreTest')
export interface AdvancedGridListTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: {
    InnerContent: {
      Header: object
      Scroller: typeof AdvancedGridMousePager
      AdvancedGridListInnerList: typeof List
    }
  }

  focusable: boolean | null | undefined
  width: number
  cardSetup?: Partial<AdvancedGridCardSetup> | null
  content: TheGridRowData | null
  card?: GridComponent
  label?: string
  listId?: ID | null
  handleSelected: boolean
  listPosition?: XListPosition
}

export type MoreGridListPatch =
  Lightning.Element.PatchTemplate<AdvancedGridListTemplateSpec>

export interface AdvancedGridListSignals extends DirectionalSignalMap {
  reposition(): void
  contentSelected(item: ContentItem): void
  focusable(): boolean | void
}
export interface AdvancedGridListTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: AdvancedGridListSignals
}

export type AdvancedGridListComponent = Lightning.Component<
  AdvancedGridListTemplateSpec,
  AdvancedGridListTypeConfig
>
export class AdvancedGridList
  extends Lightning.Component<
    AdvancedGridListTemplateSpec,
    AdvancedGridListTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<AdvancedGridListTemplateSpec>,
    SetsByX
{
  public handleSelected: boolean = true
  private _card: GridComponent = AdvancedWideCard

  private _content: TheGridRowData | null = null
  private _width = 1920 - (MainMenu.widthClosed + 180)

  static getListId(content?: TheGridRowData | null): string | null {
    if (content && content.items.length > 0) {
      return hashCode(content.items.map(({ id }) => id).join(':'))
    }
    return null
  }
  static getRowHeight(
    w: number,
    data: Lightning.Element.PatchTemplate<AdvancedGridListTemplateSpec>
  ) {
    const cardHeight = this.getCardHeight(w, data.card, data.cardSetup)
    return cardHeight + 104
  }

  static getCardHeight(
    w: number,
    card?: GridComponent,
    setup?: Partial<AdvancedGridCardSetup> | null
  ) {
    card = card ?? AdvancedWideCard
    const dimensions = card.getDimensionsForWidth(w, setup)
    return dimensions.height
  }
  Content = this.getByRef('Content')!
  InnerContent = this.Content.getByRef('InnerContent')!
  AdvancedGridListInnerList = this.InnerContent.getByRef(
    'AdvancedGridListInnerList'
  )!
  Header = this.InnerContent.getByRef('Header')!
  Scroller = this.InnerContent.getByRef('Scroller')!

  static override _template(): Lightning.Component.Template<AdvancedGridListTemplateSpec> {
    return {
      w: 1920 - (MainMenu.widthClosed + 180),
      h: AdvancedWideCard.height + 64,
      Content: {
        x: -20,
        y: 0,
        rtt: true,
        rect: true,
        color: 0x00000000,
        shader: {
          type: Lightning.shaders.FadeOut,
          left: 20,
          right: 20,
        },
        InnerContent: {
          x: 20,
          y: 0,

          Header: {
            x: 20,
            y: 0,
            h: 40,
            // rect: true,
            text: {
              maxLines: 1,
              wordWrapWidth: 400,
              text: '',
              fontFace: 'Bold',
              fontSize: 36,
            },
          },
          AdvancedGridListInnerList: {
            x: 0,
            y: 64,
            w: (w: number) => w,
            h: (h: number) => h - 64,
            type: List,
            direction: 'row',
            itemType: AdvancedWideCard,
            spacing: 0,
          },
          Scroller: {
            visible: false,
            type: AdvancedGridMousePager,
            y: 0,
            w: 1920 - (MainMenu.widthClosed + 840),
            h: 40,
            signals: {
              pageForward: '_pageForward',
              pageBack: '_pageBack',
              hovered: '_pagerHover',
            },
          },
        },
      },
    }
  }
  public focusable: boolean = true

  override _construct() {
    this._resetScrollDuration = this._resetScrollDuration.bind(this)
    this._watchTargetScroll = this._watchTargetScroll.bind(this)
    this._up = this._up.bind(this)
    this._down = this._down.bind(this)
    this._right = this._right.bind(this)
    this._left = this._left.bind(this)
  }

  _up(coords?: CoordinateDimensions | null) {
    return passSignal(this.signal('up', coords))
  }

  _down(coords?: CoordinateDimensions | null) {
    const out = passSignal(this.signal('down', coords), false)
    return out
  }
  _right(coords?: CoordinateDimensions | null) {
    if (
      this.AdvancedGridListInnerList.index ===
      this.AdvancedGridListInnerList.items.length - 1
    ) {
      this.signal('right', coords)
    }
  }

  _left(coords?: CoordinateDimensions | null) {
    if (this.AdvancedGridListInnerList.index === 0) {
      this.signal('left', coords)
    }
  }

  set card(card: GridComponent) {
    if (this._card !== card) {
      this._card = card
      this._cachedCardConfig = null
      if (this.enabled) this.renderList()
    }
  }

  get card() {
    return this._card
  }

  private _cardSetup: Partial<AdvancedGridCardSetup> | null = null
  set cardSetup(setup: Partial<AdvancedGridCardSetup> | null) {
    const update = { ...this.cardSetup, ...setup }
    if (!equal(update, this._cardSetup)) {
      this._cardSetup = update
      this._cachedCardConfig = null
      if (this.enabled) this.renderList()
    }
  }
  get cardSetup(): AdvancedGridCardSetup {
    return this._cardSetup
      ? { ...this.card.cardSetup, ...this._cardSetup }
      : this.card.cardSetup
  }

  set width(w: number) {
    if (isNumber(w) && w !== this._width) {
      this._width = w
      this.patch({ w })
    } else if (!isNumber(w)) {
      console.warn('Got invalid width', w)
    }
  }

  get width() {
    return this._width
  }

  set content(items: TheGridRowData | null) {
    debug.info('Got Content', items, this._content)
    if (!equal(items, this._content)) {
      debug.info('Rendering new content? Enabled %s', this.enabled, items)
      this._content = items
      if (this.enabled) this.renderList()
      this.currentPages = isGoodArray(this._content?.items)
        ? Math.ceil(this._content!.items.length / this.cardSetup.cardsPerRow)
        : 0
    }
  }

  get content() {
    return this._content
  }

  set label(text: string) {
    this.Header.patch({
      text: { text },
    })
  }

  renderList() {
    debug.info('Rendering List', this.content, this.visible)
    if (this.content && this.content.items.length > 0) {
      const current = getCoordinateDimensions(this)
      const outerExpansion = 40
      const outerWrapperPatch = expandDimensions(
        { w: current.width, h: current.height, x: 0, y: 0 },
        outerExpansion
      )
      const innerPatch = expandVertical(
        getPatchFromCoordinates({
          ...current,
          x: outerExpansion,
          y: 0,
        }),
        0
      )

      this.Content.patch({ ...outerWrapperPatch })

      const patch: Lightning.Element.PatchTemplate = {
        visible: true,
        color: Colors('primaryHighlight').alpha(0.4).get(),
        ...innerPatch,
        h: outerWrapperPatch.h,
        Header: {
          text: {
            text: this.content.title.toUpperCase(),
            wordWrapWidth: this.w / 2 - 40,
          },
        },
        Scroller: {
          x:
            this.cardConfig.width * this.cardSetup.cardsPerRow -
            AdvancedGridMousePager.width -
            100,
          mountX: 0,
          progressWidth: this.cardSetup.cardsPerRow / this.content.items.length,
          page: {
            current: this._currentPage,
            total: Math.ceil(
              this.content.items.length / this.cardSetup.cardsPerRow
            ),
          },
        },

        AdvancedGridListInnerList: {
          x: 0,
          y: 64,
          h: this.cardConfig.height,
          w: innerPatch.w,
          itemType: this.card,
          items: this.content.items.map((content, idx) => ({
            content,
            w: this.cardConfig.width,
            h: this.cardConfig.height,
            cardConfig: this.cardConfig,
            signals: {
              hovered: (hovered: Lightning.Element) => {
                if (
                  componentWithinContainerHorizontally(
                    this.AdvancedGridListInnerList,
                    hovered
                  )
                ) {
                  this.setIndexOnHover(idx)
                }
              },
              up: this._up,
              down: this._down,
              left: this._left,
              right: this._right,
            },
          })),
        },
      }
      this.InnerContent.patch(patch)
      this.signal('reposition')
      debug.info('Rendered List', this.visible)
    }
  }

  _pagerHover() {
    const viewport = withinViewport(this, 0.5)
    if (viewport.precise.withinY) {
      this.signal('hovered')
    }
  }
  setIndexOnHover(index: number) {
    this.AdvancedGridListInnerList.setIndex(index)
    this.signal('hovered')
  }

  private _cachedCardConfig: CardRenderConfig | null = null

  get cardConfig() {
    if (this._cachedCardConfig === null) {
      const current = getCoordinateDimensions(this)
      this._cachedCardConfig = this.card.getDimensionsForWidth(
        current.width,
        this.cardSetup
      )
    }
    return this._cachedCardConfig
  }

  _pageForward() {
    if (this.content) {
      const maxOffset = this.content.items.length - this.cardSetup.cardsPerRow
      if (this.currentListOffset >= maxOffset) return
      let offset = this.currentListOffset + this.cardSetup.cardsPerRow - 1
      if (offset > maxOffset) {
        this.AdvancedGridListInnerList.setIndex(
          this.AdvancedGridListInnerList.index + 1
        )
      } else {
        const index =
          this.AdvancedGridListInnerList.index +
          (offset - this.currentListOffset)
        this.scrollTo(index, offset)
      }
    }
  }

  _pageBack() {
    if (this.content) {
      let offset = this.currentListOffset - this.cardSetup.cardsPerRow + 1
      if (offset < 0) {
        this.AdvancedGridListInnerList.setIndex(
          this.AdvancedGridListInnerList.index - 1
        )
      } else {
        const index =
          this.AdvancedGridListInnerList.index +
          (offset - this.currentListOffset)
        this.scrollTo(index, offset)
      }
    }
  }
  private _mouseIsEnabled = false
  override _init() {
    this._mouseIsEnabled = Settings.get('app', 'enablePointer', false)
    this.Scroller.patch({ visible: this._mouseIsEnabled })
    const theme = this.fireAncestors('$theme')
    this.AdvancedGridListInnerList.scrollTransition.on(
      'finish',
      this._resetScrollDuration.bind(this)
    )
    this.AdvancedGridListInnerList.scrollTransition.on('start'), () => {}
    if (theme) {
      this.Header.patch({
        color: Colors(theme.palette.text).get(),
      })
    }
  }

  override _getFocused() {
    return this._scrolling ? null : this.AdvancedGridListInnerList
  }
  override _captureLeft() {
    if (this.AdvancedGridListInnerList.index === 0) {
      return this.signal('left')
    } else {
      return false
    }
  }
  override _captureRight() {
    if (
      this.AdvancedGridListInnerList.index ===
      this.AdvancedGridListInnerList.items.length - 1
    ) {
      return this.signal('right')
    } else {
      return false
    }
  }

  private _listId: string | null = null
  set listId(listId: string | null) {
    this._listId = listId
  }
  get listId(): string | null {
    return this._listId ?? AdvancedGridList.getListId(this.content)
  }

  setClosestByX(coords: CoordinateDimensions | null) {
    if (coords) {
      const index = getClosestIndexByX(
        coords,
        this.AdvancedGridListInnerList.items ?? []
      )
      if (isGoodNumber(index, true))
        this.AdvancedGridListInnerList.setIndex(index)
    }
  }

  _resetScrollDuration() {
    const { scrollTransition } = this.AdvancedGridListInnerList
    if (scrollTransition) {
      if (scrollTransition.settings.duration === 0) {
        scrollTransition.settings.duration = 0.2
      }
    }
  }
  private _scrolling = false
  _unfocusWhileScrolling() {
    this._scrolling = true
    this._refocus()
    Registry.setTimeout(() => {
      this._scrolling = false
      this._refocus()
    }, 300)
  }

  scrollTo(index: number, offset: number) {
    if (this.content) {
      this._unfocusWhileScrolling()
      const { scrollTransition } = this.AdvancedGridListInnerList
      let position = -this.cardConfig.width * offset
      if (Math.abs(position) > 0) position += this.cardConfig.spacing
      if (scrollTransition.isRunning()) {
        scrollTransition.updateTargetValue(position)
      } else {
        scrollTransition.start(position)
      }
      this.AdvancedGridListInnerList.setIndex(index)
    }
  }
  private _listPosition: XListPosition | null = null

  set listPosition(position: XListPosition) {
    if (position && this.active) {
      const { scrollTransition } = this.AdvancedGridListInnerList
      if (scrollTransition.element.x !== position.xOffset) {
        scrollTransition.element.setSmooth('x', position.xOffset, {
          duration: 0,
        })
      }
      this.AdvancedGridListInnerList.setIndex(position.index)
    } else {
      this._listPosition = position
    }
  }

  get listPosition(): XListPosition {
    const {
      index,
      scrollTransition: { targetValue },
    } = this.AdvancedGridListInnerList

    const out: XListPosition = {
      index,
      xOffset: targetValue,
    }
    return out
  }

  $contentSelected(item: ContentItem) {
    this.fireAncestors('$contentSelected', item)
  }

  currentFocusCoordinates(): CoordinateDimensions | null {
    const focused =
      this.AdvancedGridListInnerList.items[this.AdvancedGridListInnerList.index]
    if (focused) {
      return getCoordinateDimensions(focused)
    }
    return null
  }
  override _enable() {
    this.renderList()
  }

  private _currentListOffset: number = 0
  public get currentListOffset(): number {
    return this._currentListOffset
  }
  public set currentListOffset(value: number) {
    if (value !== this._currentListOffset) {
      this._currentListOffset = value
      const page =
        Math.ceil(this._currentListOffset / this.cardSetup.cardsPerRow) + 1
      debug.info('Setting page to %s', page)
      this.currentPage = page
    }
  }
  private _currentPage: number = 1

  public get currentPage(): number {
    return this._currentPage
  }
  public set currentPage(value: number) {
    if (this._currentPage !== value) {
      this._currentPage = value
      this._patchPages()
    }
  }
  private _currentPages: number = 1
  public get currentPages(): number {
    return this._currentPages
  }
  public set currentPages(value: number) {
    if (this._currentPage !== value) {
      this._currentPages = value
      this._patchPages()
    }
  }
  private _patchPages() {
    this.Scroller.patch({
      page: {
        current: this._currentPage,
        total: this._currentPages,
      },
    })
  }
  testTargetValue(targetValue: number) {
    const mod =
      Math.round(Math.abs(targetValue)) % Math.round(this.cardConfig.width)
    if (mod !== 0) {
      const diff = Math.abs(
        Math.abs(targetValue) - Math.round(this.cardConfig.width)
      )
      if (diff > 3) {
        console.warn(
          'seem to be scrolling somewhere strange %s',
          Math.round(targetValue),
          Math.round(this.cardConfig.width),
          Math.round(targetValue) % Math.round(this.cardConfig.width)
        )
      }
    }
  }
  private _watchTargetScroll() {
    const {
      scrollTransition: { targetValue },
    } = this.AdvancedGridListInnerList

    this.currentListOffset = Math.ceil(
      Math.abs(Math.round(targetValue)) / Math.round(this.cardConfig.width)
    )
    debug.info('Watch target scroll', targetValue, this.currentListOffset)
  }

  _cardFullyInWindow(component?: Lightning.Component) {
    if (component) {
      const coords = getCoordinateDimensions(component)
      const listCoords = getCoordinateDimensions(this.AdvancedGridListInnerList)
      if (coords && listCoords) {
        return isFullyWithinContainer(listCoords, coords)
      }
    }
    return true
  }

  override _active() {
    if (this._listPosition) {
      this.listPosition = this._listPosition
      this._listPosition = null
    }
    const { scrollTransition = null } = this.AdvancedGridListInnerList
    if (isScrollTransition(scrollTransition)) {
      scrollTransition.on('finish', this._watchTargetScroll)
    }
  }
  override _inactive() {
    const { scrollTransition = null } = this.AdvancedGridListInnerList
    if (isScrollTransition(scrollTransition)) {
      scrollTransition.off('finish', this._watchTargetScroll)
    }
  }

  override _unfocus() {
    if (this.listId) {
      this.fireAncestors(
        '$cacheAdvancedGridPosition',
        this.listId,
        this.listPosition
      )
    }
  }
}

import { Storage } from '@lightningjs/sdk'
import isDate from 'lodash-es/isDate'
import isString from 'lodash-es/isString'

export interface StoragePayload<T = any> {
  expires: Date
  data: T
}
export function isStoragePayload<T>(x: any): x is StoragePayload<T> {
  return x && isDate(x.expires) && x.data
}

export class TVCache {
  private _name: string
  constructor(name?: string) {
    this._name = isString(name) ? name : 'adetvcache'
  }
  async get<T>(key: string): Promise<T | null> {
    try {
      let payload = Storage.get(key)
      if (isString(payload)) {
        payload = JSON.parse(payload)
        if (isString(payload.expires))
          payload.expires = new Date(payload.expires)
      }
      if (!payload || !isStoragePayload(payload)) {
        return null
      }
      const { expires, data } = payload
      if (isDate(expires) && expires.getTime() < new Date().getTime()) {
        await Storage.remove(key)
        return null
      } else {
        return data as T
      }
    } catch (e) {
      const error = e as Error
      console.error('error parsing cache JSON %s', error.message)
      return null
    }
  }
  private getKey(key: string) {
    return `${this._name}:${key}`
  }

  async set<T>(key: string, data: T, { expires }: { expires?: Date | null }) {
    key = this.getKey(key)
    try {
      expires = isDate(expires) ? expires : null
      await Storage.set(key, { expires, data })
      return data
    } catch (e) {
      const error = e as Error
      console.error('Error saving cache %s', error.message)
      return null
    }
  }
}

export class LocalCache<T> {
  private _cache: Record<string, T> = {}
  private _orderedKeys: string[] = []
  public maxItems = 50
  set(lookup: string, item: T) {
    if (lookup.trim().length === 0) {
      console.warn('Not saving to lookup with empty key', item)
      return
    }
    this._cache[lookup] = item
    this._orderKeysByAccess(lookup)
    if (this._orderedKeys.length > this.maxItems) {
      const killit = this._orderedKeys.shift()
      if (killit) delete this._cache[killit]
    }
  }

  private _orderKeysByAccess(lookup: string) {
    // remove if it exists, push to the end.
    // will keep more used items from being killed first.
    this._orderedKeys = this._orderedKeys.filter(k => k === lookup)
    this._orderedKeys.push(lookup)
  }
  get(lookup: string): T | null {
    const item = this._cache[lookup]
    this._orderKeysByAccess(lookup)
    return item ?? null
  }
}

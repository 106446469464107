import { Colors, Lightning } from '@lightningjs/sdk'
import {
  Debugger,
  cp,
  getCoordinateDimensions,
  registerHoverable,
} from '../../../lib'
import { DirectionalSignalMap } from '@adiffengine/engine-types'
//@ts-ignore
const debug = new Debugger('AdvancedGridScrollGrabber')

export interface AdvancedGridScrollGrabberTemplateSpec
  extends Lightning.Component.TemplateSpec {
  direction: 'row' | 'column'
  Background: object
  Strokes: {
    First: object
    Second: object
    Third: object
  }
}

const GRABBER_WIDTH = 80
const GRABBER_HEIGHT = 18

export interface AdvancedGridScrollGrabberTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: DirectionalSignalMap
}

export class AdvancedGridScrollGrabber
  extends Lightning.Component<
    AdvancedGridScrollGrabberTemplateSpec,
    AdvancedGridScrollGrabberTypeConfig
  >
  implements
    Lightning.Component
      .ImplementTemplateSpec<AdvancedGridScrollGrabberTemplateSpec>
{
  static rowMerge(): Lightning.Element.PatchTemplate<AdvancedGridScrollGrabberTemplateSpec> {
    const StrokeConfig = {
      h: 12,
      w: 4,
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        fillColor: Colors('buttonText').darker(0.4).get(),
        radius: 2,
      },
    }
    return {
      w: GRABBER_WIDTH,
      h: 18,
      Background: {
        w: GRABBER_WIDTH,
        h: GRABBER_HEIGHT,
      },
      Strokes: {
        w: GRABBER_WIDTH,
        h: GRABBER_HEIGHT,
        flex: {
          direction: 'row',
        },
        First: StrokeConfig,
        Second: {
          ...StrokeConfig,
          flexItem: {
            marginRight: 4,
            marginLeft: 4,
          },
        },
        Third: StrokeConfig,
      },
    }
  }
  static columnMerge(): Lightning.Element.PatchTemplate<AdvancedGridScrollGrabberTemplateSpec> {
    const StrokeConfig = {
      h: 4,
      w: 12,
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        fillColor: Colors('buttonText').darker(0.4).get(),
        radius: 2,
      },
    }
    return {
      h: GRABBER_WIDTH,
      w: 18,
      Background: {
        h: GRABBER_WIDTH,
        w: GRABBER_HEIGHT,
      },
      Strokes: {
        h: GRABBER_WIDTH,
        w: GRABBER_HEIGHT,
        flex: {
          direction: 'column',
        },
        First: StrokeConfig,
        Second: {
          flexItem: {
            marginTop: 4,
            marginBottom: 4,
          },
        },
        Third: StrokeConfig,
      },
    }
  }
  static override _template(): Lightning.Component.Template<AdvancedGridScrollGrabberTemplateSpec> {
    const StrokeConfig = {
      h: 12,
      w: 4,
      rect: true,
      rtt: true,
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        fillColor: Colors('buttonBackground').get(),
        radius: 2,
      },
    }
    return {
      collision: true,
      h: 18,
      w: 80,
      Background: {
        rect: true,
        rtt: true,
        w: cp,
        h: cp,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 9,
          fillColor: Colors('buttonText').get(),
        },
      },
      Strokes: {
        w: cp,
        h: cp,
        flex: {
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        },
        First: StrokeConfig,
        Second: {
          ...StrokeConfig,
          flexItem: {
            marginRight: 4,
            marginLeft: 4,
          },
        },
        Third: StrokeConfig,
      },
    }
  }
  private _animation: Lightning.types.Animation | null = null
  get _focusAnimation() {
    if (this._animation === null) {
      this._animation = this.animation({
        duration: 0.3,
        actions: [
          {
            p: 'scale',
            v: { 0: 1, 1: 1.05 },
          },

          {
            t: 'Background',
            // @ts-ignore
            p: 'shader.fillColor',
            v: {
              0: Colors('buttonText').get(),
              1: Colors('primaryHighlight').get(),
            },
          },
        ],
      })
    }
    return this._animation
  }
  override _init() {
    registerHoverable('AdvancedGridScrollGrabber', this)
  }
  override _handleHover() {
    this._focusAnimation.start()
    this.signal('hovered', getCoordinateDimensions(this))
  }
  _handleUnhover() {
    this._focusAnimation.stop()
    this.signal('unhovered', getCoordinateDimensions(this))
  }

  private _direction: 'row' | 'column' = 'column'
  set direction(direction: 'row' | 'column') {
    if (this._direction !== direction) {
      this._direction = direction
      this.patch(
        direction === 'row'
          ? AdvancedGridScrollGrabber.rowMerge()
          : AdvancedGridScrollGrabber.columnMerge(),
      )
    }
  }
  get direction() {
    return this._direction
  }
}

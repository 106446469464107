import { Lightning } from '@lightningjs/sdk'
export interface HorizontalSearchTemplateSpec
  extends Lightning.Component.TemplateSpec {}

export class HorizontalSearch
  extends Lightning.Component<HorizontalSearchTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<HorizontalSearchTemplateSpec>
{
  static override _template(): Lightning.Component.Template<HorizontalSearchTemplateSpec> {
    return {
      x: 0,
      y: 0,
      h: 1080,
      w: 1920,
    }
  }
}

import {
  ContentItem,
  ContentSelectedDirectionalSignalMap,
} from '@adiffengine/engine-types'
import { Lightning, Utils } from '@lightningjs/sdk'
import equal from 'fast-deep-equal/es6'
import { Debugger } from '../lib'
import { AdeBoxArtButton } from './AdeBoxArtButton'

const debug = new Debugger('ContentBoxArtButton')
export interface ContentBoxArtButtonTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Button: typeof AdeBoxArtButton
  content: ContentItem
}

export interface ContentBoxArtButtonTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: ContentSelectedDirectionalSignalMap
}
export class ContentBoxArtButton
  extends Lightning.Component<
    ContentBoxArtButtonTemplateSpec,
    ContentBoxArtButtonTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<ContentBoxArtButtonTemplateSpec>
{
  static height = AdeBoxArtButton.height
  static width = AdeBoxArtButton.width
  Button = this.getByRef('Button')!
  static override _template(): Lightning.Component.Template<ContentBoxArtButtonTemplateSpec> {
    return {
      x: 0,
      y: 0,
      h: AdeBoxArtButton.height,
      w: AdeBoxArtButton.width,
      Button: {
        type: AdeBoxArtButton,
        h: AdeBoxArtButton.height,
        w: AdeBoxArtButton.width,
        passSignals: {
          up: true,
          down: true,
          left: true,
          right: true,
        },
      },
    }
  }
  private _content: ContentItem | undefined
  set content(item: ContentItem) {
    if (!equal(this._content, item)) {
      this._content = item
      const imageSet =
        item.images.box ??
        item.images.thumb ??
        item.images.wallpaper ??
        item.images.wide
      const url = imageSet
        ? imageSet.getForWidth(AdeBoxArtButton.width)
        : undefined
      const imageSrc = url ? Utils.proxyUrl(url) : undefined
      this.Button.patch({
        h: this.h,
        w: this.w,
        title: this.content.title,
        imageSrc,
      })
    }
  }
  get content() {
    return this._content!
  }

  override _focus() {
    if (this.content) {
      debug.info('Content Focused', this, this.content)
      this.signal('contentFocused', this.content)
    }
  }

  override _handleEnter() {
    if (this.content) this.signal('contentSelected', this.content)
  }

  override _getFocused() {
    return this.Button
  }
}

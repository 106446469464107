import {
  ContentItem,
  DirectionalSignalMap,
  GridResponse,
} from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import equal from 'fast-deep-equal/es6'

import { MainMenu } from '../components/MainMenu'
import { Debugger, registerHoverable } from '../lib'
import {
  AdeHero,
  AdvancedBoxCard,
  AdvancedGrid,
  AdvancedGridList,
  AdvancedGridTemplateSpec,
  AdvancedWideCard,
} from '../components'
// @ts-ignore
const debug = new Debugger('GridHomeContent')
type NavigationPath = keyof ContentItem['paths']
const navPaths: NavigationPath[] = ['details', 'player']
export type GridCardType = 'wide' | 'box' | null

export interface GridHomeContentTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: DirectionalSignalMap
}
export interface GridHomeContentTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Grid: typeof AdvancedGrid
  Test: object
  navigationPath: NavigationPath
  content: GridResponse
  card: GridCardType
}

export class GridHomeContent
  extends Lightning.Component<
    GridHomeContentTemplateSpec,
    GridHomeContentTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<GridHomeContentTemplateSpec>
{
  public IsPage = true

  Grid = this.getByRef('Grid')!
  override get id() {
    return 'GridHomeContent'
  }

  public navigationPath: 'details' | 'player' = 'details'

  static override _template() {
    return {
      w: 1920 - (MainMenu.widthClosed + 100), // 1920 - 96 - 180
      h: 1080,
      shader: {
        type: Lightning.shaders.FadeOut,
        top: 20,
        bottom: 20,
      },
      Grid: {
        type: AdvancedGrid,
        x: 20,
        y: 80,
        spacing: 40,
        w: 1920 - (MainMenu.widthClosed + 260),
        h: 1080 - (AdvancedGrid.bottomMargin + 80),
        passSignals: {
          left: true,
        },
      },
    }
  }

  override _init() {
    debug.info('Init')
    registerHoverable('GridHomeContent', this)
  }

  private _content: GridResponse = {
    requestId: 'none',
    grid: [],
    context: { params: { type: 'all' } },
  }

  set content(grid: GridResponse) {
    debug.info('Content')
    if (!equal(grid, this._content)) {
      this._content = grid
      this.renderContent()
    }
  }
  get content() {
    return this._content
  }

  renderContent() {
    const items: Lightning.Element.PatchTemplate[] = this.content.grid
      .filter(content => content.items.length > 0)
      .map(content => {
        const type = content.items[0]!.type
        const switcher =
          type !== 'movie' || this.card === 'wide'
            ? {
                cardSetup: {
                  spacing: 40,
                  cardsPerRow: 5,
                },
                h: AdvancedGridList.getCardHeight(this.w, AdvancedWideCard),
                card: AdvancedWideCard,
              }
            : {
                cardSetup: {
                  spacing: 20,
                  cardsPerRow: 7,
                },
                h: AdvancedGridList.getCardHeight(this.w, AdvancedBoxCard),
                card: AdvancedBoxCard,
              }
        return this.Grid.gridItem({
          listId: AdvancedGridList.getListId(content),
          content,
          type: AdvancedGridList,
          ...switcher,
          w: this.w,
        })
      })
    if (this.content.heros) {
      items.unshift(
        this.Grid.gridItem({
          type: AdeHero,
          heros: this.content.heros.items,
          w: this.w,
          h: 440,
          passSignals: {
            left: true,
          },
        })
      )
    }

    const patch: Lightning.Element.PatchTemplate<AdvancedGridTemplateSpec> = {
      requestId: this.content.requestId,
      w: this.w - 40,
      items,
    }
    this.patch({ visible: true })
    this.Grid.patch(patch)
  }

  getValidPath(paths: ContentItem['paths']): string | null {
    let destination: string | null = paths[this.navigationPath] ?? null
    if (destination) return destination
    destination = navPaths.reduce(
      (acc, path) => {
        if (acc) return acc
        return paths[path] ?? null
      },
      null as string | null
    )
    return destination
  }

  override _getFocused(): Lightning.Component | undefined {
    debug.info('_getFocused')
    return this.Grid
  }

  private _card: GridCardType = null
  public set card(card: GridCardType) {
    if (card !== this._card) {
      this._card = card
    }
  }
  public get card() {
    return this._card
  }
  override _active() {
    debug.info('Active')
    this._refocus()
  }
  override _focus() {
    debug.info('Focus')
  }
}

import { User } from '@adiffengine/engine-types'
import { Colors, Lightning, Registry, Utils } from '@lightningjs/sdk'
import { capitalCase } from 'change-case'
import { format } from 'fecha'
import defer from 'lodash-es/defer'
import { Debugger } from '../lib'
import { AvatarList } from './AvatarList'

const debug = new Debugger('Welcome')
export interface WelcomeTemplateSpec extends Lightning.Component.TemplateSpec {
  WelcomeText: object
  Bar: object
  Time: object
  AvatarList: typeof AvatarList
  name: string
}
export const INNER_PADDING = 0
export const PART_1_FORMAT = 'ddd, MM Do hh:mm:ss'
export const PART_3_FORMAT = 'ss'
export interface WelcomeTypeConfig extends Lightning.Component.TypeConfig {}

const peeps: User[] = [
  {
    id: 'mike',
    avatar: 'images/users/mike.png',
    name: 'mike',
  },
  {
    id: 'april',
    avatar: 'images/users/april.png',
    name: 'april',
  },
  {
    id: 'kennedy',
    avatar: 'images/users/kennedy.png',
    name: 'kennedy',
  },
]

export const DATE_FORMAT_STRING = 'dddd, MMMM Do hh:mm:ss'

function dateString() {
  return `It's ${format(new Date(), 'dddd, MMMM Do hh:mm A')}`
}
export class Welcome
  extends Lightning.Component<WelcomeTemplateSpec, WelcomeTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<WelcomeTemplateSpec>
{
  WelcomeText = this.getByRef('WelcomeText')!
  Bar = this.getByRef('Bar')!
  Time = this.getByRef('Time')!
  AvatarList = this.getByRef('AvatarList')!
  static override _template(): Lightning.Component.Template<WelcomeTemplateSpec> {
    return {
      x: 0,
      y: 0,
      h: 1080,
      w: 1920,
      color: Colors('background').get(),
      rtt: true,
      rect: true,
      AvatarList: {
        direction: 'column',
        w: 80,
        h: 1080 - 160,
        x: 1920 - 160,
        y: 80,
        mountX: 1,
        type: AvatarList,
        spacing: -4,
      },
      WelcomeText: {
        alpha: 0.0001,
        x: INNER_PADDING * 2,
        y: INNER_PADDING,
        color: Colors('text').get(),
        text: {
          text: '',
          fontSize: 64,
          fontFace: 'Text',
        },
      },
      Bar: {
        x: INNER_PADDING * 2,
        y: INNER_PADDING + 120,
        w: 10,
        h: 10,
        rect: true,
        rtt: true,
        color: Colors('text').get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 5,
        },
      },
      Time: {
        x: INNER_PADDING * 2,
        y: INNER_PADDING + 80,
        h: 80,
        color: Colors('text').get(),
        renderOffscreen: true,
        text: {
          text: '',
          fontFace: 'Text',
          fontSize: 24,
          textAlign: 'left',
        },
      },
    }
  }
  private _currentStep = -1
  private _steps = ['AvatarOne', 'AvatarTwo', 'AvatarThree']
  private _arrived = false

  arrived() {
    if (!this._arrived) {
      this.WelcomeText.patch({
        smooth: {
          alpha: [1, { duration: 1 }],
        },
      })
    }
  }

  private _name: string = ''
  set name(text: string) {
    if (this._name !== text) {
      this._name = text
      this.WelcomeText.patch({
        text: {
          text: `Hi ${capitalCase(text)}!`,
        },
      })
      this.WelcomeText.on('txLoaded', () => {
        debug.info('Welcome Text Loaded')
        this.dateString = dateString()
      })
      this.Time.on('txLoaded', () => {
        defer(() => {
          debug.info('Time Loaded and deferred... %s', this.Time.finalW)
          if (this.Bar.w !== this.Time.finalW) {
            this.bar = this.Time.finalW
          }
        })
      })
    }
  }
  get name() {
    return this._name
  }
  private _bar: number = 10
  set bar(width: number) {
    if (this._bar !== width) {
      debug.info('Patching Bar to width', width)
      this._bar = width
      this.Bar.patch({
        smooth: { w: [width + 50, { duration: 0.5, delay: 1 }] },
      })
    }
  }
  get bar() {
    return this._bar
  }

  private _dateString: string = ''
  private set dateString(text: string) {
    debug.info('Setting datestring current %s new %s', this.dateString, text)
    if (this._dateString !== text) {
      this._dateString = text
      this.Time.patch({
        text: { text },
      })
    }
  }
  private get dateString() {
    return this._dateString
  }
  tick() {
    this.dateString = dateString()
  }
  private _interval: ReturnType<typeof Registry.setInterval> | null = null
  override _active() {
    this.clearInterval()
    this.fireAncestors('$screenSaver', false)
    this._interval = Registry.setInterval(this.tick, 60 * 1000)
    this.arrived()
  }
  override _inactive() {
    this.clearInterval()
  }
  clearInterval() {
    if (this._interval !== null) {
      Registry.clearInterval(this._interval)
      this._interval = null
    }
  }
  override _construct() {
    this.tick = this.tick.bind(this)
    this.nextStep = this.nextStep.bind(this)
  }

  override _captureKey(e: KeyboardEvent): boolean | void {
    debug.info('Got key?', e)
    defer(() => {
      debug.info('Next Step Called')
      this.nextStep()
    })
  }

  nextStep() {
    if (this._steps[this._currentStep + 1]) {
      this._currentStep++
      debug.info('Settings state to %s', this._steps[this._currentStep])
      this._setState(this._steps[this._currentStep])
    }
  }

  get users(): User[] {
    return peeps.map(({ avatar, ...p }) => ({
      avatar: Utils.asset(avatar),
      ...p,
    }))
  }

  static override _states(): Lightning.Component.Constructor<
    Lightning.Component<WelcomeTemplateSpec, WelcomeTypeConfig>
  >[] {
    return [
      class AvatarOne extends this {
        override $enter() {
          this.AvatarList.patch({
            users: this.users.slice(0, 1),
          })
          debug.info('Entering Avatar List', this.AvatarList)
        }
      },
      class AvatarTwo extends this {
        override $enter() {
          this.AvatarList.patch({
            users: this.users.slice(0, 2),
          })

          debug.info('Entering Avatar List 2', this.AvatarList)
        }
      },
      class AvatarThree extends this {
        override $enter() {
          this.AvatarList.patch({
            users: this.users,
          })
          debug.info('Entering Avatar List 2', this.AvatarList)
        }
      },
    ]
  }
}

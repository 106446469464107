/* eslint  @typescript-eslint/no-explicit-any: 0 */
import {
  CoordinateDimensions,
  Coords,
  HorizontalNavSignalMap,
  ID,
  isID,
  ListIndexChange,
  ListPosition,
} from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import isEqual from 'fast-deep-equal/es6'
import isFunction from 'lodash-es/isFunction'
import isNumber from 'lodash-es/isNumber'
import {
  getClosestIndexByX,
  getCoordinateDimensions,
  getCoords,
  isGoodNumber,
  isSavedPosition,
} from './lightning-tools'

// const debug = new Debugger('FullPageModal')

export function makeClosestFocusable<T extends ClosestFocusable>(Base: T): any {
  // @ts-ignore - this seems to be a bug https://github.com/microsoft/TypeScript/issues/37142
  return class RowFocusable extends Base {
    constructor(
      stage: Lightning.Stage,
      properties?: Lightning.Component.TemplateSpecLoose,
    ) {
      super(stage, properties)
    }
    _status: 'offlist' | 'left' | 'right' | 'list' = 'offlist'
    set navStatus(x: typeof this._status) {
      this._status = x
    }
    get navStatus() {
      return this._status
    }

    checkListCoords() {
      const coords = getCoords(this.list())
      if (!isEqual(coords, this.__lastListCoords)) {
        this.recheckWindowSetup()
      }
    }

    recheckWindowSetup() {
      this.__currentWindow = this.__currentWindowIndex
    }

    override _captureUp(e: KeyboardEvent) {
      this.checkListCoords()
      if (super._captureUp) return super._captureUp(e)
      else return false
    }

    override _captureDown(e: KeyboardEvent) {
      this.checkListCoords()
      if (super._captureDown) return super._captureDown(e)
      else return false
    }
    override _captureRight(e: KeyboardEvent) {
      this._signalDirection('right')
      if (super._captureRight) return super._captureRight(e)
      else return false
    }
    override _captureLeft(e: KeyboardEvent) {
      this._signalDirection('left')
      if (super._captureLeft) return super._captureLeft(e)
      else return false
    }
    getListCoords() {
      const listCoords = getCoords(this.list())
      return listCoords
    }

    _signalDirection(dir: 'right' | 'left') {
      if (this.navStatus === dir) {
        const list = this.list()
        const current = list ? list.childList.getAt(list.index) : null
        const coords = current ? getCoordinateDimensions(current) : null
        this.signal(dir, coords)
      }
    }

    private __lastListCoords: Coords | null = null

    set __currentWindow(x: number) {
      const { cardWidth, cardHeight } = this.cardDetails()
      this.__currentWindowIndex = x
      const list = this.list()
      const listCoords = getCoords(list)
      this.__lastListCoords = listCoords
      const windowCoords: CoordinateDimensions = {
        x: listCoords.x + list.spacing / 2 + x * (cardWidth + list.spacing),
        y: listCoords.y,
        width: cardWidth,
        height: cardHeight,
      }
      const gridId = this.fireAncestors('$gridId')
      if (isID(gridId)) {
        windowCoords.gridData = {
          window: x,
          id: this.gridId!,
        }
      }
      this.__patchPosition({
        position: list.scrollTransition.targetValue,
        window: x,
      })
      this.fireAncestors('$currentFocus', windowCoords)
    }

    get __currentWindow(): number {
      return this.__currentWindowIndex
    }
    __currentWindowIndex: number = 0
    __maxWindow: number = 0
    __currentRowOffsetNumber = 0
    __setupWindows() {
      const { numberOfVisibleCards } = this.cardDetails()
      this.__maxWindow = numberOfVisibleCards - 1
    }

    __currentWindowPosition: ListPosition | Omit<ListPosition, 'id'> = {
      index: 0,
      position: 0,
      window: 0,
    }

    __patchPosition(position: Partial<ListPosition>) {
      const newPosition = { ...this.__currentWindowPosition, ...position }
      if (!isEqual(newPosition, this.__currentWindowPosition)) {
        this.__currentWindowPosition = newPosition
        if (!this.__restoringState) {
          this.firePosition()
        }
      }
    }

    firePosition() {
      const id = this.listId()
      if (id) {
        this.fireAncestors('$listPosition', id, this.__currentWindowPosition)
      }
    }

    set __currentRowOffset(x: number) {
      this.__currentRowOffsetNumber = x
    }
    get __currentRowOffset() {
      return this.__currentRowOffsetNumber
    }

    static INDEX_CHANGE_METHOD = '__onIndexChanged'
    static ITEMS_REPOSITIONED = '__onItemsRepositioned'

    __onIndexChanged(arg: ListIndexChange) {
      const { index, previousIndex } = arg
      this.__setupWindows()
      // Moved up in the list and not on the end.
      if (index > previousIndex && this.__currentWindow < this.__maxWindow) {
        const offset = Math.abs(index - previousIndex)
        const windowUpdate = this.__currentWindow + offset
        const nextWindow =
          windowUpdate > this.__maxWindow ? this.__maxWindow : windowUpdate
        this.__currentWindow = nextWindow
        if (nextWindow !== windowUpdate) {
          const listMove = windowUpdate - nextWindow
          this.__currentRowOffset -= listMove
        }
      } else if (
        index > previousIndex &&
        this.__currentWindow === this.__maxWindow
      ) {
        this.__currentRowOffset -= Math.abs(index - previousIndex)
      } else if (index < previousIndex) {
        const offset = Math.abs(previousIndex - index)
        if (this.__currentWindow > 0) {
          const windowUpdate = this.__currentWindow - offset
          this.__currentWindow = windowUpdate < 0 ? 0 : windowUpdate
        } else {
          this.__currentRowOffset += offset
        }
      }

      this.__patchPosition({
        index,
      })
      this.__setNavStatus()
      this.__handleOverridenSignal('onIndexChanged', arg)
      this.fireAncestors('$trackFocus', this.getLocationString(), arg.index)
    }

    __setNavStatus() {
      const list = this.list()
      this.navStatus =
        list.index === 0
          ? 'left'
          : list.index === list.length - 1
            ? 'right'
            : list.hasFocus()
              ? 'list'
              : 'offlist'
    }

    __signalMap: Record<string, string> = {}

    __handleOverridenSignal(signal: string, arg?: unknown) {
      const parentSignal = this.__signalMap[signal]
      const signalCall = parentSignal ?? signal
      if (isFunction(Base.prototype[signalCall])) {
        return Base.prototype[signalCall].call(this, arg)
      }
    }

    __overideSignals(signalMap: Record<string, string>) {
      const list = this.list()
      const { signals = {} } = list
      const updatedSignals = Object.entries(signalMap).reduce(
        (acc, [signal, method]) => {
          if (!signals[signal]) {
            acc[signal] = method
          } else {
            this.__signalMap[signal] = signals[signal]
            acc[signal] = method
          }
          return acc
        },
        { ...signals } as Record<string, string>,
      )
      list.signals = updatedSignals
    }

    __listScrollTransition: any | null = null
    __onListTransitionStart() {
      const { scrollTransition } = this.list()
      if (scrollTransition) {
        if (isGoodNumber(scrollTransition.targetValue)) {
          this.__updateListScroll(this.__listScrollTransition.targetValue)
        }
      }
    }
    __onListTransitionFinish() {
      const { scrollTransition } = this.list()
      if (scrollTransition) {
        if (scrollTransition.settings.duration === 0) {
          scrollTransition.settings.duration = 0.2
        }
      }
    }

    __updateListScroll(newPositionX: number) {
      this.__patchPosition({ position: newPositionX })
    }
    override _focus() {
      this.__setNavStatus()
      this.__callParent('_focus')
    }

    override _unfocus() {
      this.navStatus = 'offlist'
      this.__callParent('_unfocus')
    }
    __restoringState: boolean = false
    __restoreWindow: number | null = null

    restoreState() {
      this.__restoringState = true
      try {
        const listId = this.listId()
        const list = this.list()
        const { scrollTransition } = list
        if (listId) {
          const savedPosition = this.fireAncestors('$listPosition', listId)
          if (isSavedPosition(savedPosition)) {
            if (scrollTransition.element.x !== savedPosition.position) {
              try {
                scrollTransition.element.setSmooth(
                  'x',
                  savedPosition.position,
                  {
                    duration: 0,
                  },
                )
              } catch (error) {
                console.error('Error patching %s', error.message, error)
              }
            }
            if (list.index !== savedPosition.index) {
              list.setIndex(savedPosition.index)
            }
            this.__currentWindowPosition = savedPosition
            this.__currentWindowIndex = savedPosition.window
            this.firePosition()
          }
        }
      } catch (error) {
        console.warn('Error restoring state %s', error.message)
      } finally {
        this.__restoringState = false
      }
    }

    override _attach() {
      this.__callParent('_attach')
      const list = this.list()
      const { scrollTransition } = list
      if (scrollTransition) {
        if (isFunction(scrollTransition.on)) {
          this.__onListTransitionStart = this.__onListTransitionStart.bind(this)
          this.__onListTransitionFinish =
            this.__onListTransitionFinish.bind(this)
          scrollTransition.on('start', this.__onListTransitionStart)
          scrollTransition.on('finish', this.__onListTransitionFinish)
          this.__listScrollTransition = scrollTransition
        }
      }
      this.__overideSignals({
        onIndexChanged: RowFocusable.INDEX_CHANGE_METHOD,
        onItemsRepositioned: RowFocusable.ITEMS_REPOSITIONED,
      })
    }

    __callParent(f: string, ...args: unknown[]) {
      if (isFunction(Base.prototype[f])) {
        Base.prototype[f].apply(this, args)
      }
    }

    override _detach() {
      this.__callParent('_detach')
      if (this.__listScrollTransition) {
        if (isFunction(this.__listScrollTransition.off)) {
          this.__listScrollTransition.off('start', this.__onListTransitionStart)
          this.__listScrollTransition.off(
            'finish',
            this.__onListTransitionFinish,
          )
        }
        this.__listScrollTransition = null
      }
    }

    setClosest(coords: CoordinateDimensions) {
      const list = this.list() as typeof List
      const tests = Array.isArray(list.itemWrappers)
        ? list.itemWrappers
        : list.items

      const index = getClosestIndexByX(coords, tests)
      if (isNumber(index) && list.index !== index) {
        list.setIndex(index)
      }
    }
    override _getFocused() {
      const currentFocus = this.fireAncestors('$currentFocus')
      const list = this.list()
      if (currentFocus && !list.hasFocus()) {
        this.setClosest(currentFocus)
      }
      return this.list()
    }
  }
}

export interface CardDetails {
  numberOfVisibleCards: number
  cardWidth: number
  cardHeight: number
}

type GConstructor<T extends Lightning.Component = Lightning.Component> = new (
  stage: Lightning.Stage,
  properties?: Lightning.Element.PatchTemplate<
    Lightning.Element.ExtractTemplateSpec<T>
  >,
) => T

export interface ClosestFocusbleTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: HorizontalNavSignalMap
}

type ListType = Lightning.Component<
  Lightning.Component.TemplateSpecLoose,
  ClosestFocusbleTypeConfig
> & {
  gridId?: ID
  list(): typeof List
  listId(): null | string
  cardDetails(): CardDetails
}
type ClosestFocusable = GConstructor<ListType>

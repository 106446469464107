import {
  ActionConfig,
  ButtonConfig,
  ContentItem,
  CoordinateDimensions,
  DirectionalSignalMap,
  FocusableMixin,
  SetsByX
} from '@adiffengine/engine-types'
import { Colors, Img, Lightning } from '@lightningjs/sdk'
import isNumber from 'lodash-es/isNumber'
import isString from 'lodash-es/isString'
import { Debugger, isGoodArray, isGoodNumber } from '../lib'
import { getStoredTheme } from '../themes'
const debug = new Debugger('AdeHero')

import { isBoolean } from '../lib/utils'
import { AdeButtonCollection } from './AdeButtonCollection'

export function createHeroCarousel(
  content: ContentItem[],
  opts: Record<string, any> = {},
) {
  return {
    type: AdeHero,
    heros: content,
    h: 400,
    w: 1740,
    ...opts,
  }
}

// const debug = new Debugger('AdeHero')

export type MaybeString = string | undefined | null
export interface TextSet {
  Title: MaybeString
  Subtitle: MaybeString
  Description: MaybeString
}
export interface AdeHeroTemplateSpec
  extends Lightning.Component.TemplateSpecLoose {
  Content: {
    Poster: object
    OverlayRounded: object
    Details: {
      Headers: {
        Title: object
        Subtitle: object
        Description: object
        Buttons: typeof AdeButtonCollection<ActionConfig>
      }
    }
  }
  buttons?: ButtonConfig<ActionConfig>[]
}
export interface AdeHeroTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: FocusableMixin<DirectionalSignalMap>
}

export class AdeHero
  extends Lightning.Component<AdeHeroTemplateSpec, AdeHeroTypeConfig>
  implements
    Lightning.Component.ImplementTemplateSpec<AdeHeroTemplateSpec>,
    SetsByX
{

  private _contentIndex = 0
  get current() {
    return Array.isArray(this._content)
      ? this._content[this._contentIndex]
      : undefined
  }

  set contentIndex(x: number) {
    this._contentIndex = x
  }

  get contentIndex() {
    return this._contentIndex
  }

  set buttons(x: ButtonConfig<ActionConfig>[]) {
    if (isGoodArray(x)) {
      this.Buttons.patch({
        buttons: x,
      })
    }
  }
  static height = 400
  Content = this.getByRef('Content')!
  Hero = this.Content.getByRef('Poster')!
  Details = this.Content.getByRef('Details')!
  Headers = this.Details.getByRef('Headers')!
  Buttons = this.Headers.getByRef('Buttons')!
  static override _template(): Lightning.Component.Template<AdeHeroTemplateSpec> {
    const theme = getStoredTheme()
    return {
      Content: {
        w: (w: number) => w,
        h: (w: number) => w,
        shader: { type: Lightning.shaders.RoundedRectangle, radius: 12 },
        clipping: true,
        Poster: {
          w: (w: number) => w,
          h: (h: number) => h,
          x: 0,
          y: 0,
          rect: true,
          rtt: true,
          shader: { type: Lightning.shaders.RoundedRectangle, radius: 12 },
        },
        OverlayRounded: {
          w: (w: number) => w,
          h: (h: number) => h,
          x: 0,
          y: 0,
          rect: true,
          colorLeft: Colors(theme.palette.darks[500]).alpha(1).get(),
          colorRight: Colors(theme.palette.darks[800]).alpha(0.001).get(),
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: 12,
          },
        },
        Details: {
          x: 32,
          y: 32,
          w: (w: number) => w, // * 0.7,
          h: (h: number) => h - 64,
          Headers: {
            w: (w: number) => w,
            h: (h: number) => h - 32,
            flex: {
              direction: 'column',
              justifyContent: 'center',
              padding: 20,
            },
            Title: {
              color: Colors('white').get(),
              text: {
                text: '',
                fontFace: 'Bold',
                fontSize: 48,
                textAlign: 'left',
                wordWrap: true,
                maxLines: 2,
              },
            },
            Subtitle: {
              color: Colors('white').get(),
              text: {
                text: '',
                fontFace: 'Bold',
                fontSize: 24,
                textAlign: 'left',
                wordWrap: true,
                maxLines: 3,
              },
            },
            Description: {
              color: Colors('white').get(),
              text: {
                text: '',
                fontFace: 'Text',
                fontSize: 24,
                lineHeight: 36,
                textAlign: 'left',
                wordWrap: true,
                maxLines: 4,
              },
            },
            Buttons: {
              type: AdeButtonCollection<ActionConfig>,
              direction: 'row',
              spacing: 40,
              signals: {
                selected: '_handleSelectedItem',
                hovered: '_handleButtonHovered',
                left: '_left',
                right: '_right',
                up: '_up',
                down: '_down',
              },
            },
          },
        },
      },
    }
  }
  private _focusable: boolean | null | undefined
  set focusable(focusable: boolean | null | undefined) {
    this._focusable = focusable
  }
  get focusable() {
    if (isBoolean(this._focusable)) {
      return this._focusable
    } else {
      const out = this.signal('focusable')
      return isBoolean(out) ? out : true
    }
  }
  _handleButtonHovered() {
    this.signal('hovered')
  }
  _handleSelectedItem(item: ActionConfig) {
    debug.info('Handle Selected Item', item)
    this.fireAncestors(item.action, item.payload)
  }

  setClosestByX(coords?: CoordinateDimensions | null) {
    this.Buttons?.setClosestByX(coords)
  }

  restoreState() {
    if (this.current) {
      const index = this.fireAncestors('$listPosition', this.current.id)
      if (isGoodNumber(index)) {
        this.Buttons.patch({
          index,
        })
      }
    } else {
      console.warn('No current content to restore focus on hero')
    }
  }

  currentCoords(): CoordinateDimensions | null {
    return this.Buttons.currentCoords()
  }

  _left() {
    const response = this.signal('left', this.currentCoords())
    return isBoolean(response) ? response : false
  }
  _right() {
    const response = this.signal('right', this.currentCoords())
    return isBoolean(response) ? response : false
  }
  _up() {
    const response = this.signal('up', this.currentCoords())
    return isBoolean(response) ? response : false
  }
  _down() {
    const response = this.signal('down', this.currentCoords())
    return isBoolean(response) ? response : false
  }
  setText(textSet: Partial<TextSet>) {
    const container = isNumber(this.w) ? this.w : AdeHero.width
    const wordWrapWidth = container * 0.6
    if (Object.keys(textSet).length < 1) return
    const patch = Object.entries(textSet).reduce((acc, [key, text]) => {
      return isString(text) && text.length > 0
        ? { ...acc, [key]: { text: { text, wordWrapWidth } } }
        : acc
    }, {} as Partial<TextSet>)
    this.Headers.patch(patch as Lightning.Element.PatchTemplate)
  }

  setupContent() {
    if (this.current) {
      const imageUrl = this.current.images.wide?.getForWidth(this.w)
      if (imageUrl) {
        this.Hero.patch({
          visible: true,
          texture: Img(imageUrl).cover(this.w, this.h),
        })
        this.Hero.on('txError', () => this.Hero.patch({ visible: false }))
      } else {
        this.Hero.patch({
          visible: false,
        })
      }
      this.setText({
        Title: this.current.title,
        Subtitle: this.current.tagline,
        Description: this.current.description,
      })

      this.Buttons.patch({
        buttons: [
          {
            buttonText: 'Play',
            payload: {
              action: '$navigate',
              payload: this.current.paths.player,
            },
          },
          {
            buttonText: 'More Info',
            payload: {
              action: '$navigate',
              payload: this.current.paths.details,
            },
          },
        ],
        index: 0,
      })
    } else {
      this.Buttons.patch({
        buttons: [],
        index: 0,
      })
    }
  }

  override _init() {
    this.w = isNumber(this.w) ? this.w : AdeHero.width
    this.h = isNumber(this.h) ? this.h : AdeHero.height
    this.setupContent()
  }
  private _content: ContentItem[] = []
  set heros(content: ContentItem[]) {
    if (this.current) {
      this.fireAncestors('$listPosition', this.current.id)
    }
    if (Array.isArray(content)) {
      this._content = content
    } else {
      console.warn('Content is not an array')
      this._content = []
    }
    this.contentIndex = 0
  }
  get heros() {
    return this._content
  }
  override _getFocused() {
    return this.tag('Content.Details.Headers.Buttons')
  }
  public static width = 1600
}

import { Settings, Registry } from '@lightningjs/sdk'
import Emittery from 'emittery'
import { Debugger, isGoodNumber } from '../../lib'
const debug = new Debugger('AreYouStillThereUserKeyMonitor')
export type UserActivityState = 'inactive' | 'active'
export interface KeyMonitorEvents {
  reset: number
  timedout: undefined
  stateChange: UserActivityState
  maxVideos: undefined
}

export class UserKeyMonitor extends Emittery<KeyMonitorEvents> {
  private _timeoutInMS: number
  private _timer: ReturnType<typeof Registry.setTimeout> | null = null

  constructor(timeoutInMinutes?: number) {
    super()
    if (timeoutInMinutes) this._timeoutInMS = timeoutInMinutes * 60000
    else {
      const timeout = Settings.get('app', 'SCREENSAVER_TIMEOUT_IN_MINUTES', 150)
      debug.info('Starting Timeout with duration of %s ', timeout * 60000)
      this._timeoutInMS = timeout * 60000
    }
  }
  private _state: UserActivityState = 'active'
  get state() {
    return this._state
  }
  set state(state: UserActivityState) {
    if (this._state !== state) {
      debug.info('Setting state to %s, from: %s', state, this._state)
      this._state = state
      this.emit('stateChange', state)
    }
  }
  private _clearTimer() {
    if (this._timer !== null) Registry.clearTimeout(this._timer)
    this._timer = null
  }
  private _resetTimer(skipEmit = false) {
    debug.info('Resetting Timer')
    this._clearTimer()
    this._timer = Registry.setTimeout(() => {
      debug.info('Timer Fired')
      this._timer = null
      this.state = 'inactive'
      this.emit('timedout')
    }, this._timeoutInMS)
    if (!skipEmit === true)
      this.emit('reset', Math.round(this._timeoutInMS / 60000))
  }
  get timeoutInSeconds() {
    return this._timeoutInMS / 1000
  }
  get timeoutInMinutes() {
    return (this._timeoutInMS / 1000) * 60
  }

  start() {
    debug.info('Starting User Key Monitor')
    this._resetTimer(true)
  }

  ping() {
    debug.info('Pinging User Key Monitor')
    this._resetTimer()
    this.state = 'active'
  }
  destroy() {
    this._clearTimer()
  }
  reset() {
    this.destroy()
    this.start()
  }
}
export class UserVideoMonitor extends UserKeyMonitor {
  maxVideos: number = 2
  _videosWatched: number = 0
  constructor(timeoutInMinutes?: number, maxVideos?: number) {
    super(timeoutInMinutes)
    if (!isGoodNumber(maxVideos)) {
      this.maxVideos = Settings.get('app', 'MAX_VIDEOS_BEFORE_CHECK', 2)
    }
  }
  override start() {
    super.start()
    this.videosWatched = 0
    this.on('stateChange', change => {
      if (change === 'active') {
        this.videosWatched = 0
      }
    })
  }
  override destroy() {
    super.destroy()
    this.videosWatched = 0
    this.maxed = false
  }

  maxed: boolean = false
  set videosWatched(watched: number) {
    if (watched !== this._videosWatched) {
      const maxed =
        this._videosWatched > this.maxVideos && watched > this.maxVideos
      this._videosWatched = watched
      if (maxed) {
        this.maxed = maxed
        this.emit('maxVideos')
      }
    }
  }

  get videosWatched() {
    return this._videosWatched
  }
  incrementVideoCount(inc = 1) {
    this.videosWatched += inc
  }
}

import { CoordinateDimensions } from '@adiffengine/engine-types'
import { Colors, Lightning } from '@lightningjs/sdk'
import { Debugger, cp, getCoordinateDimensions } from '../../lib'
import { MouseHelper, MouseHelperEvents } from '../../lib/pointerHelper'
import { AdvancedGridScrollGrabber } from './lib/AdvancedGridScrollGrabber'
const debug = new Debugger('AdvancedGridScroller')
export interface AdvancedGridScrollerTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Grabber: typeof AdvancedGridScrollGrabber
  Bar: object
}
export interface AdvancedGridScrollerTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: {
    reposition(percentage: number): void
  }
}
export class AdvancedGridScroller
  extends Lightning.Component<
    AdvancedGridScrollerTemplateSpec,
    AdvancedGridScrollerTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<AdvancedGridScrollerTemplateSpec>
{
  Bar = this.getByRef('Bar')!
  Grabber = this.getByRef('Grabber')!
  static override _template(): Lightning.Component.Template<AdvancedGridScrollerTemplateSpec> {
    return {
      h: 18,
      rect: true,
      color: 0x00000000,
      Bar: {
        x: 0,
        y: 6,
        w: cp,
        h: 6,
        rect: true,
        rtt: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          fillColor: Colors('buttonBackground').get(),
          radius: 3,
        },
      },
      Grabber: {
        type: AdvancedGridScrollGrabber,
        signals: {
          hovered: '_showBar',
          unhovered: '_dimBar',
        },
      },
    }
  }

  private _animation: Lightning.types.Animation | null = null
  get _focusAnimation() {
    if (this._animation === null) {
      this._animation = this.animation({
        duration: 0.3,
        actions: [
          {
            t: 'Bar',
            // @ts-ignore
            p: 'shader.fillColor',
            v: {
              0: Colors('buttonBackground').get(),
              1: Colors('primaryHighlight').alpha(0.5).get(),
            },
          },
        ],
      })
    }
    return this._animation
  }

  _currentCoords: CoordinateDimensions | null = null
  _jumpAmount: number | null = null

  _handleMouseMove(coords: MouseHelperEvents['drag']) {
    debug.info('Drag X distance', coords.delta.x)
    let offset = this._startGrabberX + coords.delta.x
    const x = offset < 40 ? 0 : offset > this.w - 40 ? this.w - 40 : offset
    debug.info('Patching X for Grabber to ', x)
    this.Grabber.patch({ x })
  }

  _hovered: boolean = false
  _dragging: boolean = false
  _startGrabberX: number = 0

  signalResposition() {
    const position = (this.w - this.Grabber.x) / this.w
    debug.info('Reposition', position)
    this.signal('reposition', position)
  }
  clearDragWatcher() {
    debug.info('Clear Drag Watcher', this._hovered, this._dragging)
    this._mouseHandler?.pause()
    this._mouseHandler?.off('drag', this._handleMouseMove)
    this._focusAnimation.stop()
    this.signalResposition()
  }

  _showBar() {
    debug.info('Hovered')
    this._hovered = true
    this._currentCoords = getCoordinateDimensions(this)
    this._mouseHandler?.start()
    this._mouseHandler?.on('drag', this._handleMouseMove)
    this._focusAnimation.start()
  }

  _dimBar() {
    debug.info('Unhovered')
    this._hovered = false
    this.clearDragWatcher()
  }
  private _mouseHandler: MouseHelper | null = null
  override _init() {
    this._handleMouseMove = this._handleMouseMove.bind(this)
    this._mouseHandler = new MouseHelper()
    this.Bar.patch({ w: this.w })
  }
}

import { Lightning } from '@lightningjs/sdk'
import { AdeLoader } from './AdeLoadingPage'

class ExitPageLoader extends AdeLoader {
  override _active() {
    this.Background.dance()
    this.Text.setSmooth('alpha', 1)
  }
  override _inactive() {}
}

export interface AdeExitPageTypeConfig extends Lightning.Component.TypeConfig {
  IsPage: true
}

export interface AdeExitPageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: typeof ExitPageLoader
  IsPage: true
}
export class AdeExitPage extends Lightning.Component<
  AdeExitPageTemplateSpec,
  AdeExitPageTypeConfig
> {
  static override _template(): Lightning.Component.Template {
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      Content: {
        x: 0,
        y: 0,
        w: 1920,
        h: 1080,
        type: ExitPageLoader,
        loadingText: 'Exited',
      },
    }
  }
}

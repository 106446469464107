import { DirectionalSignalMap } from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'

import { Debugger, cp } from '../../lib'
import { AdeStack } from '../AdeStack'
import { PlayControlButton, TogglePlayButton } from '../player'
const debug = new Debugger('AudioPlayerControls')

export interface AudioPlayerControlsTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Controls: typeof AdeStack
  useDefaults: boolean
  buttons: AudioButton[]
}
export interface ControlsSignalMap extends DirectionalSignalMap {
  next(): void
  previous(): void
  like(): void
  dislike(): void
}
export interface AudioPlayerControlsTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: ControlsSignalMap
}

type AudioButtonType = typeof TogglePlayButton | typeof PlayControlButton
export interface AudioButtonObject {
  w: number
  h: number
  type: AudioButtonType
  signal?: keyof ControlsSignalMap
  icon?: string
  backgroundDisabled?: boolean
}

const AudioButtons: Record<string, AudioButtonObject> = {
  next: {
    w: 40,
    h: 40,
    type: PlayControlButton,
    signal: 'next',
    icon: 'play-skip-forward-sharp.png',
  },
  toggle: {
    w: 40,
    h: 40,
    type: TogglePlayButton,
    backgroundDisabled: true,
  },
  previous: {
    w: 40,
    h: 40,
    type: PlayControlButton,
    signal: 'previous',
    icon: 'play-skip-back-sharp.png',
  },
  like: {
    w: 30,
    h: 30,
    type: PlayControlButton,
    signal: 'like',
    icon: 'like-fill.png',
  },
  dislike: {
    w: 30,
    h: 30,
    type: PlayControlButton,
    signal: 'dislike',
    icon: 'dislike-fill.png',
  },
}

export type AudioButton = keyof typeof AudioButtons

const defaultItems = [
  AudioButtons['toggle'],
  AudioButtons['next'],
  AudioButtons['like'],
  AudioButtons['dislike'],
]

export class AudioPlayerControls
  extends Lightning.Component<
    AudioPlayerControlsTemplateSpec,
    AudioPlayerControlsTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<AudioPlayerControlsTemplateSpec>
{
  static height = 100
  Controls = this.tag('Controls')!
  static override _template(): Lightning.Component.Template<AudioPlayerControlsTemplateSpec> {
    return {
      w: 400,
      h: 100,
      Controls: {
        h: cp,
        w: cp,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        type: AdeStack,
        stackConfig: {
          direction: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          spacing: 12,
        },
        passSignals: {
          up: true,
          down: true,
          left: true,
          right: true,
        },
        currentIndex: 1,
      },
    }
  }

  set buttons(buttons: AudioButton[]) {
    const items = buttons.map(button => AudioButtons[button])
    this.items = items
  }

  override _init() {
    if (this.useDefaults) {
      this.items = defaultItems
    }
  }
  override _handleEnter() {
    const index = this.Controls.currentIndex
    const item = this.items[index]
    if (item && item.signal) this.signal(item.signal)
  }
  private _items: AudioButtonObject[] = []
  set items(items: AudioButtonObject[]) {
    this.Controls.patch({ items })
    this._items = items
  }
  get items() {
    return this._items
  }
  set useDefaults(useDefaults: boolean) {
    if (useDefaults) {
      this.items = defaultItems
    }
  }
  override _getFocused(): Lightning.Component {
    return this.tag('Controls')!
  }
  override _enable() {
    debug.info('Enable Audio Player Controls', this)
  }
}

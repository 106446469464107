import { platform as lgPlatform } from './lg/platform'
import { platform as x1Platform } from './x1/platform'
import { platform as samsungPlatform } from './samsung/platform'
import { platform as smartTvPlatform } from './smart-tv/platform'

import { Settings } from '@lightningjs/sdk'
import { DeviceType, DeviceTypes } from '@adiffengine/engine-types'
import { DevicePlatform } from './platforms'
let displayed = false
const DeviceLookup: Record<DeviceType, DevicePlatform> = {
  x1: x1Platform,
  samsung: samsungPlatform,
  'smart-tv': smartTvPlatform,
  lg: lgPlatform,
  android: smartTvPlatform,
}
export function getDevicePlatform() {
  let device = Settings.get('app', 'platform', null) as DeviceType | null
  if (device === null || device === DeviceTypes.Android) {
    console.warn(
      'Got %s device setting in settings, using default smart tv platform',
      device === null ? 'No' : 'Android',
    )
    device = DeviceTypes.SmartTV
  } else if (!displayed) {
    console.info('Using current platform: %s', device)
  }
  return DeviceLookup[device]
}

import { Colors, Lightning } from '@lightningjs/sdk'
import isFunction from 'lodash-es/isFunction'
import { Welcome } from '../components/Welcome'
// import { Debugger } from '../lib'
// const _debug = new Debugger('WelcomeScreen')
export interface WelcomeScreenTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Welcome: typeof Welcome
}
export interface WelcomeScreenTypeConfig
  extends Lightning.Component.TypeConfig {
  IsPage: true
}

export function isWelcomeScreen(x: Lightning.Element): x is WelcomeScreen {
  return x.constructor === WelcomeScreen
}
interface Arriver extends Lightning.Element {
  arrived(x?: number): void
}
interface Departer extends Lightning.Element {
  departed(x?: number): void
}
export function isArrivalScreen(x: Lightning.Element): x is Arriver {
  return isFunction((x as Arriver).arrived)
}
export function isDepartureScreen(x: Lightning.Element): x is Departer {
  return isFunction((x as Departer).departed)
}

export class WelcomeScreen
  extends Lightning.Component<
    WelcomeScreenTemplateSpec,
    WelcomeScreenTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<WelcomeScreenTemplateSpec>
{
  Welcome = this.getByRef('Welcome')!
  static override _template(): Lightning.Component.Template<WelcomeScreenTemplateSpec> {
    return {
      x: 0,
      y: 0,
      h: 1080,
      w: 1920,
      rect: true,
      rtt: true,
      color: Colors('background').get(),
      Welcome: {
        type: Welcome,
        x: 80,
        y: 80,
        h: 1080,
        w: 1920,
        name: 'Mike',
      },
    }
  }

  override _getFocused() {
    return this.Welcome
  }
}

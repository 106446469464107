import { AppFontFaces } from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { defer } from '../../lib/utils'

export interface MagicTextTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Text: object
  fontFace: AppFontFaces
  fontSize: number
}

export class TextWidthTester
  extends Lightning.Component<MagicTextTemplateSpec>
  implements Lightning.Component.ImplementTemplateSpec<MagicTextTemplateSpec>
{
  Text = this.getByRef('Text')!
  static override _template(): Lightning.Component.Template<MagicTextTemplateSpec> {
    return {
      Text: {
        alpha: 0.0001,
      },
    }
  }

  public fontFace: AppFontFaces = 'Regular'
  public fontSize = 18
  getStringLength(text: string): Promise<number> {
    const comp = this.stage.c({
      text: {
        fontFace: this.fontFace,
        fontSize: this.fontSize,
        text,
      },
    })
    this.Text.childList.clear()
    const element = this.Text.childList.a(comp)
    return new Promise<number>(resolve => {
      defer(() => {
        const width = element.finalW
        this.Text.childList.clear()
        resolve(width)
      })
    })
  }
}
